/*! 
 * フォーム関係
 */

 /** ========================================
 * @p--login
 * ログイン
======================================== */
.p--form{
  width: 895px;
  margin: 40px auto 0;
  &--noFooter{
    margin-bottom: 80px;
  }
  &__main{
    padding: 28px 23px;
  }
  &__table{
    margin: 45px 0 0;
    th{
      font-size: remSize(13);
    }
    .p--input__heading{
      display: inline-block;
      min-width: 40px;
      margin: 0 5px 0 0;
      font-size: remSize(13);
    }

    input{
      vertical-align: middle;
      + a{
        margin: 0 0 0 10px;
      }
    }

    .p--step__txt{
      background: #FAFAFA;
      padding: 8px 18px;
      font-size: remSize(13);
      span{
        display: inline-block;
        margin: 0 5px 0 0;
        font-weight: bold;
      }
    }
    .c--step__main{
      padding: 10px 15px;
      .c--main__attenTxt{
        margin: 8px 0 0;
        font-size: remSize(12);
      }
      .c--main__infoTxt{
        margin: 15px 0 0;
        font-size: remSize(13);
      }
    }
    .c--arrow--circle{
      vertical-align: middle;
      &::before{
        top: calc(.5rem - 6px);
        transform: none;
      }
    }
    .c--dsp--inlineBlock{
      vertical-align: middle;
    }
  }

  &__ruleBox{
    height: 180px;
    margin: 30px 0 0;
    padding: 19px 22px;
    border: 1px solid $clr-g40;
    background: $clr-w;
    overflow-y: scroll;
  }

  &RegistBtn{
    margin: 40px 0 0;
    .c--btn{
      &:nth-child(1){
        width: 155px;
      }
      &:nth-child(2){
        width: 300px;
      }
      + .c--btn{
        margin: 0 0 0 8px;
      }
    }
  }
}

.p--conf__area th{
  padding: 24px 20px;
}




@media screen and (max-width: 767px){
  .p--form{
    width: 100%;

    &__main{
      padding: 11px 0;
      .p--form__infoTxt{
        padding: 0 22px;
      }
    }
    &__table {
      margin: 18px 0 0;
      .c--step__main{
        margin: 10px 0;
        padding: 10px 20px;
      }
    }

    .p--step__txt{
      display: none;
    }

    .p--ruleBox--sp{
      margin: 10px 0 0;
      padding: 10px 25px;
      border-top: 1px solid $clr-g40;
      label{
        margin: 20px 0;
      }
    }
    .p--formBtn{
      padding: 0 20px;
    }

    .p--formRegistBtn{
      display: flex;
      justify-content: center;
      padding: 0 20px;
      margin: 30px 0 0;

      .c--btn{
        &--2line{
          padding: 7px 15px;
          line-height: 1.5;
        }
        &:nth-child(1){
          width: 95px;
        }
        &:nth-child(2){
          width: 230px;
        }
      }
    }
    &__infoTxt--sp__pd{
      padding: 8px 22px;
    }
  }
  .p--conf__area th{
    padding: 5px 20px;
  }

}



/** ========================================
 * @p--ruleBox--agree
 * 利用規約設定
 * ====================================== */
.p--ruleBox--agree{
  margin: 30px 0 0;
  text-align: center;
  .c--control--checkbox{
    margin: 20px 0 30px;
  }
}
@media screen and (max-width: 767px){
  .p--ruleBox--agree{
    margin: 10px 0 0;
    padding: 10px 25px;
    border-top: 1px solid #dadadb;
    text-align: left;
    .c--control--checkbox{
      margin: 20px 0;
    }
  }
}