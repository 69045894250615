/*! 
 * トップページ
 */


 /** ========================================
 * 重要なお知らせ
 * ====================================== */
.p--top .c--notices__list{
  width: calc(100% - 134px);
}
@media screen and (max-width: 767px){
  .p--top .c--notices__list{
    width: 100%;
  }
}


/** ========================================
 * @p--searchArea
 * 検索エリア
 * ====================================== */
// メインビジュアル
.p--searchArea__bg{
  position: relative;
  height: 405px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
  img{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 100%;
  }
}

@media screen and (max-width: 767px){
  .p--searchArea__bg{
    height: auto;
    overflow: unset;
    img{
      display: none;
    }
  }
}


// 検索エリア
.p--searchArea__inner{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1200px;
  display: flex;
  align-items: center;
  height: 100%;
  text-align: left;
  z-index: 1;
}
.p--searchArea{
  margin: 25px 0 0;
  .p--searchArea__box{
    width: 540px;
    margin-left: 80px;
  }
  &__tab{
    display: flex;
  }
}

@media screen and (max-width: 1200px){
  .p--searchArea__inner{
    width: 100%;
  }
}

.p--tab{
  min-width: 266px;
  background:  $clr-w;
  text-align: center;
  &:not(:first-child){
    margin: 0 0 0 8px;
  }
  &__txt{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: bold;
  }
  &--hospital{
    height: 78px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
  }

  &--intern{
    height: 54px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
  }
  &--active{
    height: 62px;
    padding: 12px 0 17px;
    border-top: 4px solid $clr-p80;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 2px -2px rgba(97,97,106,.2), 0 0 5px -5px rgba(97,97,106,.1);
    p{
      color: $clr-p80;
      font-size: 1rem;
      font-weight: bold;
    }
    span{
      display: inline-block;
      color: $clr-b;
      font-size: .75rem;
      i{
        color: $clr-p80;
        font-weight: bold;
      }
    }
  }

}

.p--searchArea__main{
  padding: 16px 40px 11px;
  background: #fff;
  box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
}

.p--main__btnArea{
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  border-radius: 4px;
  .p--btn{
    display: flex;
    align-items: center;
    width: 222px;
    padding: 13px 15px;
    .p--btn__icon{
      position: relative;
      width: 48px;
      height: 48px;
      margin-right: 8px;
      &:after{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        content: "";
        display: block;
        width: 24px;
        margin: 2px auto 8px;
        background-size: contain;
      }
      &:before{
        width: 48px;
        height: 48px;
        background-size: 48px;
      }
      &--area:after{
        height: 24px;
        background: url(../images/icon_aria.png) 50% no-repeat;
        background-size: 18px;
      }
      &--ds:after{
        height: 24px;
        background: url(../images/icon_ds.png) 50% no-repeat;
        background-size: 18px;
      }
    }
  }
}

.p--main__kwArea{
  .p--kw{
    &__ttl{
      font-size: 1rem;
      font-weight: bold;
    }
    &__input{
      input{
        width: 355px;
        margin: 0 5px 0 0;
      }
    }
  }
}
.p--main__tagArea{
  margin: 18px 0 0;
  .p--tag{
    margin: 10px 0 0;
    span{
      display: inline-block;
      border-radius: 4px;
      background: $clr-g20;
      margin: 0 6px 6px 0;
      a{
        display: inline-block;
        padding: 7px 8px;
        color: $clr-p80;
        font-size: remSize(12);
      }
    }
  }
  
  .p--other{
    margin: 8px 0 0;
    font-size: remSize(12);
    a{
      margin: 0 10px 5px 0;
    }
  }
}

.c--recommended{
  margin: 30px 0 0;
  .p--sliderArea + &{
    margin-top: 60px;
  }
}

@media screen and (max-width: 1300px){
  .p--searchArea__main{
    padding: 12px 40px 7px;
  }
  .p--main__btnArea{
    margin-top: 15px;
  }
  .p--main__tagArea{
    margin-top: 15px;
  }
}


@media screen and (max-width: 767px){
  .p--top__main{
    display: flex;
    flex-direction: column;
  }
  .p--sliderArea + .c--recommended{
    margin-top: 30px;
  }

  .p--sliderWrap{
    display: flex;
    flex-direction: column;
  }
  .p--searchArea{
    order: 2;
    margin: 24px 0 0;
    .p--searchArea__box{
      width: 100%;
      position: relative;
      top: auto;
      left: auto;
      margin-left: 0;
    }
    &__inner{
      position: relative;
      left: 0;
      transform: unset;
    }
    &__tab{
      justify-content: space-between;
      padding: 0 11px 0 10px;
    }
  }
  .p--tab{
    min-width: auto;
    width: calc(50% - 4px);
    &__txt{
      font-size: remSize(14);
      line-height: 1;
    }
    &--hospital{
      height: 57px;
      padding: 0 0 4px;
      span{
        margin-top: 3px;
        font-size: remSize(12);
        i{
          margin: 0 2px;
          font-size: remSize(16);
        }
      }
    }
    &--intern{
      height: 49px;
    }
  }

  .p--searchArea__main{
    padding: 19px 0 0;
    .c--hoverShadow__item{
      border-radius: 4px;
    }
  }

  .p--main__btnArea{
    padding: 0 20px;
    .p--btn{
      width: calc(50% - 7px);
      .p--btn__icon{
        width: 38px;
        height: 38px;
        margin: 0;
        &:before{
          width: 38px;
          height: 38px;
          background-size: 38px;
        }
        &--area:after{
          height: 20px;
          background-size: 15px;
        }
        &--ds:after{
          height: 20px;
          background-size: 16px;
        }
      }
      .p--btn__txt{
        margin: 0 0 0 7px;
        font-size: 14px;
      }
    }
  }
  .p--main__kwArea{
    .p--kw{
      padding: 0 20px;
      &__input{
        input{
          width: calc(100% - 96px);
        }
        .c--btn--input{
          width: 96px;
        }
      }
    }
    .c--btn__input{
      width: 96px;
    }
  }
  .p--main__tagArea{
    .p--tag{
      padding: 0 20px;
      margin: 15px 0 0;
    }
    .p--other{
      margin: 9px 0 0;
      a.c--arrow--circle{
        display: block;
        width: 100%;
        margin: 0!important;
        padding: 8px 30px 8px 20px;
        border-top: 1px solid $clr-g30;
        &:before{
          top: 48%;
          right: 20px;
          left: unset;
          width: 7px;
          height: 7px;
          border: solid #e92d82;
          border-width: 0 2px 2px 0;
          z-index: 10;
          -webkit-transform: translateY(-50%) rotate(-45deg);
          transform: translateY(-50%) rotate(-45deg);
          background: none;
        }
      }
    }
  }

}

/** ========================================
 * @p--sliderArea
 * スライダー
 * ====================================== */
.p--sliderArea{
  margin: 25px 0 0;

  // ナビ
  .swiper{
    &-custom-parent{
      position: relative;
      width: 1200px;
      margin: 0 auto;
    }
    &-container{
      width: 1152px;
    }

    &-button{
      &-prev,
      &-next{
        background: $clr-p60;
        height: 100%;
        top: 0;
        margin: 0;
        width: 25px;
        pointer-events: none;
        &:hover{
          background: $clr-p80;
        }
        &:focus{
          outline: none;
        }

        &:after{
          display: none;
          content: "";
          position: absolute;
          top: 50%;
          width: 6px;
          height: 6px;
          border: solid $clr-w;
          border-width: 0 2px 2px 0;
        }
      }
      &-prev{
        left: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        &:after{
          left: calc(50% + 2px);
          transform: translate3d(-50%, -50%, 0) rotate(135deg);
        }
      }
      &-next{
        right: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &:after{
          right: calc(50% - 6px);
          transform: translate3d(-50%, -50%, 0) rotate(-45deg);
        }
      }
    }

    // ページネーション
    &-pagination{
      width: 100%;
      margin: 3px 0 0;
      &-bullet{
        border: 2px solid $clr-b!important;
        background: $clr-w!important;
        opacity: 1!important;
        margin: 0 2px;
        &:focus {
          outline: none;
        }
        &-active{
          background: $clr-p60!important;
        }
      }
    }
  }

  .p--move__slider{
    .swiper-button-prev,
    .swiper-button-next{
      pointer-events: auto;
      &::after{
        display: block;
      } 
    }
  }

  .p--slider__pc{
    display: flex;
    a{
      width: 282px;
      &:not(:first-child){
        margin: 0 0 0 8px;
      }
    }
  }


}


@media screen and (max-width: 767px){
  .p--sliderArea{

    .swiper{
      &-custom-parent,
      &-container{
        width: 100%;
      }
      &-button{
        &-prev,
        &-next{
          position: relative;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          pointer-events: auto;
          &::after{
            display: block;
          }
        }
        &-prev{
          order: 1;
          &:after{
            left: calc(50% + 1px);
          }
        }
        &-next{
          order: 3;
          &:after{
            right: calc(50% - 5px);
          }
        }
      }
      &-pagination{
        display: flex;
        position: relative;
        order: 2;
        width: auto;
        margin: 0 6px;
      }
    }

    .p--top__btn{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 8px 0 0;
    }
  }

}



/** ========================================
 * @p--search__jobFair
 * 合説を探す
 * ====================================== */
//  エリア別で絞り込む
.p--search__jobFair{
  padding: 35px 0;
}
.p--jobFair__area{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 0;
  padding: 0 25px;
  background: $clr-g40;
  font-size: remSize(14);
}
.p--area{
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0 0 0 40px;
  &__heading{
    position: relative;
    padding: 0 0 0 21px;
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background-size: contain;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background: url(../images/icon_jobFair.png) 50%/16px no-repeat;
    }
  }
  .p--area__link{
    display: inline-block;
    width: calc((100% - 64px) / 9) ;
    margin: 8px 0;
    padding: 8px 7px;
    border: none;
    border-radius: 4px;
    background: #fff;
    text-align: center;
    &:hover{
      border-top: 4px solid $clr-p80;
      padding-top: 4px;
    }
    &[aria-selected=true]{
      margin: 8px 0 0;
      padding: 5px 7px 17px;
      border-top: 4px solid $clr-p80;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: #fff7f6;
      color: $clr-p80;
      font-weight: bold;
      &:hover{
        padding-top: 5px;
      }
    }
  }
}




// エリア結果一覧
.p--jobFair__areaResult{
  margin: 33px 0 0;
  + .p--btnArea{
    margin: 25px 0 0;
  }
}
.p--areaResult{
  display: flex;
  flex-wrap: wrap;
  &[aria-hidden=true]{
    display: none;
  }
  &__item{
    width: calc((100% - 56px) / 8);
    margin: 0 8px 0 0;
    &--noData{
      display: flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      width: 100%;
      height: 106px;
      font-size: 16px;
      font-weight: bold;
    }
    &:nth-child(8n){
      margin: 0;
    }
    &:nth-child(n + 9){
      margin-top: 8px;
    }
    a{
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 2px;
      border: 1px solid $clr-g40;
    }
    .p--item__heading{
      padding: 4px 14px;
      background: $clr-g20;
      color: $clr-p80;
      font-weight: bold;
      text-align: center;
      &.c--arrow--p__right:before {
        right: 5px;
      }
    }
    .p--item{
      &__btm{
        padding: 7px;
        text-align: center;
      }
      &__tag{
        display: inline-block;
        min-width: 56px;
        padding: 0 7px;
        border: 1px solid $clr-p80;
        border-radius: 100px;
        color: $clr-p80;
        font-size: remSize(10);
        &--bgOn{
          border-color: $clr-p60;
          background: $clr-p60;
          color: $clr-w;
        }
        &--lightBgOn{
          border-color: $clr-p60;
          background: $clr-p10;
          color: $clr-p60;
        }
      }
      &__date{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3px 0 0;
        font-weight: bold;
        .p--date{
          font-size: remSize(30);
        }
        .p--week{
          display: inline-block;
          width: 25px;
          height: 25px;
          margin: 3px 0 0 2px;
          border: 1px solid $clr-p80;
          border-radius: 50%;
          color: $clr-p80;
          &--pale{
            color: $clr-p60;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px){
  //  エリア別で絞り込む
  .p--search__jobFair{
    padding: 14px 0 40px;
  }

  .p--area{
    .p--area__link{
      margin: 4px 0;
    }

  }

  .p--jobFair__area{
    display: block;
    padding: 18px 20px;
    .p--area{
      flex-wrap: wrap;
      margin: 10px 0 0;
      &__link{
        width: calc((100% - 16px) / 3);
        font-size: remSize(12);
        &[aria-selected=true]{
          margin: 4px 0;
          padding: 4px 7px 8px;
          border-radius: 4px;
          background: $clr-w;
          &:hover{
            padding-top: 4px;
          }
        }
      }
    }
  }

  // エリア結果一覧
  .p--jobFair__areaResult{
    padding: 0 10px;
    + .p--btnArea{
      margin: 23px 0 0;
      .c--btn{
        width: calc(100% - 20px);
      }
    }
  }
  .p--areaResult__item{
    width: calc((100% - 15px) / 4);
    margin: 0 5px 0 0;
    &--noData{
      width: 100%;
      height: auto;
      margin: 0;
    }
    &:nth-child(4n){
      margin: 0;
    }
    &:nth-child(n + 5){
      margin-top: 5px;
    }
    .p--item__heading{
      padding: 4px 14px 4px 0;
      font-size: remSize(10);
    }

    .p--item__btm{
      padding: 10px 5px;
    }
    .p--item__date{
      .p--date{
        font-size: remSize(18);
      }
      .p--week{
        display: inline-block;
        width: 1rem;
        height: 1rem;
        margin: 0 0 0 2px;
        font-size: remSize(10);
        line-height: 1.1;
      }
    }

  }

}



/** ========================================
 * @p--featured
 * 注目の特集
 * ====================================== */
.p--featured{
  padding: 57px 0;
  background: $clr-w url(../images/featured_bg_pc.png) no-repeat left top/100%;
  .p--simpleTab{
    margin-top: 40px;
  }
  .c--dot__line{
    margin: 24px 0;
  }
  .c--secondary__heading{
    margin: 12px 0 0;
    + p{
      margin: 25px 0 0;
    }
  }
  .p--btnArea{
    margin: 28px 0 0;
  }
  &__main{
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0;
  }
  .p--main__img{
    width: 576px;
    img{
      height: 280px;
    }
  }
  .p--main__txt{
    width: calc(100% - 588px - 48px);
  }
  &__sub{
    .c--item__img{
      width: 282px;
      img{
        height: 180px;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--top + .p--featured{
    margin-top: 14px;
  }
  .p--featured{
    padding: 40px 0;
    background: $clr-w url(../images/featured_bg_sp.png) no-repeat left top/100%;
    .p--simpleTab{
      margin-top: 25px;
    }
    .p--tabBody{
      padding: 0 20px;
    }
    .c--primary__heading{
      padding: 0;
    }
    .c--secondary__heading{
      margin: 8px 0 0;
      + p{
        margin: 15px 0 0;
        line-height: 1.7;
      }
    }

    &__main{
      display: block;
      margin: 25px 0 0;
    }
    .p--main__img{
      width: 100%;
      img{
        height: calc((100vw - 20px) / 2.2207);
      }
    }
    .p--main__txt{
      width: 100%;
      margin: 17px 0 0;
      .c--btn{
        width: 100%;
      }
    }
    &__sub{
      .c--item__img{
        width: 100%;
        img{
          height: calc((50vw - 28px) / 1.5501)!important;
        }
      }
    }
  }

  .c--card--spCol1{
    padding: 0 20px;
    &.c--card a{
      flex-direction: row;
      flex-wrap: wrap;
    }
    .c--card__item{
      padding: 0 0 10px;
      &:not(:first-child){
        margin: 25px 0 0;
      }
      .c--item__img{
        width: 120px;
        img{
          height: 72.7px; // 横幅120px / 33 * 20
        }
      }
      .c--item__ttl{
        width: calc(100% - 128px);
        margin: 10px 0 0 8px;
        padding: 0;
        font-size: remSize(14);
      }
      .c--item__txt{
        width: 100%;
        padding: 0;
        margin-top: 10px;
        span{
          width: auto;
          margin: 0 15px 0 0;
        }
        span:nth-child(n+3){
          margin-top: 0;
        }
      }
    }
  }

}


/** ========================================
 * @p--info
 * お役立ち情報
 * ====================================== */
// リンク
.p--info{
  padding: 55px 0 32px;
  &Link{
    display: flex;
    justify-content: space-between;
    margin: 42px 0 0;
    &__item{
      width: 588px;
      height: 220px;
      img{
        border-radius: 4px;
      }
    }
    &__master{
      display: flex;
      justify-content: space-between;
      padding: 0 30px 0 25px;
      background: url(../images/info_master_pc.png) no-repeat left top/100%;
      color: $clr-w;
      &--simple{
        position: relative;
        .p--master__a{
          position: absolute;
          bottom: 24px;
          left: 50%;
          width: 360px!important;
          padding: 10px 20px!important;
          transform: translateX(-50%);
        }
      }
    }
  }
  .p--master{
    &__ttl{
      padding: 10px 0;
      width: 268px;
      .c--arrow--circle{
        color: #fff;
        margin: 13px 0 0 6px;
      }
    }
    &__txt{
      width: 230px;
      padding: 21px 0 0;
      font-size: remSize(12);
      a{
        font-size: remSize(14);
        font-weight: bold;
      }
    }
    &__top{
      display: flex;
      justify-content: space-between;
      span{
        display: inline-block;
        margin: 0 0 0 2px;
        letter-spacing: 2px;
      }
    }
    &__star{
      color: #fff04d;
    }
    &__q{
      margin: 10px 0 0;
      line-height: 1.8;
    }
    &__a{
      width: 100%;
      margin: 20px 0 0;
      padding: 9px 20px;
      color: $clr-p80;
    }
  }
}



// メニュー
.p--infoMenu{
  display: flex;
  margin: 24px 0 0;
  border: 1px solid $clr-g40;
  border-radius: 4px;
  &__item{
    width: calc(100% / 6);
    &:not(:first-child){
      border-left: 1px solid $clr-g40;
    }
    .p--item{
      width: 100%;
      height: 100%;
      padding: 23px 0;
      text-align: center;

      .p--btn{
        &__icon{
          &:before{
            width: 80px;
            height: 80px;
            background-size: 80px;
            margin: 0 auto 17px;
          }
          &:after{
            width: 44px;
            background-size: 100%!important;
          }
          &--work:after{
            height: 46px;
            background: url(../images/icon_work.png) 50% no-repeat;
          }
          &--topics:after{
            height: 46px;
            background: url(../images/icon_topics.png) 50% no-repeat;
          }
          &--campaign:after{
            height: 46px;
            background: url(../images/icon_campaign.png) 50% no-repeat;
          }
          &--manual:after{
            width: 78px;
            height: 78px;
            background: url(../images/icon_manual.png) 50% no-repeat;
          }
          &--analysis:after{
            height: 46px;
            background: url(../images/icon_analysis.png) 50% no-repeat;
          }
          &--practice:after{
            height: 46px;
            background: url(../images/icon_practice.png) 50% no-repeat;
          }
          &--consult:after{
            height: 46px;
            background: url(../images/icon_consult.png) 50% no-repeat;
          }
          &--ne:after{
            height: 46px;
            background: url(../images/icon_ne.png) 50% no-repeat;
          }
        }
        &__ttl{
          color: #e92d82;
          font-size: remSize(16);
        }
        &__txt{
          margin: 8px 0 0;
          font-size: remSize(13);
          font-weight: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  // リンク
  .p--info{
    padding: 39px 20px 32px;
    .c--primary__heading{
      padding: 0;
    }
    &Link{
      display: block;
      margin: 25px 0 0;
      &__item{
        width: 100%;
        height: auto;
      }
      &__master{
        display: block;
        margin: 25px 0 0;
        padding: 0 16px 62px;
        border-radius: 4px;
        background:url(../images/info_master_sp-top.png) no-repeat left top/100%,url(../images/info_master_sp-btm.png) no-repeat left bottom/100%,url(../images/info_master_sp-mdl.png) repeat left top 25px/100%;
        img{
          padding: 0 5px;
        }
        &--simple{
          margin: 20px 0 0;
          .p--master__a{
            width: 61%!important;
            bottom: 14px;
            padding: 5px 20px!important;
            font-size: remSize(8);
          }
        }
      }
    }
    .p--master__ttl{
      width: 100%;
      padding: 24px 3px 0;
      .c--arrow--circle{
        margin: 15px 0 0 0;
      }
    }
    .p--master__txt{
      width: 100%;
      padding: 27px 8px 0;
      a{
        padding: 14px 20px;
      }
    }
    .p--master__top{
      justify-content: flex-start;
      p + p{
        margin: 0 0 0 15px;
      }
    }
    .p--master__a{
      margin: 18px 0 0;
    }
  }


  // メニュー
  .p--infoMenu{
    flex-wrap: wrap;
    border-top: none;
    &__item{
      width: calc(100% / 3);
      border-top: 1px solid $clr-g40;
      border-right: 1px solid $clr-g40;
      &:not(:first-child){
        border-left: none;
      }
      &:nth-child(3n){
        border-right: none;
      }
      .p--item{
        padding: 17px 5px 23px;
        .p--btn{
          &__icon{
            &:before{
              width: 60px;
              height: 60px;
              margin: 0 auto 6px;
            }
            &:after{
              width: 36px;
            }
            &--manual:after{
              width: 60px;
              height: 60px;
            }
          }
          &__ttl{
            font-size: remSize(13);
          }
          &__txt{
            font-size: remSize(11);
          }
        }
      }
    }
  }
}

/** ========================================
 * @p--hospital
 * お勧めの病院
 * ====================================== */
.p--hospital{
  padding: 25px 0 60px;
  .c--contents__inner{
    width: 1200px!important;
  }
  &__card{
    margin: 42px 0 0;
    .c--item__ttl{
      font-size: remSize(14);
    }
    .c--item__txt{
      font-size: remSize(12);
    }
  }
}

@media screen and (max-width: 767px){
  .p--hospital{
    margin-top: 20px;
    padding: 20px 0 40px;
    background: $clr-w;
    .c--contents__inner{
      width: 100%!important;
    }
    &__card {
      margin: 25px 0 0;
      &.c--hoverShadow--shadow{
        .c--hoverShadow__item{
          box-shadow: none;
        }
      }
    }
  }
}

/** ========================================
 * @p--news
 * お知らせ
 * ====================================== */
.p--news{
  padding: 60px 0 0;
  .c--dot__line{
    margin: 60px 0 0;
  }
  &__inner{
    display: flex;
    align-items: center;
  }
  .c--primary__heading{
    width: 204px;
    text-align: left;
  }
  .p--notices__list{
    width: calc(100% - 204px);
    border-top: 1px solid $clr-g30;
    border-bottom: 1px solid $clr-g30;
    a{
      border-radius: 4px;
    }
  }
}



@media screen and (max-width: 767px){

  .p--news{
    padding: 40px 10px 0;
    .c--dot__line{
      margin: 12px 10px 0;
    }
    &__inner{
      display: block;
    }
    .c--primary__heading{
      width: 100%;
      padding: 0 10px;
      text-align: center;
    }
    .c--notices__list{
      width: 100%;
      margin: 25px 0 0;
      .c--list__item:first-child{
        border-top: 1px solid $clr-g30;
      }
      a,div{
        flex-direction: column;
        padding: 7px 5px 19px;
        font-size: remSize(14);
        &.c--arrow--p__right:before{
          right: 15px!important;
        }
        .c--item__txt{
          width: calc(100% - 20px);
          margin: 7px 0 0;
        }
      }
    }
  }

}


/** ========================================
 * @p--top__fixed__bnr
 * 追従バナー(SPのみ)
 * ====================================== */

.p--top__fixedBnr{
  display: none;
}
@media screen and (max-width: 767px){
  .p--top__fixedBnr{
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1010;
    opacity: 0;
    .p--closeBtnBox{
      position: absolute;
      top: 0;
      right: 0;
    }
    .p--closeBtn{
      position: relative;
      width: 25px;
      height: 25px;
      padding: 0;
      background: transparent;
      border: none;
      &:before,
      &:after{
        content: "";
        position: absolute;
        left: 50%;
        width: 15px;
        height: 2px;
        background-color: #000;
        top: 50%;
      }
      &:before{
        transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      }
      &:after{
        transform: translateY(-50%) translateX(-50%) rotate(45deg);
      }
    }
  }
  
}
@media screen and (max-width: 767px){
  .p--areaResult__item .p--item__tag{
    position: relative;
    height: 17px;
    margin: 4px 0 0;
    i{
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      top: 50%;
      height: 10px;
      transform: translateX(-50%) translateY(-50%) scale(0.7, 1);
      line-height: 1;
    }
  }
}


/** ========================================
 * @p--searchLinks__block
 * 検索リンク集
 * ====================================== */
.p--searchLinks{
  &Block{
    padding: 40px 0 0;
    .c--dot__line{
      margin-top: 80px;
    }
    .p--searchArea{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 0;
      &__heading{
        width: 115px;
        font-size: 14px;
        font-weight: bold;
        &:nth-child(n + 3){
          margin-top: 13px;
        }
      }
      &__body{
        width: calc(100% - 115px);
        font-size: 12px;
        &:nth-child(n + 3){
          margin-top: 13px;
        }
      }
    }
  }
  
  &__heading{
    color: #e92d82;
    font-size: 14px;
    font-weight: bold;
    &:nth-child(n + 2){
      margin-top: 32px;
    }
  }
  &__body{
    margin: 15px 0 0;
    a{
      position: relative;
      font-size: 12px;
      &::after{
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: 0 6px -2px;
        background: #D8D8D9;
      }
      &:last-of-type{
        &::after{
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 767px){
  .p--searchLinks{
    &Block{
      padding: 40px 20px 32px;
      .p--searchArea{
        display: block;
        &__heading{
          width: 100%;
        }
        &__body{
          width: 100%;
          margin-top: 5px;
          &:nth-child(n + 3){
            margin-top: 5px;
          }
        }
      }
      .c--dot__line{
        display: none;
      }
    }
    &__heading{
      &:nth-child(n + 2){
        margin-top: 22px;
      }
    }
  }
}


/** ========================================
 * @p--featured_cards
 * 取材特集 20240618
 * ====================================== */
.p--featuredSlider{
  position: relative;
  &.p--no__slider{
    .swiper-wrapper{
      display: flex;
    }
    .swiper-slide{
      width: calc(25% - 18px);
      margin-right: 24px;
    }
  }

  .swiper-slide{
    height: auto;
    .c--item__ttl{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  /** --------------------
   * コントローラーのラッパー
   * ------------------ */
  .p--featuredSlider__btns {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .c--btns__inner{
      position: relative;
      width: 100%;
      height: 100%;
    }

    /** --------------------
    * swiper上書き
    * ------------------ */
    /* ----- 矢印ボタン ----- */
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      width: 30px;
      height: 30px;
      margin: 0;
      background: #ff80ab;
      border-radius: 50%;
      outline: none;
      transform: translateY(-50%);
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      top: 50%;
      left: -55px;
      right: auto;
    }
    
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      top: 50%;
      right: -55px;
      left: auto;
    }
    
    .swiper-button-next:after,
    .swiper-button-prev:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
    
    }
    .swiper-button-next:after {
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: translateX(-1px) rotate(45deg);
    }
    .swiper-button-prev:after {
      border-top: 2px solid #fff;
      border-left: 2px solid #fff;
      transform: translateX(1px) rotate(-45deg);
    }
  }
}
@media screen and (max-width: 767px){
  .p--featuredSlider{
    width: 80%;
    margin: 0 auto;
    &.p--no__slider{
      width: 100%;
      .swiper-slide{
        width: calc(50% - 8px);
        margin-right: 16px;
      }
    }

    .p--hospital__card{
      .c--item__ttl{
        font-size: remSize(12);
      }
      .c--card__item{
        .c--item__txt{
          padding: 0;
          span{
            width: 100%;
            margin: 0;
          }
        }
      }
    }

    .swiper-slide{
      img{
        height: 22.3vw;
      }
    }

    /** --------------------
    * コントローラーのラッパー
    * ------------------ */
    .p--featuredSlider__btns {
      /** --------------------
      * swiper上書き
      * ------------------ */
      /* ----- 矢印ボタン ----- */
      .swiper-button-next,
      .swiper-button-prev {
        width: 28px;
        height: 28px;
      }

      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        left: -31px;
      }

      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: -31px;
      }
    }
  }
}
