/*! 
 * ヘルパークラス
 */


.hr__solid{
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #ddd;
}

.u--table__scroll{
  overflow-x: scroll;
}
.u--table__auto{
  overflow-x: auto;
}
.u--tLayout{
  &--fixed{
    table-layout:fixed;
  }
  &--auto{
    table-layout:auto;
  }
}
/** ========================================
 * display
 * ====================================== */
.u--disp__inline{
  display: inline-block;
}

/** ========================================
 * 配置汎用
 * ====================================== */
.u--txtal{
  &__center{
    text-align: center !important;
  }
  &__left{
    text-align: left !important;
  }
  &__right{
    text-align: right !important;
  }
}

.u--relative{
  position:relative !important;
}

@media screen and (max-width: 768px) {
  .u--sptxtal{
    &__center{
      text-align: center !important;
    }
  }
}

/** ========================================
 * 横並び汎用
 * ====================================== */
.u--flexBlock{
  display: flex;
  &__wrap{
    flex-wrap: wrap;
  }
  &__between{
    justify-content: space-between;
  }
  &__justEnd{
    justify-content: flex-end;
  }
  &__justCenter{
    justify-content: center;
  }
  &__itemsStart{
    align-items: flex-start;
  }
  &__itemsCenter{
    align-items: center;
  }
}



/** ========================================
 * @
 * テーブルの幅用
 * ====================================== */
@for $i from 0 through 250 {
  $val : ($i+1);
  .u--w__per--#{$val} {
    width: #{$val}% !important;
  }
  .u--w__per--rwd#{$val} {
    width: #{$val}% !important;
  }
}
@for $i from 1 through 250 {
  $val : ($i*5);
  .u--w__px--#{$val} {
    width: #{$val}px !important;
    max-width: #{$val}px !important;
    min-width: #{$val}px !important;
  }
  .u--w__px--rwd#{$val} {
    width: #{$val}px !important;
    max-width: #{$val}px !important;
    min-width: #{$val}px !important;
  }
}

@media screen and (max-width: 768px) {
  @for $i from 1 through 250 {
    $val : ($i+1);
    .u--w__per--rwd#{$val} {
      width: 100% !important;
    }
  }
  @for $i from 1 through 250 {
    $val : ($i*5);
    .u--w__px--rwd#{$val} {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }
}

.u--mx__w--0{
  max-width: 0 !important;
}
.u--mi__w--auto{
  min-width: auto !important;
}

.u--w__auto{
  width:auto !important;
}
.u--h__auto{
  height:auto !important;
}

// 線
.u--bdb__no{
  border-bottom: none !important;
}
.u--bdt__no{
  border-top: none !important;
}

// テキスト位置
.u--vtc__al{
  &--top{
    vertical-align: top !important;
    &--th{
      th{
        vertical-align: top !important;
      }
    }
    &--td{
      td{
        vertical-align: top !important;
      }
    }
  }
  &--middle{
    vertical-align: middle !important;
  }
  &--bottom{
    vertical-align: bottom !important;
  }
 }


/** ========================================
 * @
 * 余白用
 * ====================================== */
.u--mg--0 {
  margin: 0 !important;
}
.u--mgt--0{
  margin-top: 0 !important;
}
.u--mgr--0{
  margin-right: 0 !important;
}
.u--mgb--0{
  margin-bottom: 0 !important;
}
.u--mgl--0{
  margin-left: 0 !important;
}
.u--pd--0 {
  padding: 0 !important;
}
.u--pd--5 {
  padding: 5px !important;
}
.u--pdr--0 {
  padding-right: 0 !important;
}
.u--pdl--0 {
  padding-left: 0 !important;
}
.u--pdt--0{
  padding-top: 0 !important;
}
.u--pdb--0{
  padding-bottom: 0 !important;
}


@for $i from 1 through 100 {
  $val : ($i*5);
  .u--mgt--#{$val} {
    margin-top: #{$val}px !important;
  }
  .u--mgb--#{$val} {
    margin-bottom: #{$val}px !important;
  }
  .u--mgl--#{$val} {
    margin-left: #{$val}px !important;
  }
  .u--mgr--#{$val} {
    margin-right: #{$val}px !important;
  }
  .u--pdt--#{$val} {
    padding-top: #{$val}px !important;
  }
  .u--pdb--#{$val} {
    padding-bottom: #{$val}px !important;
  }
  .u--pdl--#{$val} {
    padding-left: #{$val}px !important;
  }
  .u--pdr--#{$val} {
    padding-right: #{$val}px !important;
  }
}

$vp: 768;
$rate: 100 / $vp;
@media screen and (max-width: 768px) {
  @for $i from 1 through 100 {
    $val : ($i*5);
      .u--mgt--#{$val} {
        margin-top: $rate * $val * 1vw !important;
      }
      .u--mgb--#{$val} {
        margin-bottom: $rate * $val * 1vw !important;
      }
      .u--mgl--#{$val} {
        margin-left: $rate * $val * 1vw !important;
      }
      .u--mgr--#{$val} {
        margin-right: $rate * $val * 1vw !important;
      }
      .u--pdt--#{$val} {
        padding-top: $rate * $val * 1vw !important;
      }
      .u--pdb--#{$val} {
        padding-bottom: $rate * $val * 1vw !important;
      }
      .u--pdl--#{$val} {
        padding-left: $rate * $val * 1vw !important;
      }
      .u--pdr--#{$val} {
        padding-right: $rate * $val * 1vw !important;
      }
  }
}

.u--mgl--210--rwd{
  margin-left:210px;
}
@media screen and (max-width: 768px) {
  .u--mgl--210--rwd{
    margin-left:0;
  }
  .u--sppdlr--20{
    padding: 0 20px;//スマホの時だけ左右paddingをつける用
  }
  .u--sppdb--30{
    padding-bottom: 30px;//スマホの時だけ下paddingをつける用
  }
  .u--sppdb--40{
    padding-bottom: 40px;//スマホの時だけ下paddingをつける用
  }
}
/** ========================================
 * @
 * 文字関連
 * ====================================== */
.u--fs{
  &__10{
    font-size: remSize(10) !important;
  }
  &__11{
    font-size: remSize(11) !important;
  }
  &__12{
    font-size: remSize(12) !important;
  }
  &__13{
    font-size: remSize(13) !important;
  }
  &__14{
    font-size: remSize(14) !important;
  }
  &__16{
    font-size: remSize(16) !important;
  }
  &__18{
    font-size: remSize(18) !important;
  }
  &__22{
    font-size: remSize(22) !important;
  }
  &__24{
    font-size: remSize(24) !important;
  }
}
.u--fw{
  &__bold{
    font-weight: bold !important;
  }
  &__normal{
    font-weight: normal !important;
  }
}
.u--color{
  &__red{
    color: #EB2B51 !important;
  }
  &__dkred{
    color: #B20000 !important;
  }
  &__navy{
    color: #00468C !important;
  }
  &__skyblue{
    color: #00AAEB !important;
  }
  &__white{
    color: #FFF !important;
  }
}



.u--bg{
  &__f2fbfd{
    background:#f2fbfd !important;
    td ,th{
      background:#f2fbfd !important;
    }
  }
  &__e5e5e5{
    background:#e5e5e5 !important;
    td ,th{
      background:#e5e5e5 !important;
    }
  }
  &__f5f6f8{
    background:#f5f6f8 !important;
    td ,th{
      background:#f5f6f8 !important;
    }
  }
  &__ffe8e9{
    background:#ffe8e9 !important;
    td ,th{
      background:#ffe8e9 !important;
    }
  }
  &__error{
    padding: 16px 13px;
    background:#ffe8e9 !important;
  }
}



.u--ellipsis {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.u--lineClamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  &--2{
    -webkit-line-clamp: 2;
    max-height: 34px;
  }
  &--3{
    -webkit-line-clamp: 3;
    max-height: 52px;
  }
}

@media screen and (max-width: 768px) {
  .u--fs{
    &__8--sp__only{
      font-size: remSize(8) !important;
    }
  }
}
