/*! 
 * ログイン関連
 */

/** ========================================
 * @p--login
 * ログイン
======================================== */
.p--login{
  /*全体*/
  &__area{
    width:600px;
    margin:0 auto;
  }
  &__box{
    width:600px;
    margin:30px auto 0;
    /*ピンク*/
    &--p{
      border-top:2px solid $clr-p80;
    }
    /*青*/
    &--b{
      border-top:2px solid $clr-lb60;
    }
    .p--signup__title{
      margin:20px 0 10px;
    }
  }
  /*タイトル*/
  &__heading{
    font-size: remSize(20);
    font-weight: bold;
    text-align: center;
    padding:15px 0;
    border-bottom: 1px solid #dbdbdc;
  }
  /*下のブロック*/
  &__block{
    padding:25px 40px;
  }
  &__txt{
    font-size: remSize(16);
  }
  &__btn{
    width: 300px;
  }
}
/*パスワード*/
.p--pswd{
  &__btn{
    width: 300px;
  }
}
/*画像認証*/
.p--authentication{
  display: flex;
  &__btn{
    margin-left: 20px;
    p{
      margin-top:5px;
    }
  }
}
@media screen and (max-width: 767px){
  .p--login{
    &__area{
      width:100%;
      padding:0 20px;
    }
    &__box{
      width:100%;
    }
    /*タイトル*/
    &__heading{
      font-size: remSize(16);
      text-align: left;
      padding:15px;
    }
    /*下のブロック*/
    &__block{
      padding:25px 20px;
    }
    &__txt{
      font-size: remSize(14);
    }
    &__btn{
      width: 100%;
    }
  }
  /*パスワード*/
  .p--pswd{
    &__btn{
      width: calc(100% - 100px );
    }
  }
  /*画像認証*/
  .p--authentication{
    display: block;
    &__img{
      img{
        width:auto;
      }
    }
    &__btn{
      margin-left: 0;
      margin-top:10px;
      p{
        margin-top:5px;
      }
    }
  }
}


/** ========================================
 * @p--rule
 * 利用規約
======================================== */
.p--rule{
  .c--contents__inner{
    width: 896px;
  }
  .c--boxWht{
    margin: 37px 0 0;
    padding: 27px 25px;
  }
  .c--btnArea{
    margin: 40px 0 30px;
    text-align: center;
    .c--btn--pk{
      width: 296px;
      padding: 20px 22px;
    }
  }
}

.p--ruleHeading{
  &:not(:first-child){
    margin: 48px 0 0;
  }
  + p{
    margin: 25px 0 0;
  }
}
.p--ruleList{
  margin: 25px 0 0;
  dt{
    margin-bottom: 10px;
    font-weight: bold;
    &:not(:first-child){
      margin-top: 20px;
    }
  }
  dd{
    line-height: 1.65;
  }
  + p{
    margin: 23px 0 0;
  }
  ul{
    li{
      a{
        color: $clr-p80;
      }
    }
    + p{
      margin: 25px 0 0;
    }
  }
  .p--rulrList--normal{
    > li{
      padding-left: .9em;
      text-indent: -.9em;
      p > a{
        padding-left: .9em;
      }
    }
    .p--rulrList--brackets{
      padding-left: 0;
      > li{
        padding-left: 1.5em;
        text-indent: -1.5em;
      }
    }
  }
  .p--rulrList--brackets{
    > li{
      padding-left: 1.9em;
      text-indent: -2.5em;
    }
    .p--rulrList--hyphen{
      padding: 0;
      > li{
        padding-left: .35em;
        text-indent: -.35em;
      }
    }
  }
  .p--rulrList--hyphen{
    padding-left: 1em;
  }
}


@media screen and (max-width: 767px){
  .p--rule{
    .c--contents__inner{
      width: 100%;
    }
    .c--boxWht {
      margin: 37px 0 0;
      padding: 17px 20px;
    }
    .c--btnArea{
      padding: 0;
      .c--btn--pk{
        width: 100%;
      }
    }
    
    &Box *{
      word-break: normal !important;
      word-wrap: break-word;
    }

  }
}



// 20240402追加 - 別の見え方
.p--rule--ver02{
  *{
    line-height: 1.5!important;
  }
  .c--contents{
    padding: 60px 0 36px;
  }
  .c--contents__inner{
    font-size: 14px;
    margin: 0 auto;
    width: 1024px;
  }
  .c--primary__heading{
    border-left: 4px solid #E63182;
    padding: 0 0 0 1rem;
    text-align: left;
    font-size: 1.75rem;
    font-weight: normal;
  }
  .c--boxWht{
    background-color: #fff;
    margin: 36px auto 0 auto;
    padding: 40px;
  }
  .p--ruleList{
    &:first-of-type{
      margin-top: 0;
    }
    &__title{
      background-color: #fff7f6;
      color: #e92d82;
      font-size: 1.25rem;
      font-weight: normal;
      margin: 20px 0 10px;
      padding: 7px 13px;
      &:first-of-type{
        margin-top: 0;
      }
      &--sml{
        background: transparent;
        color: #e92d82;
        font-size: 1.1rem;
        margin: 20px 0 10px;
        padding: 0 0 0 1rem;
      }
    }
    &__subtitle{
      font-weight: normal;
      padding: 0 0 0 2rem;
      + .p--ruleList__body{
        margin-top: 25px;
      }
    }
    &__body{
      padding: 0 0 0 2rem;
    }
    &--hyphen{
      padding-left: 1.75rem;
    }
  }
  .p--poricy__iframe{
    width: 100%;
    height: 1500px;
    margin: 40px 0;
  }
  .c--innerTable{
    border-collapse: collapse;
    display: block;
    margin: 20px 0;
    th,
    td{
      border: 1px solid #ddd;
      padding: 10px;
      word-break: break-all;
    }
    th{
      background: #eee;
      text-align: left;
    }
  }
}
@media screen and (max-width: 767px){
  .p--rule--ver02{
    .c--contents{
      padding: 30px 0 10px;
    }
    .c--contents__inner{
      width: 100%;
    }
    .c--primary__heading{
      border-left: 4px solid #E63182;
      font-size: 1.25rem;
      margin: 0 20px;
      padding: 0 0 0 .5rem;
    }
    .c--boxWht{
      margin: 18px auto 0 auto;
      padding: 20px;
    }

    .p--ruleList{
      &__subtitle,
      &__body{
        padding: 0 0 0 .5rem;
      }
    }
    .c--innerTable{
      *{
        word-break: break-all !important;
      }
    }

  }
}