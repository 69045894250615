/*! 
 * SCHL-001
 */

/* 色の定数 */
$CLR-HOKKAIDO: #b5d951;
$CLR-TOHOKU: #3dd9a9;
$CLR-KANTO: #ffbf2a;
$CLR-HKURIKU: #c983e6;
$CLR-TOKAI: #6b98eb;
$CLR-KINKI: #ff9d04;
$CLR-CHUGOKU: #f48fd1;
$CLR-KYUSYU: #58cfed;


/** ========================================
 * ページの上部：ヒーローエリアと見出し
 * ====================================== */
.p--schl {
  &__heroImage {
    display: inline-block;
    margin-top: 56px;
  }
  &__heading {
    margin-top: 46px;
  }
  &__lead {
    margin-top: 30px;
    text-align: center;
    p:not(:nth-of-type(1)) {
      margin-top: 28px;
    }
  }
}


/** ========================================
 * 地域タブ流用 - 上書き
 * ====================================== */
.p--schl__tab {
  margin-top: 42px;
  .p--area, .p--jobFair__area {
    justify-content: flex-start;
  }
  .p--area .p--area__link {
    margin-right: 8px;
  }
  .p--area .p--area__link--disabled {
    opacity: .6;
    pointer-events: none;
  }
  .p--area .p--area__link--disabled:hover {
    border-top: none;
    padding-top: 8px;
  }
}




/** ========================================
 * 地域セクション
 * ====================================== */
.p--areaContainer {
  position: relative;
  margin-top: 50px;
  padding-top: 34px;

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, #d9d9db ,#d9d9db 3px, transparent 0, transparent 13px);
    background-size: 13px 3px;
    background-repeat: repeat-x;
  }

  &__heading {
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold;
  }
}

/** ========================================
 * スクールカード
 * ====================================== */
.p--schoolCards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  .p--item {
    flex: 0 0 170px;
    padding: 2px;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 6px;
    &:not(:last-child) {
      margin-right: 8px;
    }
    &:nth-child(n + 7) {
      margin-top: 12px;
    }
    &:hover {
      @extend .c--hoverShadow__item:hover;
    }

    &__area {
      padding: 6px;
      background-color: #d9d9db;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      &:before{
        right: 5px;
        width: 6px;
        height: 6px;
        border-color: $clr-w;
      }
    }
    &__dateTime {
      margin-top: 10px;
      text-align: center;
      .p--dateTime__year {
        font-size: .75rem;
      }
      .p--dateTime__date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px 0 0;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
      }
      .p--date_dayOfWeek {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 0 0 2px;
        border-radius: 50%;
        background: $clr-g70;
        color: $clr-w;
        font-size: remSize(14);
        line-height: 24px;
        &--sun {
          background: #E92D82;
        }
        &--sat {
          background: #00B0FF;
        }
      }
      .p--dateTime__time {
        margin: 3px 0 0;
        font-size: .75rem;
      }
    }
    &__txt{
      margin: 4px 10px 0;
      padding: 7px 0 10px;
      border-top: 1px solid $clr-g20;
      font-size: remSize(12);
      line-height: 1.2;
    }
  }
}


/** ========================================
 * 地域ごとのカラー設定
 * ====================================== */
@mixin areaSet($area, $color) {
  &--#{$area} {
    &::before {
      background-image: linear-gradient(90deg, $color , $color 3px, transparent 0, transparent 13px);
    }
    .p--schoolCards .p--item__area {
      background-color: $color;
    }
  }
}

.p--areaContainer {
  @include areaSet(hokkaido, $CLR-HOKKAIDO);
  @include areaSet(tohoku, $CLR-TOHOKU);
  @include areaSet(kanto, $CLR-KANTO);
  @include areaSet(hokuriku, $CLR-HKURIKU);
  @include areaSet(tokai, $CLR-TOKAI);
  @include areaSet(kinki, $CLR-KINKI);
  @include areaSet(chugoku, $CLR-CHUGOKU);
  @include areaSet(kyusyu, $CLR-KYUSYU);
}





@media screen and (max-width: 767px){

  /** ========================================
   * ページの上部：ヒーローエリアと見出し
   * ====================================== */
  .p--schl {
    .c--contents__inner{
      padding-bottom: 30px;
    }
    &__heroImage {
      display: flex;
      justify-content: center;
      margin-top: 0;
    }
    &__heading {
      margin-top: 25px;
      font-size: remSize(22);
    }
    &__lead {
      margin-top: 12px;
      padding: 0 20px;
      text-align: left;
      font-size: remSize(12);
      p:not(:nth-of-type(1)) {
        margin-top: 18px;
      }
    }
  }


  /** ========================================
   * 地域タブ流用 - 上書き
   * ====================================== */
  .p--schl__tab {
    margin-top: 28px;
    .p--area .p--area__link:nth-child(3n) {
      margin-right: 0;
    }
  }


  /** ========================================
   * 地域セクション
   * ====================================== */
  .p--areaContainer {
    margin: 30px 20px 0;
    padding-top: 22px;
    &__heading {
      font-size: 1.2rem;
    }
  }

  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards {
    margin-top: 17px;
    .p--item {
      flex: 0 0 calc((100% - 16px) / 3);
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 7px!important;
      }

      &__area {
        padding: 3px 6px;
        font-size: remSize(13);
      }
      &__dateTime {
        margin-top: 8px;
        .p--dateTime__year {
          line-height: 1;
        }
        .p--dateTime__date {
          font-size: 1.6rem;
        }
        .p--date_dayOfWeek {
          width: 22px;
          height: 22px;
          margin: 0 0 0 5px;
          font-size: .7rem;
        }
      }
      &__txt{
        margin: 4px 5px 0;
        padding: 5px 0 10px;
        border-top: 1px solid #f5f5f5;
        font-size: .45rem;
        line-height: 1.2;
      }
    }
  }
  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards {
    .p--item {
      &__dateTime {
        .p--dateTime__date {
          font-size: 1.3rem;
        }
        .p--date_dayOfWeek {
          width: 18px;
          height: 18px;
          font-size: .6rem;
          line-height: 18px;
        }
      }
    }
  }
}


@media screen and (max-width: 370px){
  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards{
    .p--item__txt{
      font-size: 1.8vw;
    }
  }
}