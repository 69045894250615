/*! 
 * 説明会申込
 */

/** ========================================
 * @p--aply
 * 説明会申込
 ======================================== */
// 共通
.p--aply{
  .p--contBox{
    padding: 0;
    &__inner{
      width: 846px;
      margin: 0 auto;
      padding: 30px 0;
    }
  }
  .c--primary__heading{
    + .p--topBdBox{
      margin: 35px 0 0;
    }
  }
  .c--boxlp{
    padding: 9px 16px;
    .c--ovalTag{
      margin: 0 15px 0 0;
    }
    p + p{
      margin: 7px 0 0;
      span{
        display: inline-block;
        margin: 0 15px 0 0;
      }
    }
  }
  .p--tagBox{
    margin: 25px 0 0;
  }
  .c--btnArea{
    + .p--form__infoWrap{
      margin-top: 38px;
    }
  }
  .p--form__table {
    margin: 25px 0 0;
  }
  .c--btnArea__formBox{
    margin: 40px auto 30px;
    .c--btn--pk{
      width: 296px;
      padding: 20px;
      &.c--btn--next{
        padding: 18px 22px;
      }
    }
  }
  div + .c--contHeading--gr{
    margin: 50px 0 0;
  }

  /** ====================
  * APLY-001_02
  ==================== */
  .p--dateBox{
    margin: 13px 0 0;
    .c--control{
      min-width: auto;
      margin-right: 5px;
      &__indicator{
        width: 16px;
        height: 16px;
      }
    }
    .c--ui__date{
      width: 140px;
      height: 30px;
      border-width: 1px;
    }
    .c--select{
      width: auto;
      margin: 0 5px;
      select{
        min-width: 70px;
        height: 30px;
        padding: 5px 10px;
        border-width: 1px;
      }
    }
    + .p--tagBox{
      margin-top: 20px;
    }
  }
  .p--infoBox--btm{
    margin: 0 0 80px;
  }


  /** ====================
  * APLY-002_03
  ==================== */
  .c--input__name{
    width: 155px;
  }

}

.p--detailInfoWrap{
  > .u--fw__bold{
    margin: 20px 0 0;
    color: $clr-p80;
  }
  .p--infoTxt{
    margin: 5px 0 0;
  }
}

.p--form__infoWrap{
  margin-bottom: 20px;
  &--topBd{
    margin-top: 28px;
    padding-top: 27px;
    border-top: 1px dotted #dadadb;
  }
  + .p--form__table{
    margin-top: 0;
  }
  .p--form__infoTxt{
    + .p--form__infoTxt{
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 767px){
  .p--aply{
    .p--contBox__inner{
      width: 100%;
      padding: 20px 0 30px;
    }
    .c--boxlp{
      margin: 0 20px;
      padding: 12px;
      .c--ovalTag{
        width: 100px;
        padding: 2px 5px;
        font-size: remSize(10);
        + span{
          display: block;
          width: 100%;
        }
      }
    }
    .p--tagBox{
      margin: 16px 0 0;
      padding: 0 20px;
    }
    .p--form__infoWrap{
      padding-right: 20px;
      padding-left: 20px;
    }
    .p--form__edit{
      margin: 28px 0 0;
      padding-top: 20px;
    }
    .c--btnArea__formBox{
      margin: 15px auto;
      .c--btn--pk{
        width: 100%;
      }
    }
    .p--infoBox--btm{
      margin: 20px 0 0;
      padding: 0 20px;
    }
    div + .c--contHeading--gr{
      margin: 0;
    }
    .c--primaryTable + .c--control{
      margin: 5px 25px;
    }
    .p--form__table + .p--tagBox{
      margin: 0;
    }
  
    .c--input__name{
      width: 48%;
    }

    /** ====================
    * APLY-001_02
    ==================== */
    .p--dateBox{
      padding: 0 20px;
    }

  }

  .p--detailInfoWrap{
    margin: 25px 0 0;
    padding: 0 20px;
    > .u--fw__bold{
      margin: 20px 0 0;
    }
  }


}



/** ====================
 * APLY-001_03
==================== */
.p--aply__reserved{
  .p--topBdBox__inner{
    padding-bottom: 60px;
  }
  .p--reserved__txt{
    margin: 7px 0 0;
  }
  .c--btnArea{
    margin: 30px auto 0;
    text-align: center;
    .c--btn{
      width: 335px;
      text-align: center;
    }
  }
}
@media screen and (max-width: 767px){
  .p--aply__reserved{
    .p--topBdBox__inner{
      padding-bottom: 35px;
    }
    .p--reserved__txt{
      padding: 0 20px;
    }
    .c--btnArea{
      margin: 20px auto 0;
      .c--btn{
        width: 100%;
      }
    }
  }
}


/** ====================
 * APLY-003_01
==================== */
.p--appFinish{
  &__txtArea{
    margin: 35px 0 0;
    text-align: center;
    > p:not(.p--ttl){
      margin: 25px 0 0;
    }
    .p--ttl{
      font-size: remSize(18);
    }
  }
  &__checkArea{
    margin: 45px 0 0;
    padding: 10px 25px;
    text-align: center;
    .p--ttl{
      text-align: left;
    }
  }
  .c--btn{
    min-width: auto;
    margin: 16px 0 0;
    padding: 17px 20px;
    font-size: remSize(14);
    + p{
      margin: 7px 0 10px;
    }
  }
  .c--btnArea__formBox{
    margin: 12px auto 0;
    .c--btn{
      min-width: 168px;
      + .c--btn{
        margin: 0 0 0 7px;
      }
    }
  }
  .p--fav__hospital{
    padding-top: 20px;
  }
}
@media screen and (max-width: 767px){
  .p--appFinish{
    &__txtArea{
      margin: 18px 0 0;
      padding: 0 20px;
      text-align: left;
      .p--ttl{
        font-size: 1rem;
      }
    }
    &__checkArea{
      margin: 30px 20px 0!important;
      padding: 10px 20px!important;
      .p--ttl{
        text-align: left;
      }
    }
    .c--btn{
      margin: 6px 0 0;
      padding: 13px 20px;
      font-size: remSize(12);
      + p{
        margin: 6px 0 13px;
        font-size: remSize(12);
      }
    }
    .c--btnArea__formBox{
      margin: 25px auto 0;
      .c--btn{
        margin: 0;
        padding: 13px 20px;
        font-size: remSize(12);
        + .c--btn{
          margin: 15px 0 0;
        }
      }
    }
    .p--fav__hospital{
      margin-top: 20px;
    }
  }
}





/** ========================================
 * @p--survey
 * アンケート回答
 ======================================== */
.p--survey{
  .p--contBox{
    width: 895px;
    margin: 37px auto 0;
    padding: 32px 22px;
    &.p--surveyTopInfo{
      padding: 0;
      background: transparent;
      .p--surveyTopInfo__ttl{
        font-size: 1rem;
      }
      .p--surveyTopInfo__txt{
        margin: 10px 0 0;
      }
    }
  }
  .p--form__edit{
    + .p--form__table{
      margin: 25px 0 0;
    }
  }
  .c--btnArea__formBox{
    margin: 30px 0 0;
    .c--btn--pk{
      width: 296px;
    }
  }
  &__finishTxt{
    margin: 42px 0 0;
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px){
  .p--survey{
    .p--contBox{
      width: 100%;
      margin: 28px auto 0;
      padding: 0;
      &.p--surveyTopInfo{
        padding: 0 20px;
        .p--surveyTopInfo__ttl{
          font-size: 1rem;
        }
        .p--surveyTopInfo__txt{
          margin: 10px 0 0;
        }
      }
    }
    .p--form__edit{
      padding: 15px 20px 0;
    }
    .c--btnArea__formBox{
      .c--btn--pk{
        width: 100%;
      }
    }
    .p--form__infoWrap{
      margin: 0;
      padding: 20px;
    }
    &__finishTxt{
      margin: 25px 0 0;
    }
  }
}

