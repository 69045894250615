/*! 
 * 相談関係
 */

/** ========================================
 * @p--cnst
 * 相談
======================================== */

/** ====================
 * 左メイン
==================== */
.p--cnst__bnr{
  display: inline-block;
  + .p--cnstSearch{
    margin: 30px 0 0;
  }
  &:not(:first-child){
    margin: 40px 0 0;
  }
  + .p--cnst__bnr{
    margin: 30px 0 0;
  }
}
.p--cnstSearch{
  padding: 20px;
  border-radius: 4px;
}
.p--cnstSearch__box{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  input{
    width: 690px;
    height: 55px;
  }
  .c--btn--input{
    width: 152px;
    height: 55px;
  }
}
.p--cnstSearch__word{
  margin: 15px 0 0;
  .p--word__tag{
    margin: 10px 0 0;
    .p--tag__item{
      display: inline-block;
      background: $clr-g40;
      margin: 0 8px 10px 0;
      padding: 8px 7px;
      border-radius: 4px;
      color: $clr-p80;
      font-size: remSize(12);
    }
  }
}

.p--cnstRslt{
  margin: 30px 0 0;
  &__tab{
    display: flex;
    justify-content: space-between;
    .p--tab{
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 5px);
      height: 61px;
      font-size: remSize(20);
      font-weight: bold;
      box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
      &:not(.p--tabSelect):hover{
        cursor: pointer;
      }
      &Select{
        height: 70px;
        padding-bottom: 13px;
        border-top: 4px solid $clr-p80;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0 0 2px -2px rgba(97,97,106,.2), 0 0 5px -5px rgba(97,97,106,.1);
      }
    }
  }

  .p--cnstRslt__main{
    padding: 23px 0;
    background: #fff;
    box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
    .c--page__ctrl{
      padding: 0 25px;
      &:first-child{
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--cnst{
    .c--error__list{
      padding: 0 20px;
      + .p--cnst__bnr{
        margin: 0;
      }
    }
    &__bnr{
      display: flex;
      justify-content: center;
    }
  }
  .p--cnstSearch{
    padding: 0;
    border-radius: 0;
  }
  .p--cnstSearch__box{
    padding: 0 20px;
    input{
      width: calc(100% - 106px);
      height: 50px;
    }
    .c--btn--input{
      width: 96px;
      height: 50px;
      padding: 14px 10px;
    }
  }
  .p--cnstSearch__word{
    margin: 10px 0 0;
    padding: 0 20px 5px;
    .p--word__ttl{
      font-size: remSize(12);
    }
  }
  
  .p--cnstRslt{
    &__tab{
      padding: 0 10px;
      .p--tab{
        height: 48px;
        font-size: remSize(14);
        &Select{
          padding-bottom: 11px;
          height: 55px;
          color: $clr-p80;
        }
      }
    }
  }


  .p--cateModalLink{
    margin: 20px;
    padding: 6px;
    color: $clr-p80;
    font-weight: bold;
    text-align: center;
  }
}



/** ====================
 * 右メニュー
==================== */

/* 色の定数 */
// 濃い色
$CLR-STUDENT: #266CBF;
$CLR-TRAINING: #CD8ACB;
$CLR-JOB: #EA56A3;
$CLR-STATE: #FF5784;
$CLR-OTHER: #FF9A66;
// 薄い色
$CLR-STUDENT-LC: rgba(38, 108, 191, .1);
$CLR-TRAINING-LC: rgba(205, 138, 203, .1);
$CLR-JOB-LC: rgba(234, 86, 163, .1);
$CLR-STATE-LC: rgba(255, 87, 132, .1);
$CLR-OTHER-LC: rgba(255, 154, 102, .1);

// カテゴリーリンク
.p--cnst{
  .c--menu__side{
    box-shadow: none;
    > div{
      box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
      + div{
        margin: 25px 0 0;
      }
    }
  }
}
.p--categoryListWrap{
  background: $clr-w;

  .p--category{
    &Link{
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 14px 14px 14px 46px;
      &:before{
        content: "";
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        width: 16px;
        height: 20px;
        background-size: contain!important;
        background-repeat: no-repeat;
      }
    }
    &--student{
      .p--categoryLink:before{
        background-image: url(../images/icon_student.png);
      }
    }
    &--training{
      .p--categoryLink:before{
        background-image: url(../images/icon_training.png);
      }
    }
    &--job{
      .p--categoryLink:before{
        background-image: url(../images/icon_job.png);
      }
    }
    &--test{
      .p--categoryLink:before{
        background-image: url(../images/icon_test.png);
      }
    }
    &--other{
      .p--categoryLink:before{
        background-image: url(../images/icon_other.png);
      }
    }
    &List{
      padding: 0 0 0 25px;
      li{
        .p--categoryLink{
          padding: 10px 20px;
          font-size: remSize(13);
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--cnst{
    .c--menu__side{
      padding: 0 0 60px;
    }
  }

}

/** ========================================
 * カテゴリーリンクのカラー設定
 * ====================================== */
 @mixin cateHeadSet($cateName, $color, $colorLc) {
  &--#{$cateName} {
    background-color: $colorLc;
    + dd{
      li{
        + li{
          border-top: 1px solid $color;
        }
      }
    }
  }
}
.p--category {
  @include cateHeadSet(student, $CLR-STUDENT, $CLR-STUDENT-LC);
  @include cateHeadSet(training, $CLR-TRAINING, $CLR-TRAINING-LC);
  @include cateHeadSet(job, $CLR-JOB, $CLR-JOB-LC);
  @include cateHeadSet(test, $CLR-STATE, $CLR-STATE-LC);
  @include cateHeadSet(other, $CLR-OTHER, $CLR-OTHER-LC);
}


/** ========================================
 * おすすめのQAのカラー設定
 * ====================================== */
@mixin askClrSet($cateName, $color) {
  &--#{$cateName} {
    color: $color;
    i{
      background-color: $color;
    }
  }
}
// おすすめのQA
.p--askList{
  background: $clr-w;
  li{
    + li{
      border-top: 1px solid $clr-g40;
    }
    a{
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 12px 17px;
    }
  }
  &__date{
    color: $clr-g60;
    font-size: remSize(12);
  }
}

.p--askList,
.p--askListMain{
  a{
    &:hover{
      .p--ask__txt{
        color: $clr-p80;
      }
    }
  }
}

.p--ask{
  &__cate{
    @include askClrSet(student, $CLR-STUDENT);
    @include askClrSet(training, $CLR-TRAINING);
    @include askClrSet(job, $CLR-JOB);
    @include askClrSet(test, $CLR-STATE);
    @include askClrSet(other, $CLR-OTHER);
  
    margin: 10px 0 0;
    font-size: remSize(12);
  
    i{
      display: inline-block;
      min-width: 68px;
      margin: 0 5px 0 0;
      padding: 2px 10px;
      border-radius: 100px;
      color: $clr-w;
      text-align: center;
    }
  }
  &__cmt{
    position: relative;
    margin: 7px 0 0;
    color: $clr-g60;
    font-size: remSize(12);
    padding: 0 0 0 20px;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 16px;
      height: 18px;
      background-size: contain!important;
      background-repeat: no-repeat;
      background-image: url(../images/icon_cmt.png);
    }
    &--new{
      &:after{
        content: "NEW";
        display: inline-block;
        margin: 0 0 0 10px;
        color: $clr-red;
      }
    }
  }
  &__txt{
    margin: 5px 0 0;
    font-weight: bold;

    .p--txt__sub{
      display: flex;
      align-items: center;
      .p--ask__cmt{
        margin: 0 0 0 15px;
      }
    }
  }
}

.p--askListMainTtl{
  margin: 10px 0 0;
  padding: 0 25px;
  font-weight: bold;
}
.p--askListMain{
  margin: 20px 0 0;
  a{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px 23px;
  }
  .p--ask__img{
    width: 110px;
    img{
      height: 80px;
    }
  }
  .p--ask__txt{
    width: calc(100% - 140px);
    .p--ask__cate{
      margin-top: 0;
    }
    .p--txt__ttl{
      position: relative;
      width: 100%;
      margin: 7px 0 0;
      padding: 0 0 0 30px;
      font-size: 1rem;
      &:before{
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 24px;
        height: 26px;
        background-size: contain!important;
        background-repeat: no-repeat;
        background-image: url(../images/icon_question.png);
      }
    }
  }
}




@media screen and (max-width: 767px){
  // おすすめのQA
  .p--ask{
    &__cate{
      i{
        min-width: 60px;
        margin: 0 5px 0 0;
        padding: 2px 6px;
        font-size: remSize(10);
      }
    }
    &__txt{
      .p--txt__sub{
        display: block;
        .p--ask__cmt{
          margin: 10px 0 0;
        }
      }
      + .p--txt__ttl{
        margin: 8px 0 0;
        font-size: remSize(14);
        font-weight: bold;
      }
      .p--txt__ttl{
        margin: 0;
      }
    }
  }


  .p--askListMainTtl{
    margin: 20px 0 0;
    padding: 0 20px;
  }
  .p--askListMain{
    margin: 12px 0 0;
    border-top: 1px solid $clr-g40;
    li + li{
      border-top: 1px solid $clr-g40;
    }
    a{
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 12px 20px;
    }
    .p--ask__img{
      width: 82px;
      img{
        height: 60px;
      }
    }
    .p--ask__txt{
      width: calc(100% - 95px);
      margin: 0;
    }
  }

  .p--menu__askArea{
    .c--contHeading--gr{
      background: transparent;
      color: #333;
      font-size: remSize(14);
    }
  }


  /** ========================================
  * モーダル
  * ====================================== */
  .c--modal--category{
    .ps__rail-y{
      top: 39px!important;
    }
    .ps__rail-y.ps--clicking .ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y:hover>.ps__thumb-y,
    .ps__thumb-y{
      background-color: $clr-p80;
    }
    .c--modal__content{
      max-height: 80vh;
      padding: 0;
    }
    .c--modal__mainArea{
      padding: 10px;
    }
    .c--modal__main{
      overflow-y: auto;
      max-height: calc(80vh - 59px);
      margin: 15px 0 0;
      a{
        outline: none;
      }
    }
    .c--modal__title{
      color: #333;
      font-size: 1rem;
    }
    .p--categoryListWrap{
      .p--categoryList{
        padding: 0 0 0 45px;
        li{
          a{
            padding: 13px 20px;
          }
        }
      }
    }
  }


}




/** ========================================
 * INFO-001.html 左メイン
======================================== */
.p--cnst__qaItem{
  margin: 30px 0 0;
  border-radius: 0 0 4px 4px;
  background: $clr-w;
  .p--qaItem__heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 93px;
    padding: 15px 25px 25px;
    &--q{
      border-top: 2px solid $clr-lb80;
      background: $clr-lb10;
    }
    &--a{
      padding-top: 5px;
      border-top: 2px solid $clr-p80;
      background: $clr-p20;
    }
    .p--ask__cate{
      margin: 0;
    }
    .p--qaItem__ttl{
      position: relative;
      margin: 8px 0 0;
      padding: 0 0 0 73px;
      font-size: remSize(18);
      font-weight: bold;
      &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 10px;
        width: 42px;
        height: 44px;
        background-size: contain!important;
        background-repeat: no-repeat;
      }
      &--q{
        &::before{
          background-image: url(../images/icon_question-l.png);
        }
      }
      &--a{
        &::before{
          background-image: url(../images/icon_answer-l.png);
        }
      }
    } //p--qaItem__ttl
  } //p--qaItem__heading

  .p--qaItem__body{
    padding: 18px 0 20px;
    > p{
      padding-right: 25px;
      padding-left: 25px;
    }
    > div{
      padding: 0 25px 18px 25px;
      &:nth-child(even){
        background: $clr-g20;
        .p--qaItem__ttl{
          color: $clr-g70;
        }
      }
      + div{
        padding-top: 18px;
        border-top: 1px dotted $clr-g40;
      }
      &:last-child{
        padding-bottom: 0;
      }
    }
    .p--qaItem__answer{
      display: flex;
      justify-content: space-between;
      .p--answer__img{
        width: 110px;
        img{
          height: 110px;
        }
      }
      .p--answer__box{
        width: calc(100% - 133px);
      }
      .p--answer__ttl{
        color: $clr-p80;
        font-size: remSize(15);
        font-weight: bold;
      }
      .p--answer__txt{
        margin: 8px 0 0;
      }
    }
    .p--qaItem__ttl{
      font-size: remSize(12);
      font-weight: bold;
    }

    .p--qaItem__profile{
      .p--profile{
        &__box{
          min-height: 110px;
          font-size: remSize(13);
        }
        &__ttl{
          width: calc(100% - 150px);
          margin-bottom: 8px;
        }
        &__imgBox{
          float: right;
          width: 150px;
          margin-left: 40px;
          &::before,
          &::after{
            content: "";
            clear: both;
            display: block;
          }
          img{
            height: 110px;
          }
        }
      }
    }

    .p--linkList{
      margin: 10px 0 0;
      li{
        margin: 7px 0 0;
      }
    }
  
  } //p--qaItem__body
} //p--cnst__qaItem


.p--cnst__cmt{
  margin: 32px 0 0;
  .p--cmt__ttl{
    font-weight: bold;
  }
  .c--boxWht{
    margin: 12px 0 0;
    padding: 23px;
    border-radius: 4px;
    &.p--show__block{
      padding: 0;
    }
    > p{
      margin: 25px 0 0;
      text-align: center;
    }
    .p--cmt__link{
      font-size: remSize(15);
    }
    .p--cmt__btn{
      margin: 18px 0 0;
    }
    .p--txtCntBox{
      .p--txtCntInfo{
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
}
.p--show__block--cmt{
  li{
    padding: 18px 20px 28px;
    p{
      position: relative;
      padding: 0 0 0 50px;
      &::before{
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 24px;
        height: 26px;
        background-size: contain!important;
        background-repeat: no-repeat;
        background-image: url(../images/icon_answer.png);
      }
    }
    + li{
      border-top: 1px solid $clr-g40;
    }
  }
}

.p--loginBtnBox{
  margin: 33px 0 0;
  text-align: center;
  .c--btn{
    width: 296px;
    font-size: 1rem;
    + .c--btn{
      margin-left: 10px;
    }
  }
  > p{
    margin: 18px 0 18px;
    font-weight: bold;
  }
}
.p--page__ctrl--col2{
  .c--btn--m{
    &:first-child{
      margin: 0;
    }
  }
}


@media screen and (max-width: 767px){
  .p--cnst__qaItem{
    margin-top: 26px;
    border-radius: 0;
    + .p--cnst__qaItem{
      margin-top: 0;
    }
    .p--qaItem__heading{
      padding: 11px 20px 15px;
      .p--qaItem__ttl{
        margin: 5px 0 0;
        padding: 0 0 0 35px;
        font-size: remSize(16);
        &::before{
          top: .25em;
          left: 0;
          width: 23px;
          height: 24px;
          transform: translateY(0);
        }
      } //p--qaItem__ttl
    } //p--qaItem__heading
  
    .p--qaItem__body{
      padding: 12px 0;
      > p{
        padding-right: 20px;
        padding-left: 20px;
      }
      > div{
        padding: 0 20px 18px 20px;
        + div{
          padding-top: 12px;
        }
      }
      .p--qaItem__answer{
        flex-wrap: wrap;
        .p--answer__img{
          width: 60px;
          img{
            height: 60px;
          }
        }
        .p--answer__box{
          width: calc(100% - 70px);
        }
        .p--answer__txt{
          margin: 10px 0 0;
        }
      }

      .p--qaItem__profile{
        .p--profile{
          &__box{
            min-height: auto;
            font-size: remSize(12);
          }
          &__ttl{
            width: calc(100% - 110px);
            margin-bottom: 4px;
            font-size: remSize(10);
          }
          &__imgBox{
            width: 110px;
            margin: 20px 0 5px 20px;
            img{
              height: 80px;
            }
          }
        }
      }

    } //p--qaItem__body
  } //p--cnst__qaItem
  
  
  .p--cnst__cmt{
    margin: 28px 0 0;
    .p--cmt__ttl{
      padding: 0 20px;
    }
    .c--boxWht{
      padding: 20px;
      border-radius: 0;
      > p{
        margin: 15px 0 0;
      }
      .p--cmt__link{
        font-size: remSize(14);
      }
      .p--cmt__btn{
        margin: 22px 0 0;
        .c--btn{
          width: 100%;
        }
      }
      .p--txtCntBox{
        flex-direction: column-reverse;
        .p--txtCntInfo{
          margin: 5px 0 0;
          font-size: remSize(10);
        }
        .c--text__print{
          text-align: right;
        }
      }
    }
  }
  
  .p--show__block{
    li{
      padding-bottom: 18px;
      p{
        padding-left: 0 0 0 43px;
      }
    }
  }
  
  .p--loginBtnBox{
    margin: 20px 0 0;
    padding: 0 20px;
    .c--btn{
      width: 100%;
      + .c--btn{
        margin: 17px 0 0;
      }
    }
  }
  .p--cnst .c--ctrl__btn{
    margin: 22px 0 0;
    .c--btn{
      font-size: remSize(12);
    }
  }
}
@media screen and (max-width: 320px){
  .p--cnst .p--page__ctrl--col2{
    .c--btn{
      padding: 18px 8px;
      font-size: .6rem;
    }
  }
}


/** ========================================
 * search-result.html
======================================== */
.p--hp__search{
  .p--reg__body{
    padding-top: 5px;
  }
  .c--page__ctrl{
    margin: 0;
    &:not(:first-of-type){
      margin-top: 25px;
    }
  }
  .c--allCheckBtn__box{
    text-align: right;
    .c--control{
      margin: 0;
    }
    + .c--page__ctrl{
      margin-top: 20px;
    }
  }
}
.p--hspLink{
  margin: 18px 0 0;
  font-size: remSize(11);
  a{
    display: inline-block;
    margin: 0 20px 5px 0;
  }
  + .p--hspLink{
    margin: 15px 0 0;
  }
  .c--arrow:before{
    top: 5px;
    transform: rotate(-45deg)!important;
  }
}
.p--item__body,
.p--simpleSlider,
.p--tag--intern{
  + .p--tgl__block{
    padding: 0 20px 15px;
  }
}



.p--intern__search{
  display: flex;
  .p--search__wrap{
    width: calc(100% - 360px);
  }
  .p--search__box{
    display: flex;
    justify-content: space-between;
    margin: 15px 40px 0 0;
    input{
      width: 600px;
      height: 60px;
    }
    .c--btn--input{
      width: calc(100% - 610px);
      height: 60px;
    }
  }


  .p--intern__ttl--search{
    margin: 20px 0 0;
  }
  .p--search__tag{
    width: 360px;
    padding: 20px 0 20px 40px;
    border-left: 1px dotted $clr-g40;
    span{
      a{
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px;
        border-radius: 4px;
        background: $clr-g20;
        color: $clr-p80;
        font-size: remSize(12);
        &:hover{
          background: $clr-p80;
          color: $clr-w;
        }
      }
    }
  }
} // p--intern__search

.p--searchBtnBox{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0;
  .p--searchResult{
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    i{
      margin: 0 0 0 5px;
      font-size: 1.5rem;
    }
  }
  .c--btn{
    margin-left: 10px;
    font-size: remSize(20);
    &--lb{
      width: 296px;
      margin-left: 20px;
    }
  }
}

.p--intern__detailCond{
  .p--search_list{
    margin: 27px 0 0;
    + .p--intern__ttl{
      margin-top: 27px;
    }
    span{
      a{
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px;
        border-radius: 4px;
        background: $clr-g20;
        color: $clr-p80;
        font-size: remSize(12);
        &:hover{
          background: $clr-p80;
          color: $clr-w;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--hp__search{
    .p--reg__block--topTab{
      .p--searchArea__tab{
        margin: 0;
        .p--tab{
          height: 49px;
          &:not(.p--tab--active){
            overflow: hidden;
            border: 1px solid #dadadb;
            box-shadow: none;
          }
          &--active{
            position: relative;
            height: 57px;
            border-left: 1px solid #dadadb;
            border-right: 1px solid #dadadb;
            border-radius: 0;
            &:after{
              content: "";
              display: inline-block;
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background: #fff;
            }
          }
        }
      }
      .p--reg__body{
        border-top: 1px solid #dadadb;
      }
    }

    .c--page__ctrl{
      margin-top: 25px;
    }
    .c--allCheckBtn__box{
      padding: 0 20px;
      text-align: left;
    }
  }

  .p--intern__search{
    display: block;
    .p--search__wrap{
      width: 100%;
    }
    .p--search__box{
      margin: 0;
      input{
        width: calc(100% - 105px);
        height: 50px;
      }
      .c--btn--input{
        width: 98px;
        height: 50px;
      }
    }
  
  
    .p--intern__ttl--search{
      margin: 0;
    }
    .p--search__tag{
      width: 100%;
      margin: 15px 0 0;
      padding: 0;
      border: none;
      span{
        a{
          margin: 0 7px 8px 0;
        }
      }
    }
  } // p--intern__search


  .p--intern__detailCond{
    .p--simpleTabHead{
      &.p--simpleTabCols{
        .p--simpleTab__item{
          min-width: auto;
          p{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
          }
          &.p--tabSelect{
            p{
              padding-bottom: 17px;
            }
          }
        }
      }
    }
  }

  .c--control__allCheckBtn{
    margin: 5px 20px;
  }

  .p--hspLink{
    + .p--hspLink{
      margin-top: 10px;
    }
    a{
      display: block;
      margin: 0 0 10px 0;
    }
  }

  .p--searchBtnBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0;
    padding: 0 0 25px;
    .p--searchResult{
      flex-direction: column;
      width: auto;
      font-size: .75rem;
      i{
        font-size: 1.25rem;
      }
    }
    .c--btn{
      padding: 10px 13px;
      font-size: .875rem;
      line-height: 1.3;
      &--lb{
        width: auto;
        margin: 0 0 0 10px;
        padding: 18px;
        font-size: 1rem;
      }
    }
  }

  .p--intern__detailCond{
    .p--search_list{
      margin-top: 20px;
      padding: 0 20px;
      + .p--intern__ttl{
        margin-top: 20px;
      }
      span{
        a{
          margin: 0 7px 8px 0;
        }
      }
    }
  }

}


@media screen and (max-width: 320px){
  .p--searchBtnBox{
    .c--btn{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .75rem;
    }
  }
}



/** --------------------
 * モーダル付きスライダー
 * ------------------ */
.p--simpleSlider{
  padding: 0 20px 30px;
  > div{
    position: relative;
    margin: 0 55px;
  }
  &.p--no__slider{
    .swiper-slide{
      width: calc((100% - 60px) / 4);
      + .swiper-slide{
        margin-left: 20px;
      }
    }
  }

  .swiper-slide{
    img{
      height: 104px;
    }
  }

  /** --------------------
   * コントローラーのラッパー
   * ------------------ */
  .p--simpleSlider__btns {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .c--btns__inner{
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
  
  /** --------------------
   * swiper上書き
   * ------------------ */
  /* ----- 矢印ボタン ----- */
  &.p--simpleSlider .swiper-button-next,
  &.p--simpleSlider .swiper-button-prev {
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 0;
    background: #ff80ab;
    border-radius: 50%;
    outline: none;
    transform: translateY(-50%);
  }
  &.p--simpleSlider .swiper-button-prev,
  &.p--simpleSlider .swiper-container-rtl .swiper-button-next {
    top: 50%;
    left: -55px;
    right: auto;
  }
  
  &.p--simpleSlider .swiper-button-next,
  &.p--simpleSlider .swiper-container-rtl .swiper-button-prev {
    top: 50%;
    right: -55px;
    left: auto;
  }
  
  &.p--simpleSlider .swiper-button-next:after,
  &.p--simpleSlider .swiper-button-prev:after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
  
  }
  &.p--simpleSlider .swiper-button-next:after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: translateX(-1px) rotate(45deg);
  }
  &.p--simpleSlider .swiper-button-prev:after {
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: translateX(1px) rotate(-45deg);
  }

}

@media screen and (max-width: 767px) {
  .p--simpleSlider{
    > div{
      margin: 0;
    }
    &.p--no__slider{
      .swiper-slide{
        width: calc((100vw - 108px) / 3);
        + .swiper-slide{
          margin-left: 5px;
        }
      }
    }

    .swiper-slide{
      img{
        height: calc(((100vw - 108px) / 3) * 0.606);
      }
    }

    /** --------------------
    * swiper上書き
    * ------------------ */
    /* ----- 矢印ボタン ----- */
    &.p--simpleSlider .swiper-button-next,
    &.p--simpleSlider .swiper-button-prev {
      width: 28px;
      height: 28px;
    }

    &.p--simpleSlider .swiper-button-prev,
    &.p--simpleSlider .swiper-container-rtl .swiper-button-next {
      left: -31px;
    }

    &.p--simpleSlider .swiper-button-next,
    &.p--simpleSlider .swiper-container-rtl .swiper-button-prev {
      right: -31px;
    }

  }
}


/** ========================================
 * @
 * モーダル
 * ====================================== */
/** --------------------
 * アイテム
 * ------------------ */
// .p--simpleSlider.p--simpleSlider__modal {
//   display: none;
// }
// .p--simpleSlider.p--simpleSlider__modal.is--visible{
//   display: block;
// }
// .p--simpleSlider.p--simpleSlider__modal.is--visible .c--modal__item{
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   width: 690px;
//   padding: 40px 45px 34px 45px;
//   background: #fff;
//   border-radius: 8px;
//   transform: translate(-50%, -50%);
//   opacity: 1;
//   animation: modanime .2s;
//   z-index: 15;
// }

// .p--simpleSlider.p--simpleSlider__modal .c--modal__item .c--item__body figcaption {
//   margin-top: 10px;
// }

// @keyframes modanime {
//   0%{
//     opacity: 0;
//   }
// }

/** --------------------
 * 閉じるボタン
 * ------------------ */
// .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 40px;
//   height: 40px;
//   background: #333333;
//   border-radius: 50%;
//   cursor: pointer;
//   transform: translate(50%, -50%);
//   z-index: 1;
// }
// .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::before,
// .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::after {
//   content: "";
//   position: absolute;
//   display: inline-block;
//   height: 2px;
//   width: 18px;
//   top: 50%;
//   left: 50%;
//   background: #fff;
//   transform-origin: center;
// }
// .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::before {
//   transform: translate(-50%, -50%) rotate(45deg);
// }
// .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::after {
//   transform: translate(-50%, -50%) rotate(-45deg);
// }


/** --------------------
 * 背景レイヤー
 * ------------------ */
 #modal__screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
  z-index: 10;
}




@media screen and (max-width: 767px) {
  .p--simpleSlider{
    width: 100%;
    padding: 0 34px 15px;
  }
  .p--simpleSlider__btns{
    z-index: 1;
  }
  .p--simpleSlider .swiper-button-prev, .p--simpleSlider .swiper-container-rtl .swiper-button-next{
    left: 11px;
  }
  .p--simpleSlider .swiper-button-next, .p--simpleSlider .swiper-container-rtl .swiper-button-prev{
    right: 11px;
  }

  /** ========================================
   * @
   * モーダル
   * ====================================== */
  /** --------------------
   * アイテム
   * ------------------ */
  // .p--simpleSlider.p--simpleSlider__modal.is--visible .c--modal__item{
  //   width: 80%;
  //   padding: 10px;
  // }
  // #js--modal__slider .p--simpleSlider__btns{
  //   z-index: 0;
  // }

}

@media screen and (max-width: 320px) {
  .p--simpleSlider{
    width: 280px!important;
  }
  .c--modal__trigger > img{
    height: 170px;
  }
  .p--simpleSlider .swiper-button-prev,
  .p--simpleSlider .swiper-container-rtl .swiper-button-next{
    left: 11px;
  }
  .p--simpleSlider .swiper-button-next,
  .p--simpleSlider .swiper-container-rtl .swiper-button-prev{
    right: 11px;
  }
  .p--simpleSlider .swiper-button-next,
  .p--simpleSlider .swiper-button-prev{
    width: 24px;
    height: 24px;
  }
  .c--modal__trigger{
    width: 280px;
    height: 169.7px;
  }

  // .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close{
  //   width: 30px;
  //   height: 30px;
  // }
  // .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::before,
  // .p--simpleSlider.p--simpleSlider__modal .c--modal__item #modal__close::after{
  //   width: 15px;
  // }
}

// .p--simpleSlider.p--simpleSlider__modal .p--simpleSlider__btns{
// 	position: absolute;
// 	width: 100%;
// 	height: 100%;
// 	top: 0;
// 	left: 0;
// }
// .p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-button-prev,
// .p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-container-rtl .swiper-button-next{
// 	top: 50%;
// 	left: 8px;
// 	transform: none;
// }
// .p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-button-next,
// .p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-container-rtl .swiper-button-prev{
// 	top: 50%;
// 	right: 8px;
// 	transform: none;
// }
// @media screen and (max-width: 767px) {
// 	.p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-button-prev,
// 	.p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-container-rtl .swiper-button-next{
// 		left: -35px;
// 	}
// 	.p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-button-next,
// 	.p--simpleSlider.p--simpleSlider__modal.p--simpleSlider .swiper-container-rtl .swiper-button-prev{
// 		right: -35px;
// 	}
// }
