/*! 
 * ボタン
 */



/** ========================================
 * ボタン
 * ====================================== */
/*ボタン基本*/
.c--btn{
  display: inline-block;
  padding: 18px 22px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: $clr-w;
  color:#555;
  text-align: center;
  font-size: remSize(14);
  font-weight: bold;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px 0 rgba(0,0,0,.1);

  // ブロック設定
  &Area{
    &--col3{
      .c--btn{
        + .c--btn{
          margin: 0 0 0 10px;
        }
      }
    }
    &__formBox{
      .c--btn--back{
        min-width: 86px!important;
      }
      .c--btn--next{
        width: 296px!important;
        margin: 0 0 0 10px;
      }
      .c--btn--tempSave{
        width: 296px!important;
        margin: 0 0 0 10px;
      }
    }
  }



  // ボタン設定
  &--input{
    display: inline-block;
    width: calc(100% - 360px);
    height: 45px;
    padding: 14px 20px;
    border: none;
    background: $clr-lb60;
    color: $clr-w;
    font-size: .875rem;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0,0,0,.1);
  }
  &--noTxtShadow{
    text-shadow: none;
  }
  &--w{
    border: 1px solid $clr-g40;
  }
  &--g{
    background: $clr-g70;
    border: 1px solid $clr-g70;
    color: $clr-w;
    &.c--btn--cancel{
      padding: 7px 9px;
      font-size: remSize(10);
      font-weight: normal;
      &ed{
        padding: 7px 9px;
        background: $clr-g40;
        border-color: $clr-g40;
        color: $clr-g70;
        font-size: remSize(10);
        font-weight: normal;
      }
    }
  }
  
  &--pk{
    background: $clr-p60;
    border: 1px solid $clr-p60;
    color: $clr-w;
  }
  &--lb{
    background: $clr-lb60;
    border: 1px solid $clr-lb60;
    color: $clr-w;
  }
  &--lo{
    background: #FFEDDB;
    border: 1px solid #FFEDDB;
    &.c--btn--sml{
      padding: 7px 9px;
      font-size: remSize(10);
      font-weight: normal;
    }
  }
  &--disabled{
    border-color: $clr-g30;
    background: $clr-g30;
    color: $clr-g50;
    pointer-events: none;
    .c--arrow:before{
      border-color: $clr-g50;
    }
    &--dp{
      background: #DBDBDC;
      color: #838387;
    }
  }



  &--xs{
    padding: 7px;
    font-size: remSize(13);
  }
  &--s{
    padding: 6px 20px;
  }
  &--m{
    padding: 11px 9px;
    font-size: remSize(13);
  }
  &--l{
    padding: 25px 39px;
    font-size: remSize(20);
  }
  &--xl{
    padding: 25px 77px;
    font-size: remSize(20);
  }





  // 装飾系ボタン
  &--dlIcon{
    &:after{
      content: "";
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-left: 5px;
      background: url(../images/icon_save_pdf.png) 50% no-repeat;
    }
  }
  &--line{
    padding: 18px 22px 18px 5px;
    background: #00b900;
    color: $clr-w;
    i{
      position: relative;
      display: inline-block;
      padding: 0 0 0 45px;
      &:before{
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        background-size: contain;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: url(../images/icon_line.png) 50%/40px no-repeat;
      }
    }
  }
  &--searchIcon{
    line-height: 16px;
    &:after{
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 5px;
      background: url(../images/icon_search.png) 50% no-repeat;
      vertical-align: top;
    }
  }
  &--fav{
    position: relative;
    display: block;
    width: 70px;
    height: 70px;
    &:before{
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      height: 20px;
      background-size: contain!important;
      background-repeat: no-repeat;
      background-image: url(../images/icon_star_off.png);
    }
    &--active{
      color: $clr-p80;
      &:before{
        background-image: url(../images/icon_star_on.png);
      }
    }
  }
  &--top{
    width: 168px;
  }
  &--login,
  &--pswd{
    width: 300px;
  }

  &--trash{
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 15px;
    &:before{
      content: "";
      display: inline-block;
      width: 14px;
      height: 18px;
      margin-right: 5px;
      background: url(../images/icon_trash_w.png) 50% no-repeat;
      background-size: 14px;
    }
  }

  &--print{
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 15px;
    &:before{
      content: "";
      display: inline-block;
      width: 14px;
      height: 18px;
      margin-right: 5px;
      background: url(../images/icon_print.png) 50% no-repeat;
      background-size: 14px;
    }
  }

}

@media screen and (min-width: 768px){
  // hover時の動き(PCのみ)
  .c--btn{
    // ボタン設定
    &--pk{
      &:hover{
        background: $clr-p80;
        border: 1px solid $clr-p80;
      }
    }
    &--lb{
      &:hover{
        background: $clr-lb80;
        border: 1px solid $clr-lb80;
      }
    }
    &--disabled{
      &:hover{
        cursor: auto;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .c--btn{
    &Area{
      padding-right: 20px;
      padding-left: 20px;
      &--col3{
        .c--btn{
          + .c--btn{
            margin: 10px 0 0 0;
          }
        }
      }
      &__formBox{
        .c--btn--back{
          min-width: 96px!important;
          width: 96px!important;
          &--top{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 52px;
            padding: 0;
            font-size: remSize(12);
            line-height: 1.3;
          }
        }
        .c--btn--next{
          min-width: calc(100% - 110px)!important;
          width: calc(100% - 110px)!important;
          margin: 0 0 0 10px;
        }
        .c--btn--tempSave{
          min-width: calc(100% - 110px)!important;
          width: calc(100% - 110px)!important;
          margin: 0 0 0 10px;
        }
      }

    }

    &--fav{
      width: 56px;
      height: 56px;
    }

    &--top,
    &--login,
    &--pswd{
      width: 100%;
    }
    &--xl{
      font-size: remSize(16);
      padding: 20px 34px;
    }
  }
}


@media screen and (max-width: 320px){
  .c--btn{
    padding: 18px 15px;
    font-size: .7rem;
  }
}
