/*! 
 * メッセージページ関係
 */

/** ========================================
 * @COMN-001.html
 * メッセージ一覧
 ======================================== */
.p--msgBtn__box{
  display: flex;
  align-items: flex-start;
  .p--msgBtn__item{
    width: calc((100% - 20px) / 3);
    &:not(:first-of-type){
      margin-left: 10px;
    }
  }
  .p--msgBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 17px 0;
    border-radius: 4px;
    background: $clr-w;
    font-size: remSize(13);
    box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
    font-size: remSize(16);
    font-weight: bold;
    i{
      display: inline-block;
      margin: 0 0 0 8px;
      padding: 2px 6px;
      border-radius: 100px;
      background: $clr-red;
      color: $clr-w;
      font-size: remSize(10);
      font-weight: bold;
    }
    &--active{
      pointer-events: none;
      &.p--msgBtn{
        padding-top: 13px;
        border-top: 4px solid #e92d82;
        color: #e92d82;
      }
      i{
        background: $clr-w;
        color: $clr-p80;
      }
    }
  }

  // 下に検索エリアがある場合
  &--with__searchBox{
    .p--msgBtn{
      &--active{
        &.p--msgBtn{
          position: relative;
          margin-bottom: 0;
          padding: 13px 0 27px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          &::after{
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 10px;
            background: #fff;
          }
        }
      }
    }
  }

}
.c--page__ctrl{
  margin: 25px 0 0;
}
.p--msgSearch{
  padding: 40px 36px 30px;
  &--inbox{
    border-top-left-radius: 0;
  }
  &__ttl{
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;
    &:before{
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 7px 0 0;
      background: url(../images/icon_circle_search.png) 100%/contain no-repeat;
    }
  }
  &__box{
    display: flex;
    align-items: center;
    margin: 15px 0 0;
    input{
      width: calc(100% - 82px);
      margin: 0 10px 0 0;
      height: 55px;
    }
    .c--btn--input{
      width: 82px;
      height: 55px;
    }
  }
  &__state{
    display: flex;
    align-items: center;
    margin: 20px 0 0;
    .p--stateList{
      display: flex;
      align-items: center;
      margin: 0 0 0 5px;
      &__item{
        padding: 0 20px 0 0;
        &:not(:first-child){
          padding-left: 20px;
          border-left: 1px solid $clr-g40;
        }
        a{
          color: $clr-p80;
        }
        &--active{
          a{
            pointer-events: none;
            color: $clr-b;
            font-weight: bold;
          }
        }
      }
      + p{
        margin: 0 0 0 40px;
      }
    }
  }

  &__links{
    display: flex;
    align-items: center;
    .p--link__item{
      position: relative;
      width: calc(50% - 15px);
      padding: 15px;
      border: 1px solid $clr-g40;
      border-radius: 5px;
      color: $clr-p80;
      font-size: remSize(20);
      font-weight: bold;
      text-align: center;
      &::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 23px;
        transform: translateY(-50%);
        display: inline-block;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat!important;
        background-size: contain!important;
      }
      &--msg{
        &::before{
          background: url(../images/icon_msg.png);
        }
      }
      &--scout{
        margin-left: 30px;
        &::before{
          background: url(../images/icon_scout.png);
        }
      }

      &--active{
        pointer-events: none;
        background: $clr-p80;
        color: #fff;
        &.p--link__item--msg{
          &::before{
            background: url(../images/icon_msg-w.png);
            height: 21px;
          }
        }
        &.p--link__item--scout{
          &::before{
            background: url(../images/icon_scout-w.png);
          }
        }

      }

    }
  }

}

.p--msgInbox__heading{
  margin: 20px 0 0;
}
.p--msgInbox__body{
  margin: 30px 0 0;
  &--pageCtrl{
    margin: 0;
    .p--msgInboxTbl{
      margin: 30px 0 0;
    }
  }
  .p--msgInboxTbl{
    border-radius: 4px;
    &__head,
    &__body{
      display: flex;
    }
    &__head{
      background: #818187;
      border-radius: 4px 4px 0 0;
      .p--msgInboxTbl__item{
        padding: 0;
        .p--sortTgl{
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 10px 13px 10px 16px;
          color: $clr-w;
          &:hover{
            cursor: pointer;
          }

          &--up,
          &--down{
            &:after{
              content: "";
              position: absolute;
              display: inline-block;
              right: 13px;
              width: 7px;
              height: 7px;
              border: solid #fff;
              border-width: 0 2px 2px 0;
            }
          }
          &--up{
            &:after{
              top: 50%;
              transform: translateY(-50%) rotate(-135deg);
            }
          }
          &--down{
            &:after{
              top: 48%;
              transform: translateY(-50%) rotate(45deg);
            }
          }
        }
      }
    }
    &__body{
      border-top: 1px solid $clr-g40;
      font-weight: bold;
      &--hosp{
        background: #FFFCF0;
      }
      &--open{
        background: #FAFAFA;
        font-weight: normal;
      }
      .p--msgInboxTbl__item{
        &--subject{
          width: calc(100% - 418px);
          a{
            color: $clr-p80;
          }
        }
      }
    }

    &--check{
      .p--msgInboxTbl__item{
        &--subject{
          width: calc(100% - 418px);
        }
      }
    }
    &--noCheck{
      .p--msgInboxTbl__item{
        &--subject{
          width: calc(100% - 373px);
        }
      }
    }

    &__item{
      display: flex;
      align-items: center;
      min-height: 46px;
      padding: 0 15px;
      &--check{
        width: 45px;
        padding: 0 14px;
      }
      &--name{
        width: 203px;
      }
      &--date{
        width: 170px;
      }
      &:not(:first-child){
        border-left: 1px solid $clr-g40
      }

      .p--item{
        position: relative;
        padding: 0 0 0 29px;
        &:before,
        &:after{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: inline-block;
          width: 22px;
          height: 24px;
          background-size: contain!important;
        }
        &:before{
          left: 0;
        }
        &:after{
          left: 27px;
        }
        &--mail:before{
          background: url(../images/icon_inbox_mail.png) 50% no-repeat;
        }
        &--entry:before{
          background: url(../images/icon_inbox_entry.png) 50% no-repeat;
        }
        &--entry__read:before{
          background: url(../images/icon_inbox_entry_read.png) 50% no-repeat;
        }
        &--entry__reply:before{
          background: url(../images/icon_inbox_entry_reply.png) 50% no-repeat;
        }
        &--thanks:before{
          background: url(../images/icon_inbox_thanks.png) 50% no-repeat;
        }
        &--thanks__read:before{
          background: url(../images/icon_inbox_thanks_read.png) 50% no-repeat;
        }
        &--send:before{
          background: url(../images/icon_inbox_send.png) 50% no-repeat;
        }
        &--read:before{
          background: url(../images/icon_inbox_read.png) 50% no-repeat;
        }
        &--attachment{
          padding: 0 0 0 55px;
          &:after{
            background: url(../images/icon_img_attachment.png) 50% no-repeat;
          }
        }
      }
    }

  } //.p--msgInboxTbl

  .p--noMsgBox{
    margin: 40px 0;
  }

  .p--msgInboxInfo{
    margin: 38px 0 0;
    &__txt{
      margin: 10px 0 0;
    }
  } //.p--msgInboxInfo
  
}

@media screen and (max-width: 767px){

  .p--msgBtn__box{
    padding: 0 10px;
    .p--msgBtn__item{
      width: calc(100% / 3);
      .p--msgBtn{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 14px 4px;
        font-size: remSize(14);
        &--active{
          &.p--msgBtn{
            padding: 10px 4px 14px;
          }
        }
      }
    }

    // 下に検索エリアがある場合
    &--with__searchBox{

      .p--msgBtn{
				&__item{
					.p--msgBtn{
						padding: 10px 4px;
					}
				}
        &--active{
          &.p--msgBtn{
            padding-bottom: 25px;
          }
        }
      }
    }

  }

  .p--msgSearch{
    padding: 20px 0 10px;
    &__ttl{
      padding: 0 20px;
    }
    &__box{
      justify-content: space-between;
      padding: 0 20px;
      input{
        width: calc(100% - 85px);
        height: 48px;
      }
      .c--btn--input{
        width: 75px;
        height: 48px;
      }
    }

    &__links{
      padding: 0 20px;
      .p--link__item{
        width: calc(50% - 5px);
        padding: 13px 0 13px 48px;
        font-size: 16px;
        &--scout{
          margin-left: 10px;
        }
      }
    }


  }
  .p--msgSearch__state{
    display: block;
    > p{
      padding: 2px 20px;
      background: #F1F6FA;
      font-size: remSize(13);
      font-weight: bold;
    }
    .p--stateList{
      margin: 13px 0;
      padding: 0 20px;
      + p{
        margin: 0;
      }
    }

  }
  .p--msgInbox__heading{
    padding: 0 20px;
  }
  .p--msgInbox__select{
    margin: 20px 0 0;
    padding: 0 20px;
  }
  .p--msgInbox__body{
    margin: 20px 0 0;
    &--pageCtrl{
      .p--msgInboxTbl{
        margin: 20px 0 0;
      }
    }
    .p--msgInboxTbl{
      border-radius: 0;
      &__body{
        flex-wrap: wrap;
        padding: 16px 20px;
      }
      &__item{
        min-height: auto;
        padding: 0;
        &--check{
          width: 35px;
        }
        &--name{
          width: calc(100% - 35px);
        }
        &--subject{
          margin: 8px 0 3px;
        }
        &--subject,
        &--date{
          width: 100%!important;
        }
        &:not(:first-child){
          border: none;
        }
      }
    }
    .p--noMsgBox{
      margin: 30px 0 50px;
      padding: 0 20px;
    }
    .p--msgInboxInfo{
      margin: 30px 0 0;
      padding: 0 20px 30px;
    }
  }

}





/** ========================================
 * @RCVE-002.html
 * メッセージ表示
 ======================================== */
.p--msg{
  &Disp{
    padding: 25px;
  }
  .c--btnArea__formBox{
    margin: 30px 0 0;
  }
  &__formBox{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    .c--btnArea--right{
      margin: 0 0 0 90px;
    }
    &--printBtn{
      .p--btnArea__main{
        flex-grow: 1;
        text-align: center;
      }
      .c--btnArea--right{
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
  }
}



@media screen and (max-width: 767px){
  .p--msg{
    &Disp{
      padding: 0;
    }
    .c--btnArea__formBox{
      margin: 20px 0 0;
      padding-bottom: 30px;
    }
    &__formBox{
      .c--btn--w{
        padding: 18px 10px;
      }
    }
  
  }
  .c--btnArea__formBox.c--sp__only{
    display: flex!important;
    justify-content: flex-end;
    .c--btn--trash{
      margin: 20px 0 0;
    }
  }
}






/** ========================================
 * @CRAT-003.html
 * メッセージ送信完了
 ======================================== */
.p--msgInboxFinish{
  margin: 45px 0 0;
  &__ttl{
    font-size: 1rem;
  }
  &__txt{
    margin: 10px 0 0;
  }
  + .p--msgInboxInfo{
    margin: 86px 0 0;
  }
}

@media screen and (max-width: 767px){
  .p--msgInboxFinish{
    margin: 28px 0 0;
    padding: 0 20px;
    &__txt{
      margin: 3px 0 0;
    }
    + .p--msgInboxInfo{
      margin: 45px 0 0;
    }
  }
}


/** ========================================
 * 画像
 ======================================== */
.p--msgInbox__body{
  .p--msgImg{
    width: 100%;
    max-width: 644px;
    margin: 0 auto;
    img{
      height: 200px;
    }
  }
}

@media screen and (max-width: 767px){
  .p--msgInbox__body{
    .p--msgImg{
      max-width: 335px;
      img{
        height: calc((100vw - 40px) / 33.5 * 10);
        max-height: 100px;
      }
    }
  }
}