/*! 
 * 病院比較
 */
/** ========================================
 * @p--comparison__ctrl
 * ====================================== */
.p--compCheckBlock{
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);

  .p--compCheck__list{
    border-radius: 0 0 4px 4px;
    background: $clr-w;
    .c--control{
      margin: 0;
      padding: 15px 20px 15px 45px;
      width: 100%;
      &__indicator{
        left: 23px;
      }
    }
    .c--list__item{
      label:hover{
        color: $clr-p80;
      }
      &:not(:first-child){
        border-top: 1px solid $clr-g40;
      }
      .c--hoverShadow__item{
        background: none;
      }
    }
  }
  .c--control--disabled.c--hoverShadow__item:hover{
    box-shadow: none;
  }
}

.p--btm__selectArea{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0;
  .p--select__txt{
    display: flex;
    align-items: center;
    font-size: remSize(16);
    font-weight: bold;
    i{
      margin: 0 0 0 5px;
      font-size: remSize(24);
    }
  }
  .c--btn{
    margin: 0 0 0 10px;
    font-size: remSize(16);
  }
  .c--btn--lb{
    margin: 0 0 0 20px;
  }
  .c--color--w{
    padding: 18px 31px;
  }

}


@media screen and (max-width: 767px){
  .p--btm__selectArea{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 25px 10px;
    background: rgba(97, 97, 106, .9);
    z-index: 1010;
    .p--select__txt{
      flex-direction: column;
      color: $clr-w;
      text-align: center;
      font-size: remSize(12);
      font-weight: normal;
      line-height: 1.3;
    }
    .c--btn{
      margin: 0 0 0 8px;
      padding: 6px 10px;
      font-size: remSize(14);
      line-height: 1.5;
    }
    .c--btn--lb{
      margin: 0 0 0 15px;
    }
    .c--btn--w{
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: 6px 7px;
    }
  }
}
@media screen and (max-width: 320px){
  .p--btm__selectArea{
    .c--btn{
      font-size: remSize(12);
    }
    .c--btn--lb{
      margin: 0 0 0 5px;
    }
  }
}


.p--comparison {
/** ========================================
 * @p--comparison__ctrl
 * ====================================== */
  &__ctrl {
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding: 16px 24px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 2px rgba(0,0,0, .1), 0 -2px 2px rgba(0,0,0, .02);
    &--bottom {
      margin-top: 40px;
      margin-bottom: 120px;
    }
  }
  .p--ctrl_msg {
    .p--msg_total,
    .p--msg_view {
      display: inline-block;
    }
    .p--msg_view {
      color: #e92d82;
    }
    .l--numFont {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }
  .p--ctrl_btns {
    flex: 1 1 25%;
    text-align: right;
    .c--btn {
      padding: 12px 8px;
    }
    .c--btn + .c--btn {
      margin-right: 8px;
    }
  }
  &__text{
    display: none;
  }
}



 /** ========================================
 * @p--comparisonTable
 * ====================================== */
table.p--comparisonTable,
table.p--comparisonTable * {
  font-size: .8125rem;
}
.p--comparisonTable {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  border-collapse: collapse;
  table-layout: fixed;
  th, 
  td {
    height: 100%;
    border-bottom: 1px solid #dadadb;
    &:not(:last-child) {
      border-right: 8px solid #fff7f6;
    }
    &:first-child {
      border-right: 4px solid #fff7f6;
    }
  }
  td {
    background: #fff;
  }
  /* -------- PC左側の見出し（TFOOT部分は上書き修正） -------- */
  tr th:first-child {
    width: 114px;
  }

  /* -------- 列の数毎の幅調整 -------- */
  /*ipadで悪さをするので一旦コメントアウトします。いらないはず。*/
/*  &--2item {
    th,
    td {
      width: calc((100% - 114px) / 2);
    }
  }
  &--3item {
    th,
    td {
      width: calc((100% - 114px) / 3);
    }
  }
  &--4item {
    th,
    td {
      width: calc((100% - 114px) / 4);
    }
  }
  &--5item {
    th,
    td {
      width: calc((100% - 114px) / 5);
    }
  }

*/

  /** ------------------------------
   * THEAD
   * 病院名の見出し部分
   * ---------------------------- */
  thead {
    th {
      text-align: left;
      line-height: 1;
      vertical-align: top;
      &:not(:first-child) {
        background: #828288;
        border-bottom: none;
        a {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 18px 12px;
          padding-left: calc(1em + 12px + 6px);
          color: #fff;
          line-height: 1.4;
          &:hover {
            text-decoration: underline;
          }
          &::before {
            content: "";
            position: absolute;
            display: inline-block;
            top: 52%;
            left: 12px;
            width: 1em;
            height: 1em;
            background: #fff;
            border-radius: 50%;
            transform: translateY(-50%);
          }
          &::after {
            content: "";
            position: absolute;
            display: inline-block;
            top: 52%;
            left: 12px;
            width: 6px;
            height: 6px;
            border-top: 2px solid #828288;
            border-right: 2px solid #828288;
            transform: translate(50%,-50%) rotate(45deg);
          }
        }
        p {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 18px 12px;
          color: #fff;
          line-height: 1.4;
        }
      }
    }
  }

  /** ------------------------------
   * TBODY
   * 内容部分
   * ---------------------------- */
  tbody {
    tr {
      &:hover {
        background-color: #f1f6fa;
        th, td {
          background-color: #f1f6fa;
        }
      }
      &:last-child {
        th, 
        td {
          border-bottom: none;
        }
      }
    }
    th,
    td {
      vertical-align: top;
    }
    th {
      padding: 20px 14px 20px 0;
      text-align: right;
    }
    .p--comparisonTable__txtBox{
      position: relative;
    }
  }

  /** ------------------------------
  * TFOOT
  * ---------------------------- */
  tfoot {
    /* -------- 見出し -------- */
    tr:first-child th {
      width: 100%;
      background: #f0f0f0;
      div {
        padding: 10px;
        border-top: 1px solid #dadadb;
      }
    }
    tr:nth-child(n+2) {
      th:first-child {
          padding: 20px 14px 20px 0;
          text-align: right;
          vertical-align: top;
      }
    }
    td {
      // padding: 20px 14px;
    }
  }


  /** ------------------------------
  * パーツ
  * ---------------------------- */
  /* -------- コンテンツのラッパー -------- */
  &__body {
    position: relative;
    padding: 20px 14px;
    .p--comparisonTable__inner {
      overflow: hidden;
      // height: 400px;
    }
    &.is--open {
      .p--comparisonTable__inner {
        height: auto !important;
      }
    }

    /* -------- モバイル用の見出し -------- */
    li {
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.6;
      &:not(:first-child) {
        margin-top: 20px;
      }
      &::before {
        content: "・";
        color: #bebec1;
      }
    }

    p {
      + em {
        margin-top: 36px;
      }
      + p {
        margin-top: 36px;
      }
      + ul {
        margin-top: 36px;
      }
    }
    em {
      display: inline-block;
      + p {
        margin-top: 20px;
      }
    }


    /* -------- 薄灰色の文章 -------- */
    .p--point {
      display: block;
      margin-top: 18px;
      color: #bebec1;
      padding-left: 1em;
      text-indent: -1em;
    }

  }/* end &__body */

  /* -------- トグルする部分のラッパー -------- */
  &__toggle {
    .p--comparisonTable__body {
      &:not(.is--open){
        padding-bottom: 0;
      }
      &.p--comparisonTable__body--fold::after {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0) 60%, rgba(255,255,255,0), rgba(255,255,255,1) 100%);
      }
      &.is--open,
      &.is--short {
        &::after {
          display: none;
        }
      }
    }
  }/* end &__toggle */


  /* トグルボタン */
  .p--toggle__btn {
    display: inline-block;
    cursor: pointer;
    padding: 5px 0;
    &::after {
      content: "";
      display: inline-block;
      width: .5em;
      height: .5em;
      margin-left: 5px;
      border-bottom: 2px solid #e92d82;
      border-right: 2px solid #e92d82;
      transform: translateY(-50%) rotate(45deg);
    }
    &.is--active::after{
      transform: translateY(0) rotate(-135deg);
    }
    &--floating {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  th .p--toggle__btn {
    padding: 10px 6px;
    background: #fff;
    border: 1px solid #dadadb;
    border-radius: 6px;
    color: #e92d82;
    line-height: 1;
  }

  .p--comparisonTable__body.is--short + .p--toggle__btn::after {
    display: none;
  }



  /* -------- モバイル用の見出し -------- */
  &__title--sp {
    display: none;
    padding: 8px 6px;
    background: #f1f6fa;
  }

  /* -------- 受付部分で使ったリンクボタン -------- */
  &_rcvBtn {
    position: relative;
    display: inline-block;
    padding-left: calc(1em + 6px);
    &::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 1em;
      height: 1em;
      background: #ff80ab;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: translate(50%, -50%) rotate(45deg);
    }
  }

}


@media screen and (max-width: 767px){
  .p--comparison {
    &__ctrl{
      display: block;
      margin-top:30px;
    }
    .p--ctrl_btns ,.p--ctrl_msg{
      text-align:center;
    }
    .p--ctrl_msg{
      margin-bottom:10px;
    }
    &__text{
      display: block;
      text-align:center;
      margin:30px auto 0;
      span{
        padding: 0 20px 0 0;
        position: relative;
        display:inline-block;
        &:before{
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 7px;
          height: 7px;
          border: solid $clr-b;
          border-width: 0 2px 2px 0;
          z-index: 10;
          transform: translateY(-50%) rotate(-45deg);
        }
        &:after{
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          width: 7px;
          height: 7px;
          border: solid $clr-b;
          border-width: 0 2px 2px 0;
          z-index: 10;
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
  .p--comparisonTable__scroll{
    overflow-x: scroll;
    padding:0 10px;
  }
  .p--comparisonTable {
    tr{
      /* -------- 最初の空白のTH削除 -------- */
      th{
        &:first-child{
          display: none;
        }
        &:last-child{
          border-right:8px solid #fff7f6;
        }
      }
      td{
        &:last-child{
          border-right:8px solid #fff7f6;
        }
      }
    }
    /** ------------------------------
     * TBODY
     * 内容部分
     * ---------------------------- */
    tbody {
      tr {
        &:hover {
          background-color: #fff;
          th, td {
            background-color: #fff;
          }
        }
      }
      /* -------- 左側のTH削除 -------- */
      th{
        display: none;
      }
    }

    tfoot{
      /* -------- 最初の文字表示 -------- */
      tr:first-child{
        th{
          display: table-cell;
        }
      }
    }


    /* -------- モバイル用の見出し復活 -------- */
    &__title--sp {
      display: block;
    }
    /* -------- 5病院の場合 -------- */
    &--5item{
      width:calc(100vw / 3 * 5);
      th{
        width:calc(100vw / 3);
      }
    }
    /* -------- 4病院の場合 -------- */
    &--4item{
      width:calc(100vw / 3 * 4);
      th{
        width:calc(100vw / 3);
      }
    }
    /* -------- 3病院の場合 -------- */
    &--3item{
      th{
        width:calc((100vw - 20px ) / 3);
        &:last-child{
          border-right:none !important;
        }
      }
      td{
        &:last-child{
          border-right:none !important;
        }
      }
    }
    /* -------- 2病院の場合 -------- */
    &--2item{
      th{
        width:calc((100vw - 20px ) / 2);
        &:last-child{
          border-right:none !important;
        }
      }
      td{
        &:last-child{
          border-right:none !important;
        }
      }
    }

    .p--toggle__btn{
      // padding: 0 14px 0 0;
      // position: relative;
      &::before{
        content: "";
        display: inline-block;
        // position: absolute;
        // top: 46%;
        width: 7px;
        height: 7px;
        border: solid $clr-p80;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateY(-50%) rotate(45deg);
        // right: 0;
      }
      &.is--active{
        &::before{
          transform: translateY(0) rotate(-135deg);
        }
      }
      &::after {
        display: none;
      }
    }

  }

}



@media screen and (max-width: 320px){
  .p--comparison{
    .p--ctrl_btns{
      .c--btn{
        width: 150px;
        + .c--btn{
          margin: 10px 0 0;
        }
      }
    }
  }
}