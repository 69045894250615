/*! 
 * WEB履歴書
 */

/** ========================================
 * @p--resu
 * WEB履歴書
 ======================================== */

/** ====================
 * STUD-RESU-DETL-001
==================== */
.p--resu{
  .p--topBdBox{
    margin: 40px auto 0;
    width: 896px;
  }
  .c--boxlp{
    padding: 9px 16px;
  }
  .p--topBdBox__heading{
    padding: 12px 32px;
  }
  .p--contBox{
    padding: 28px 25px;
  }
  .c--primaryTable--clr__gy{
    margin: 20px 0 0;
    th{
      border-top: none;
    }
    th:not(:first-of-type),
    td:not(:first-of-type){
      border-left: 1px solid #dadadb;
    }
    .c--btn{
      + .c--btn{
        margin-top: 10px;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--resu{
    .p--topBdBox{
      width: 100%;
      margin-top: 30px;
    }
    .p--contBox{
      padding: 20px;
    }
    .p--table__wrap{
      margin-top: 20px;
      padding-bottom: 15px;
      border-top: 1px solid #F0F0F0;
      border-bottom: 1px solid #F0F0F0;
    }
    .c--primaryTable{
      width: calc(100% - 40px)!important;
      margin: 0 20px;
      td{
        padding-right: 0;
        padding-left: 0;
      }
    }
    .c--primaryTable--clr__gy{
      margin-top: 15px;
      th{
        padding: 0;
        background: transparent;
        &:not(:first-of-type){
          border-left: none;
        }
      }
      td{
        padding: 0 0 5px;
        &:not(:first-of-type){
          border-left: none;
        }
      }
      .c--btn{
        margin-top: 10px;
      }
    }
  }
}



/** ====================
 * STUD-RESU-INFO-001
==================== */
.p--resu{
  .p--topBdBox{
    .p--form{
      width: 100%;
      margin: 0;
      &__main{
        padding: 28px 0 15px;
      }
      &__table{
        margin: 35px 0 0;
      }
      &__infoTxt--flex{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  .p--form{
    .resume_photo{
      position: relative;
      width: 120px;
      height: 160px;
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        width: auto;
        height: auto;
        max-width: 120px;
        max-height: 160px;
      }
    }
  }
  .p--resu--info{
    .p--historyTable{
      .p--history{
        &__item{
          display: inline-block;
          &--date{
            width: 100px;
          }
          &--txt{
            width: calc(100% - 100px);
          }
        }
      }
    }
  }
}




@media screen and (max-width: 767px){
  .p--resu--info{
    .p--contBox{
      padding: 20px 0;
    }
    .c--boxlp{
      margin: 0 20px;
    }
  }
  .p--resu{
    .p--topBdBox{
      .p--form{
        &__main{
          padding: 13px 0 15px;
        }
        &__table{
          margin-top: 23px;
        }
        &__infoTxt--flex{
          .c--btn{
            margin-top: 10px;
          }
        }
      }
    }
    .p--form{
      .c--tableHeading{
        font-size: remSize(12);
        &:not(.c--pc__only)+ .c--primaryTable{
          margin-top: 17px;
        }
      }
      .resume_photo{
        margin: 0 auto;
      }
    }
  }
}


/** ====================
 * STUD-RESU-INPT-001
==================== */
.p--resu--inpt{
  .p--form__table{
    margin-top: 35px;
    .c--tableHeading{
      + .c--control{
        margin: 17px 0;
      }
    }
    .c--boxlb{
      padding: 7px 10px;
      font-size: 1rem;
      + .c--control{
        margin: 17px 0;
      }
    }
    + .p--form__table{
      margin-top: 55px;
    }
  }
  .p--historyTable{
    .p--history{
      .p--tbl__txt{
        margin: 0 10px;
      }
    }
  }
  .p--qualificationTable{
    .p--qualification{
      .p--tbl__txt{
        margin: 0 10px;
      }
      + .c--text__cntBlock{
        margin-top: 10px;
      }
    }
  }
  .c--control{
    min-width: 85px;
  }
}
.p--resu{
  .c--selFile{
    display: none;
    &__label{
      display: inline-block;
      font-size: remSize(13);
      letter-spacing: 0;
      cursor: pointer;
    }
    &__select{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__name{
      display: inline-block;
      padding: 4px 23px 4px 4px;
      font-size: remSize(13);
      &.error{
        background-color: #fcdcdb!important;
      }
      &Box{
        display: flex;
        align-items: center;
        .c--selFile__name{
          padding-right: 8px;
        }
        .c--selFile__delete{
          width: 19px;
        }
      }
    }
    &__box{
      display: flex;
      align-items: flex-end;
      &.p--state__hide{
        display: none;
      }
      .p--preview__img{
        width: 120px;
        height: 160px;
        position: relative;
        border: 1px solid #ddd;
        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          width: auto;
          height: auto;
          max-width: 120px;
          max-height: 160px;
          &.vertical{
            max-width: 160px;
            max-height: 120px;
          }
        }
      }
    }

    &__wrap{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .c--selFile__box{
        width: 100%;
      }
      &.is--upload{
        // ×ボタンが表示されるタイミングで幅を変更
        .c--selFile__name{
          width: calc(100% - 19px);
        }
      }
    }
    &__delete{
      display: inline-block;
      width: 19px;
      height: 19px;
      margin: 4px 0;
      &:hover{
        cursor: pointer;
      }
    }
    &__note{
      margin: 10px 0;
      padding: 10px;
      background: #fdf3ed;
      label{
        margin: 10px 0 0;
        + ul{
          margin: 10px 0 0;
        }
      }
      .c--control--checkbox{
        color: #E92C7F;
      }
    }

  }
}

.c--rorate__btn{
  position: relative;
  display: inline-block;
  margin: 0 0 0 10px;
  padding: 11px 9px 11px 31px;
  &:before{
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 11px;
    width: 18px;
    height: 19px;
    background-size: contain;
    transform: translateY(-50%);
    background: url(../images/icon_rotation.png) 50%/16px no-repeat;
  }
}


.p--tempSave__popup{
  display: none;
  transform: translate3d(-50%, -50%, 0);
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 20px 10px;
  border-radius: 5px;
  background-color: #666;
  box-shadow: 1px 0 20px rgba(97,97,106,.2), 4px 0 8px rgba(97,97,106,.2);
  color: #fff;
  text-align: center;
  z-index: 1000;
}

.p--note__list{
  margin: 10px 0 0;
  font-size: remSize(12);
  .p--list__item{
    margin-left: 1em;
    text-indent: -1em;
  }
  .p--note__procedure{
    margin-left: 1.1em;
  }
}


.c--colBlock{
  display: flex;
  align-items: flex-end;
  &__radios{
    width: calc(100% - 90px);
  }
  &__btn{
    width: 85px;
    margin-left: 5px;
  }
}


@media screen and (max-width: 767px){
  .p--resu--inpt{
    .p--form__table{
      margin-top: 15px;
      .c--tableHeading{
        + .c--control{
          width: calc(100% - 44px);
          margin-right: 22px;
          margin-left: 22px;
        }
      }
      .c--boxlb{
        + .c--control{
          width: calc(100% - 44px);
          margin-right: 22px;
          margin-left: 22px;
        }
      }
      .c--primaryTable{
        + .p--form__txt--atten{
          padding: 22px 22px 0;
          border-top: 1px solid #dadadb;
        }
      }
      + .p--form__table{
        margin-top: 35px;
      }
      .p--form__edit{
        margin: 0 20px 20px;
      }
      .c--tableHeading{
        font-size: remSize(13);
        + .p--form__table{
          margin-top: 0;
        }
      }
      .p--qualificationTable{
        .p--qualification{
          .u--flexBlock{
            + .u--flexBlock{
              margin-top: 5px;
            }
          }
          + .c--text__cntBlock{
            margin-top: 5px;
          }
        }
      }
      .u--flexBlock.p--history{
        .c--text__cntBlock{
          margin-top: 5px;
        }
      }
      .c--btnArea--col3{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .c--btn{
          &:nth-child(2){
            margin: 0 0 0 13px;
          }
          &:nth-child(3){
            min-width: 100%!important;
            width: 100%!important;
            margin-top: 10px;
          }
        }
      }
    }
    .p--historyTable{
      .p--history{
        flex-wrap: wrap;
        > input{
          margin-top: 10px;
        }
      }
    }
    .p--form__table--info{
      margin-top: 0;
      .c--primaryTable th{
        width: 100%!important;
        margin: 0;
      }
    }
  }
  .p--tempSave__popup{
    width: 90%;
  }
}


/** ====================
 * STUD-RESU-INPT-002
==================== */
.p--historyTable{
  .p--history__item{
    &--date{
      width: 80px;
    }
    &--txt{
      width: calc(100% - 80px);
    }
  }
}
@media screen and (max-width: 767px){
  .p--resu--conf{
    .p--fixed__bnr{
      .c--btn--lb{
        width: 92%;
      }
    }
  }
}


/** ====================
 * STUD-RESU-INPT-003
==================== */
.p--resu--finish{
  .c--btnArea{
    .c--btn{
      width: 236px;
      + .c--btn{
        margin: 0 0 0 10px;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .p--resu--finish{
    .c--btnArea{
      .c--btn{
        width: 100%;
        + .c--btn{
          margin: 10px 0 0;
        }
      }
    }
  }
}
