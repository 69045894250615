/*! 
 * 細かな動作
 */

/** ========================================
 * セルが開くTable
 * ====================================== */
.c--accordionTable {
  tr {
    overflow: hidden;
  }
  th,
  td {
    overflow: hidden;
  }
  &__head{
  span{
    position: relative;
    display:block;
      &::after{
      content:"";
      position: absolute;
      top: 47%;
      right: 10px;
      width: 6px;
      height: 6px;
      border: solid $clr-b;
      border-width: 0 2px 2px 0;
      -webkit-transform: translateY(-50%) rotate(45deg);
      -ms-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &.is-close{
    span::after{
    -webkit-transform: translateY(-50%) rotate(-135deg);
    -ms-transform: translateY(-50%) rotate(-135deg);
    transform: translateY(-50%) rotate(-135deg);
    }
  }
  }
  &__row {
    td {
      transition: all .5s ease-out;
      height: auto;
      line-height: 1.6;
    }
    &.is-close {
      td {
          height: 0;
          padding: 0;
          line-height: 0;
      }
    }
  }
}