/** ============================================================
 * File        : Foundation/_define.scss
 * DESCRIPTION : 定数・基本情報
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */


/** ========================================
 * 定数
 * ====================================== */

/** --------------------
 * カラー
 * ------------------ */
// 通常テキスト
$clr-b: #333;
$clr-w: #fff;

// 重要
$clr-red: #f84361;

// ピンク
$clr-p10: #fff7f6; //背景色
$clr-p20: #fff0f5; //背景色
$clr-p30: #ffe4ed; //背景色
$clr-p40: #ffd6e4;
$clr-p50: #ffb2cc;
$clr-p60: #ff80ab; //強調ボタン色 装飾
$clr-p80: #e92d82; //カレント 装飾

// ライトブルー
$clr-lb10: #f1f6fa;
$clr-lb60: #40c4ff; //CVボタン色
$clr-lb80: #00b0ff; //装飾

// グレー
$clr-g10: #fafafa; //背景色
$clr-g20: #f5f5f5; //背景色
$clr-g30: #f0f0f0; //セクション背景色
$clr-g40: #dadadb; //ボーダー色 非活性ボタン
$clr-g50: #bebec1;
$clr-g60: #a2a2a7;
$clr-g70: #828288; //非活性テキスト サブテキスト
$clr-g80: #61616a;

// イエロー
$clr-y20: #fff79d;
$clr-y50: #ffcc00; //お気に入りボタン
$clr-y80: #d3a900; //アクセントテキスト


/** --------------------
 * フォントサイズ
 * ------------------ */
$base-fontSize: 14;

/** --------------------
 * 幅
 * ------------------ */
$inner_width: 1024px;


/** ========================================
 * MIXIN
 * ====================================== */
/** --------------------
 * 横幅有りのブロック
 * ------------------ */
@mixin width_block {
  width: $inner_width;
  margin: 0 auto;
}


$BOX-LINE-COLOR: #ddd;
@mixin boxLine {
  border: 1px solid $BOX-LINE-COLOR;
}
@mixin boxShadow($bs-color: rgba(#ddd, .4)) {
  box-shadow: 0 2px 0 0 $bs-color;
}

@mixin grad-blu-45deg {
  background: #0076b9; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #0076b9 0%, #00abeb 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #0076b9 0%,#00abeb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #0076b9 0%,#00abeb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0076b9', endColorstr='#00abeb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
@mixin grad-blu {
  background: #00abeb;
  background: -moz-linear-gradient(top, #00abeb 0%, #005eab 100%);
  background: -webkit-linear-gradient(top, #00abeb 0%,#005eab 100%);
  background: linear-gradient(to bottom, #00abeb 0%,#005eab 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00abeb', endColorstr='#005eab',GradientType=0 );
}




/** ========================================
 * 関数
 * ====================================== */
/** --------------------
 * フォントサイズのrem変換
 * ------------------ */
@function remSize($rsize) {
  $rs : ($rsize*0.0625rem);
  @return $rs;
}



@keyframes show{
  from{
      opacity: 0;
  }
  to{
      opacity: 1;
  }
}
