/*! 
 * 病院情報関連
 */

 /** ========================================
 * @p--hosp
 * 病院情報関連
======================================== */
// トグルブロック
// 上下分かれているブロック
.p--reg__block--separate{
  .c--primary__heading{
    margin-top: 20px;
  }
  .p--reg__head{
    margin: 25px auto 0;
    padding: 12px 55px 14px 25px;
    border: 1px solid #dadadb;
    border-radius: 4px;
    color: #e92d82;
    text-align: center;
    box-shadow: none;
  } 
  .p--reg__body{
    margin: 20px 0 0;
    padding: 25px 8px;
    box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
    .p--reg__head{
      width: 95%;
      + .tgl__body--search__area{
        margin-top: 20px;
      }
    }
  }
}

// 上部にタブが付いているブロック
.p--reg__block--topTab{
  .p--searchArea__tab{
    margin: 20px 0 0;
    .p--tab{
      width: 50%;
      height: 60px;
      &--active{
        height: 68px;
      }
    }
    + .p--reg__body{
      margin: 0;
      box-shadow: none;
    }
  }

}
/* strat 20200427 */
@media screen and (max-width: 767px) {
  #obsr {
    .p--reg__block--topTab{
      .p--searchArea__tab{
        margin: 0;
        .p--tab{
         &:not(.p--tab--active) {
            overflow: hidden;
            border: 1px solid #dadadb;
            box-shadow: none;
          }
          &--active {
            position: relative;
            border-left: 1px solid #dadadb;
            border-right: 1px solid #dadadb;
            border-radius: 0;
            &::after {
              content: "";
              display: inline-block;
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background: #fff;
            }
          }
        }
      }
    }
  }
}
/* end 20200427 */




.p--intern__block{
  padding: 0 30px;
  + .p--intern__block{
    margin: 17px 0 0;
    padding-top: 42px;
    border-top: 1px solid #ccc;
    &.p--intern__block--no__bd{
      padding-top: 0;
      border-top: none;
    }
  }
}


// 背景ピンクでテキスト見出しとボタンが並んでいるエリア
.p--livingArea{
  .p--area__inner{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 14px;
    background: #FFEFF4;
    a{
      width: 420px;
      margin: 0 0 0 10px;
      padding: 15px 13px;
      font-size: remSize(16);
      font-weight: normal;
      .c--arrow{
        display: inline-block;
        width: 100%;
        i{
          font-size: remSize(18);
          font-weight: bold;
        }
      }
    }
  }
}




.p--intern__ttl{
  position: relative;
  display: flex;
  align-items: center;
  font-size: remSize(18);
  font-weight: bold;
  &:before{
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 7px 0 0;
  }
  &--search{
    &:before{
      background: url(../images/icon_circle_search.png) 100% / contain no-repeat;
    }
  }
  &--history{
    &:before{
      background: url(../images/icon_circle_history.png) 100% / contain no-repeat;
    }
  }
  &--list{
    &:before{
      background: url(../images/icon_circle_list.png) 100% / contain no-repeat;
    }
  }
  &--hospital{
    &:before{
      background: url(../images/icon_circle_hospital.png) 100% / contain no-repeat;
    }
  }
  &--salary{
    &:before{
      background: url(../images/icon_circle_salary.png) 100% / contain no-repeat;
    }
  }
}

.c--primaryTable + .p--intern__ttl{
  margin: 40px 0 0;
}

// 検索ボックス
.p--intern__searchArea{
  .p--searchArea__box{
    margin: 15px 0 0;
    input{
      width: 600px;
      height: 56px;
    }
    .c--btn--input{
      width: 140px;
      height: 56px;
      margin: 0 0 0 10px;
    }
  }
  .c--multiple__box{
    width: 600px!important;
    height: 8em!important;
    overflow-x: auto!important;
  }
}

// タブエリア
// タブ切り替えエリア
.p--simpleTab{
  display: flex;
  background: $clr-g40;
  padding: 0 15px;
  .p--tabSelect{
    border-radius: 0;
    color: $clr-p80;
    font-weight: bold;
  }
  &__item{
    margin: 8px 0;
    border-radius: 4px;
    background: #fff;
    &:not(:first-child){
      margin-left: 8px;
    }
  }
  &Head{
    .p--simpleTab__item{
      p,a{
        display: inline-block;
        padding: 12px 22px 15px;
      }
      &.p--tabSelect{
        margin-bottom: 0;
        border-top: 4px solid $clr-p80;
        p,a{
          padding: 11px 22px 23px;
        }
      }
      &.p--tabArrow{
        p,a{
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          text-align: center;
          &:before{
            content: "";
            position: absolute;
            bottom: 7px;
            left: 50%;
            width: 6px;
            height: 6px;
            border: solid #D9D9DB;
            border-width: 0 2px 2px 0;
            z-index: 10;
            transform: translateX(-50%) rotate(45deg);
          }
        }
        &.p--tabSelect{
          p,a{
            &:before{
              bottom: 15px;
              border-color: $clr-p80;
            }
          }
        }
      }
    }
  }
  &Bottom{
    .p--simpleTab__item{
      padding: 0;
      a{
        display: inline-block;
        padding: 15px 22px 12px;
      }
      &.p--tabSelect{
        margin-top: 0;
        border-bottom: 4px solid $clr-p80;
        p,a{
          padding: 23px 22px 11px;
        }
      }
      &.p--tabArrow{
        p,a{
          position: relative;
          display: flex;
          align-items: center;
          height: 100%;
          text-align: center;
          &:before{
            content: "";
            position: absolute;
            top: 7px;
            left: 50%;
            width: 6px;
            height: 6px;
            border: solid #D9D9DB;
            border-width: 0 2px 2px 0;
            z-index: 10;
            transform: translateX(-50%) rotate(-135deg);
          }
        }
        &.p--tabSelect{
          p,a{
            &:before{
              top: 15px;
              border-color: $clr-p80;
            }
          }
        }
      }
      &:hover{
        a{
          padding-top: 15px;
        }
      }
    }
  }
  &__num{
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 2px 8px;
    border-radius: 100px;
    background: $clr-p80;
    color: $clr-w;
    font-size: remSize(10);
    line-height: 1;
    &--empty{
      background: $clr-g40;
      color: $clr-g70;
    }
  }
}


@media screen and (min-width: 768px){
  .p--simpleTab{
    &__item{
      &:not(.p--tabSelect){
        &:hover{
          cursor: pointer;
        }
      }
      &:hover{
        color: $clr-p80;
        font-weight: bold;
        a{
          padding-top: 11px;
        }
      }
    }
    &Head{
      .p--simpleTab__item{
        &:not(.p--tabSelect){
          &:hover{
            border-top: 4px solid $clr-p80;
            p{
              padding-top: 11px;
            }
          }
        }
        &.p--tabArrow{
          &:hover{
            p,a{
              &:before{
                border-color: $clr-p80;
              }
            }
          }
        }
      }
    }
    &Bottom{
      .p--simpleTab__item{
        a{
  
          &:not(.p--tabSelect){
            &:hover{
              padding-bottom: 11px;
              border-bottom: 4px solid $clr-p80;
            }
          }
        }
        &.p--tabArrow{
          &:hover{
            p,a{
              &:before{
                border-color: $clr-p80;
              }
            }
          }
          &.p--tabSelect{
            p,a{
              &:before{
                top: 15px;
                border-color: $clr-p80;
              }
            }
          }
        }
      }
    }
  }
}




// 詳細条件で探す
.p--intern__detailCond{
  .p--simpleTab{
    margin: 27px 0 0;
  }

  .p__check{
    dt{
      margin: 27px 0 0;
      padding: 9px 20px;
      background: #FFEFF4;
      font-weight: bold;
    }
    dd{
      margin: 15px 0 0;
      .p--tabBody__ttl{
        margin: 5px;
        color: $clr-p80;
        &.c--tgl__head{
          position: relative;
          &:before{
            margin: 0 5px 0 0;
            content: "▼";
          }
          &.p--tgl--open{
            &:before{
              content: "▲";
            }
          }
        }
      }
      .p__checkBox{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
    }
    .c--control{
      align-self: stretch;
      margin: 0 24px 0 0;
      padding: 10px 10px 10px 33px;
      border-top: 1px solid $clr-g40;
      width: calc((100% - 82px) / 4);
      &:nth-child(4n){
        margin-right: 0;
      }
      &--nobdt{
        border-top: none;
      }
      &__indicator{
        top: calc(10px + .25em);
        left: 8px;
        height: 16px;
        width: 16px;
        transform: translateY(0);
      }
    }
  }

  .c--main__map{
    margin: 50px 10px;
    &--nobg{
      margin: 50px 10px 0;
    }
  }

}

.p--result__intern{
  margin: 35px 0 0;
  .p--search__intern + &{
    margin: 60px 0 0;
  }
  .p--intern__list{
    margin: 25px 0 0;
    .p--list__item{
      background: $clr-w;
      &.c--boxShadow{
        box-shadow: 0 1px 6px rgba(97,97,106,.3), 0 1px 10px rgba(97,97,106,.3);
      }
      + .p--list__item{
        margin: 55px 0 0;
      }
      .p--item__heading{
        border-top: 2px solid $clr-p80;
        padding: 12px 22px;
        font-size: remSize(20);
        font-weight: bold;
        &--fav{
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 0 22px;
          .p--fav__ttl{
            display: flex;
            align-items: center;
            width: 100%;
            &--col1{
              .c--control{
                width: 100%;
                margin-right: 0!important;
                .c--boxHeading{
                  display: inline-block;
                  margin-left: 5px;
                }
              }
            }
          }
          .c--ovalTag{
            width: auto;
            margin: 0;
            padding: 0 10px;
            white-space: nowrap;
          }
          .p--heading__ttl{
            width: 100%;
            padding: 12px 12px 12px 0;
          }
          .p--fav__btn{
            align-self: stretch;
            .p--favAnime{
              position: relative;
              display: flex;
              align-items: center;
              min-width: 134px;
              height: 100%;
              padding: 18px 12px 18px 37px;
              border-left: 1px solid #ccc;
              font-size: remSize(12);
              text-align: center;
              &:hover{
                cursor: pointer;
              }
            }
          }

        }
      }
      .p--item__body{
        display: flex;
        align-items: flex-start;
        padding: 15px 20px;
        border-top: 1px solid $clr-g40;
        &--arrowLink{
          > a{
            display: flex;
            align-items: flex-start;
            padding: 0 24px 0 0;
            &.c--arrow:before{
              right: 0;
            }
            .p--body__img--846__252{
              width: 830px;
              img{
                height: calc(830px / 84.6 * 25.2);
              }
            }
            .p--body__txt{
              width: 473px;
            }
          }
          &.p--item__body--col1{
            .p--body__txt{
              width: 100%;
            }
          }
        }
        .p--body__txt{
          width: calc(100% - 357px);
          margin: 0 0 0 27px;
          ul{
            margin: 7px 0 0;
            li{
              margin: 7px 10px 0 0;
              span:not(.c--ovalTag--pk){
                color: $clr-g70;
              }
            }
          }.p--tag--intern{
            margin: 7px 0 0;
          }
        }
        .p--body__ttl{
          font-size: remSize(16);
          font-weight: bold;
          + p{
            margin: 7px 0 0;
          }
        }

        &--noFlex{
          display: block;
          padding-left: 15px;
          padding-right: 15px;
          .p--body__txt{
            width: 100%;
            margin: 0;
          }
          .p--body__catchcopy{
            padding: 0 10px;
            font-size: remSize(16);
            font-weight: bold;
          }
          .p--body__img{
            + .p--body__catchcopy{
              margin-top: 10px;
            }
          }
        }

        .p--body__link{
          display: flex;
          justify-content: space-between;
          margin-bottom: 60px;
          .p--link__item{
            position: relative;
            width: calc((100% - 40px)/4);
            padding: 13px 20px 27px;
            border: 1px solid #dadadb;
            border-radius: 5px;
            text-align: center;
            &::before{
              content: "";
              position: absolute;
              left: calc(50% - 6px);
              bottom: 8px;
              display: inline-block;
              width: 12px;
              height: 14px;
              background: url(../images/common/svg/arrow_down_b.svg);
            }
          }
        }

        .p--body__img{
          margin: 2px auto 0;
          &--276__200{
            width: 276px;
            img{
              height: 200px;
            }
          }
          &--330__200{
            width: 330px;
            img{
              height: 200px;
            }
          }
          &--846__254{
            width: 846px;
            img{
              height: 254px;
            }
          }
          &--846__252{
            width: 846px;
            img{
              height: 252px;
            }
          }
        }


        .p--body__rsvArea{
          position: relative;
          width: 100%;
          padding: 10px;
          border-radius: 8px;
          &:first-of-type{
            margin: 15px 0 0;
          }
          .p--rsvArea__ttl{
            padding: 8px 14px;
            background: #FFEFF4;
          }
          .c--ovalTag{
            margin: 0 12px 0 0;
          }

          .p--list__item{
            display: flex;
            margin: 10px 0 0;
            padding: 10px 0 0;
            border-top: 1px solid #ccc;
            background: $clr-w;
            p{
              &:first-child{
                width: 180px;
                padding: 0 0 0 17px;
              }
            }
          }
        }

        &--col1{
          display: block;
          > a{
            display: block;
          }
          .p--body__txt{
            width: 100%;
            margin: 0!important;
            ul{
              + .p--tgl__block{
                margin: 15px 0 0;
              }
            }
          }
          .p--body__img{
            + .p--body__txt{
              margin: 10px 0 0!important;
            }
          }
        }
      }
    }
  }
  // 「インターンシップ口コミあり」タグ
  .p--item__body--btmAdd__tag{
    padding-bottom: 10px!important;
  }
  .p--tag--intern{
    position: relative;
    display: inline-block;
    margin: 0 20px 15px;
    padding: 4px 6px 6px 28px;
    border-radius: 3px;
    background: #F84361;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    &::before{
      content: "";
      position: absolute;
      top: calc(50% - 7px);
      left: 6px;
      display: inline-block;
      background: url(../images/icon_wm.png) no-repeat center / contain;
      width: 16px;
      height: 15px;
    }
  }


  // 検索条件
  .c--search__main{
    .c--item__txt{
      + .c--btn--searchIcon{
        margin: 30px 0 0;
      }
    }
    .c--control{
      width: 100%;
      margin: 15px 0 0;
    }
    .c--main__item--selectArea{
      .c--item__selectTTl:not(:first-child){
        margin: 10px 0 0;
      }
      select{
        height: 29px;
        margin: 5px 0 0;
        padding: 5px 30px 5px 10px;
      }
      .c--select__arrow{
        width: 7px;
        height: 7px;
        border-color: $clr-g40;
      }
    }

  }

}

.selection__box{
  position: absolute;
  top: -23px;
  left: 22px;
  background: #f84361;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  &::before,
  &::after{
    content: "";
    display: inline-block;
    position: absolute;
  }
  &::before{
    top: -13px;
    left: -13px;
    width: 16px;
    height: 16px;
    background: url(../images/icon_slash_line.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
  &::after{
    bottom: -5px;
    left: calc(50% - 2px);
    border-top: 5px solid #f84361;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  span{
    position: relative;
    display: inline-block;
    padding: 8px 11px 8px 34px;
    line-height: 1;
    &::before{
      content: "";
      position: absolute;
      top: calc(50% - 7px);
      left: 10px;
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../images/icon_pencil-w.png);
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

// ページ送り
.c--page__ctrl{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $clr-g70;
  .c--btn--m{
    margin: 0 0 0 5px;
    padding: 11px 7px;
  }
  .c--arrow{
    &--p__right{
      padding: 0 11px 0 0;
    }
    &:before{
      width: 6px;
      height: 6px;
    }
  }
  &:not(:first-of-type){
    margin: 25px 0 0;
  }
}

// 追従エリア
.p--fixed__bnr{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 22px 10px;
  background: rgba(97,97,106,.9);
  z-index: 1010;
  opacity: 0;
  .p--bnr__txt{
    display: flex;
    align-items: center;
    color: #fff;
    font-size: remSize(16);
    span{
      margin: 0 5px 0 10px;
      font-size: remSize(25);
      font-weight: 700;
    }
  }
  .c--btn--lb{
    width: 295px;
    margin: 0 0 0 40px;
  }
  .c--btn--pk{
    width: 295px;
  }
  .c--btn--w{
    margin: 0 0 0 10px;
  }

  .bnrItem__switch{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p--search__calender{
  .c--select,
  .c--btn{
    margin: 0 10px;
  }
  .c--ui__date{
    + span{
      margin: 0 10px;
    }
  }
}

// 追従エリア表示切替
// JSの「チェックボックスが押されたら追従フッターを表示する」とセットで使用
.switchDisplay{
  visibility: hidden;
  opacity: 0;
  animation: switchFadeOut 0.2s;
}
.switchDisplay.is-show {
  visibility: visible;
  opacity: 1;
  animation: switchFadeIn 0.2s;
}

@keyframes switchFadeIn {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes switchFadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/** ========================================
 * @EDCT
 * EDCTページ
======================================== */
.p--result__intern{
  &.p--trainingInfo__area{
    .p--intern__list{
      > .p--list__item{
        > .p--item__body{
          padding: 15px 40px;
        }
      }
    }
    .p--trainingInfo{
      &__top{
        display: flex;
        flex-direction: row-reverse;
        .p--top__body{
          width: calc(100% - 335px);
          padding: 0 40px 0 0;
          .p--body__detail{
            display: flex;
            align-items: center;
            li{
              &:not(:first-child){
                margin: 0 0 0 15px;
              }
            }
            span{
              color: $clr-g70;
            }
          }
          .p--body__tag{
            margin: 10px 0 0;
            .c--ovalTag{
              margin: 0 0 10px 10px;
            }
            &--add__ttl{
              text-indent: -72px;
              margin-left: 72px!important;
              span{
                text-indent: 0;
                &:first-of-type{
                  display: inline-block;
                  width: 70px;
                  color: #828288;
                }
              }
            }
          }
          .p--body__catchcopy{
            margin: 10px 0 0;
          }
          .p--body__list{
            display: flex;
            justify-content: space-between;
            margin: 22px 0 0;
            .p--list__item{
              width: calc(50% - 10px);
              margin: 0!important;
              .p--item__ttl{
                background: #ffeff4;
                padding: 8px 18px;
                font-size: remSize(12);
              }
              .p--item__body{
                font-size: remSize(12);
                &--tag{
                  .c--ovalTag{
                    width: 80px;
                  }
                }
                > div{
                  display: flex;
                  flex-direction: column;
                  margin: 0 0 0 5px;
                }
                a + a{
                  margin: 5px 0 0;
                }
              }
            }
          }
        }
        .p--body__img{
          margin: 0 0 0 20px;
        }
      }
      &__btm{
        margin: 30px 0 15px;
        padding: 15px 0 8px;
        border-top: 1px solid $clr-g40;
        border-bottom: 1px solid $clr-g40;
        .p--btm__info{
          display: flex;
          padding: 0 0 0 10px;
          + .p--btm__info{
            margin: 12px 0 0;
            padding-top: 10px;
            border-top: 1px solid $clr-g40;
          }
          .p--info__ttl{
            width: 123px;
            color: $clr-p80;
            font-weight: bold;
          }
          .p--info__list{
            width: calc(100% - 123px);
            a{
              display: block;
              width: 100%;
              padding-right: 20px;
              &.c--arrow--p__right:before{
                right: 14px;
              }
            }
            li{
              span{
                margin: 0 2px 0 0;
              }
              + li{
                margin: 12px 0 0;
                padding: 10px 0 0;
                border-top: 1px solid #ccc;
              }
            }
            &--exhibit{
              a{
                display: flex;
              }
              .p--exhibit__item{
                &--date{
                  min-width: 100px;
                }
                &--place{
                  margin-left: 10px!important;
                }
              }
              .c--tag{
                margin-left: 10px!important;
              }
            }
          }
        }
      }
    }

    .p--trainingTab{
      margin: 55px 0 0;
      .p--tabBody__main{
        width: 846px;
        margin: 0 auto;
        padding: 25px 0 60px;
        .p--main__read{
          margin: 25px 0 0;
          font-size: remSize(16);
          font-weight: bold;
          + .p--body__img{
            margin: 25px 0 0;
          }
        }
        .p--main__subHeading{
          margin: 28px 0 0;
          .p--subHeading__txt{
            margin: 12px 2px;
            color: $clr-p80;
            font-size: remSize(16);
            font-weight: bold;
          }
        }
        .p--main__text{
          margin: 30px 0 0;
          display: flex;
          justify-content: space-between;
          &--right{
            flex-direction: row-reverse;
            .p--text{
              margin-left: 50px;
            }
          }
          &--left{
            .p--text{
              margin-right: 50px;
            }
          }
          .p--text{
            width: calc(100% - 380px);
            line-height: 1.8;
          }
          .p--body__img{
            span{
              margin: 5px 0;
              display: inline-block;
            }
          }
          + .p--main__subHeading{
            margin: 50px 0 0;
          }
        }
        .p--main__column{
          margin: 50px 0 0;
          .p--column__ttl{
            background: #F1F6FA;
            padding: 20px 32px;
            line-height: 1.6;
            font-size: remSize(16);
            font-weight: bold;
          }
          .p--column__txt{
            background: #F1F6FA;
            border-top: 1px solid $clr-w;
            padding: 20px 32px;
            line-height: 1.9;
          }
        }
        .p--main__basicInfo{
          margin: 60px 0 0;
          .c--primaryTable{
            margin: 15px 0 0;
            font-size: remSize(13);
            th{
              width: 245px;
            }
          }
          .p--basicInfo__list{
            margin: 40px 0 0;
            .p--list__ttl{
              padding: 8px 18px;
              border-bottom: 1px solid $clr-g40;
              background: #ffeff4;
              font-weight: bold;
            }
            ul{
              li{
                a{
                  display: inline-block;
                  width: 100%;
                  padding: 13px 15px 13px 10px;
                  &.c--arrow--p__right:before{
                    right: 10px;
                  }
                }
                &:not(:first-child){
                  border-top: 1px solid $clr-g40;
                }
                span{
                  margin: 0 20px 0 0;
                }
              }
            }
          }
        }
        .c--tgl__block--single{
          border: 1px solid #dadadb;
          box-shadow: none;
          .c--tgl__head{
            display: flex!important;
            justify-content: space-between;
            padding: 10px 21px;
            background: #f1f6fa;
            font-size: remSize(13);
            font-weight: normal;
            span{
              font-size: remSize(11);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .p--result__intern{
    &.p--trainingInfo__area{
      .p--trainingInfo__top{
        .p--top__body{
          .p--body__tag{
            &--add__ttl{
              font-size: .75rem;
            }
          }
        }
      }
    }
  }
}

.p--trainingInfo__wrap{
  .c--card{
    margin: 35px 0 50px;
  }
  .p--fixed__bnr{
    .c--btn--l{
      width: 295px;
      &:first-child{
        margin: 0;
      }
      &:not(:first-child){
        margin: 0 0 0 10px;
      }
    }
  }
  .p--top__btn{
    padding: 0 0 50px 0;
  }
}



/** ========================================
 * @SPFT
 * SPFTページ
======================================== */
.p--hospFeature__wrap{
  .c--contents__inner{
    width: 894px;
  }
}
.p--fixed__bnr--allCheck{
  .c--control{
    &__indicator{
      width: 26px;
      height: 26px;
      border-radius: 4px;
    }
    &__txt{
      margin: 0 0 0 10px;
      color: $clr-w;
      font-size: remSize(20);
      font-weight: bold;
    }
  }
  .c--btn--lb{
    width: 425px;
    margin: 0 0 0 15px;
  }
}

.p--intern__list--headCheck{
  .p--list__item{
    .p--item__heading{
      .p--fav__ttl{
        .c--ovalTag{
          width: auto;
          margin: 0;
          padding: 5px 14px;
        }
        .c--control{
          min-width: 23px;
          margin: 0 10px 0 0;
          &__indicator{
            width: 23px;
            height: 23px;
          }
        }
        .c--control__txt{
          a{
            display: inline;
            color: $clr-b;
            text-decoration: none;
          }
        }
        .p--new{
          min-width: 30px;
          margin: 0 0 0 8px;
          color: $clr-red;
          font-size: remSize(12);
        }
      }
    }
    .p--item__body{
      padding: 15px 20px 10px;
      .p--body__txt{
        ul{
          margin: 15px 0 0;
        }
        .p--body__list--row{
          display: block;
          li{
            display: inline-block;
          }
        }
        
        .p--body__list--tags{
          li{
            text-indent: -72px;
            margin-left: 72px!important;
            span{
              text-indent: 0;
              &:first-of-type{
                width: 70px;
                display: inline-block;
              }
            }
          }
        }
        .p--body__tag{
          margin: 13px 0 0;
        }
      }
      &--col1{
        .p--body__img{
          + .p--body__txt{
            margin: 15px 0 0;
          }
        }
        .p--body__txt{
          margin: 0;
          ul{
            display: flex;
            flex-direction: column;
            margin: 10px 0 0;
            li{
              margin: 7px 20px 0 0;
            }
          }
        }
      }
    }
    .p--item__btnArea{
      display: flex;
      justify-content: center;
      padding: 17px 25px;
      border-top: 1px dotted $clr-g40;
      .c--btn{
        width: calc(50% - 5px);
        padding: 20px 22px;
        font-size: remSize(16);
        + .c--btn{
          margin-left: 10px;
        }
      }
    }
  }
} //p--intern__list--headCheck


@media screen and (max-width: 767px){

  // トグルブロック
  // 上下分かれているブロック
  .p--reg__block--separate{
    .p--reg__body{
      padding: 25px 0;
      box-shadow: none;
    }
    .p--reg__head{
      margin-top: 15px;
      padding: 3px 55px 3px 25px;
      font-size: remSize(13);
      &:before{
        right: 35px;
      }
      &:after{
        width: 6px;
        height: 6px;
        right: 14px;
      }
    }
  }
  .p--intern__block{
    padding: 5px 20px 20px;
    + .p--intern__block{
      margin: 0;
      padding-top: 20px;
    }
  }

  .p--fixed__bnr--allCheck{
    .c--control{
      margin-right: 10px;
      &__indicator{
        width: 20px;
        height: 20px;
      }
      &__txt{
        font-size: remSize(16);
      }
    }
    .c--btn--lb{
      width: 425px;
      margin: 0 0 0 15px;
    }
  }

  /* strat 20200427 */
  #obsr {
    .p--reg__block--separate{
      .p--reg__body{
        border-top: 1px solid #dadadb;
      }
    }
  }
  #seni {
    .p--reg__block--separate{
      .p--reg__body{
        margin-top: 0;
      }
    }
  }
  /* end 20200427 */



  // 背景ピンクでテキスト見出しとボタンが並んでいるエリア
  .p--livingArea{
    .p--area__inner{
      padding: 10px;
      flex-wrap: wrap;
      .p--area__ttl{
        width: 100%;
      }
      .p--area__link{
        width: calc(50% - 11px);
        margin: 10px 0 0;
        padding: 8px 13px;
        + .p--area__link{
          margin-left: 14px;
        }
        &--col1{
          width: 100%;
          .c--arrow{
            &:before{
              display: none;
            }
          }
        }
      }
      a{
        .c--arrow{
          font-size: remSize(12);
          line-height: 1.5;
          i{
            font-size: remSize(14);
          }
        }
      }
    }
  }


  // 検索ボックス
  .p--intern__searchArea{
    .p--searchArea__box{
      input{
        width: calc(100% - 106px);
        height: 50px;
        font-size: .7rem!important;
      }
      .c--btn--input{
        width: 96px;
        height: 50px;
        margin: 0 0 0 10px;
        padding: 14px 6px;
      }
    }
    .c--multiple__box{
      width: calc(100% - 106px)!important;
      height: 45px!important;
      padding: 10px 30px 10px 10px;
    }
  }


  // 詳細条件で探す
  .p--intern__detailCond{
    padding: 20px 0 0;
    .p--intern__ttl{
      padding: 0 20px;
    }
    .p--simpleTab{
      padding: 0 10px;
      span{
        display: block;
        line-height: 1.25;
      }
      &Head{
        .p--simpleTab__item{
          min-width: 90px;
          text-align: center;
          p,a{
            padding: 9px 10px;
          }
          &.p--tabSelect{
            p,a{
              padding: 5px 10px 13px;
            }
          }
        }
      }
      &__num{
        margin: 3px 0 0;
      }
    }

    .c--main__map{
      margin: 14px 0;
      &--nobg{
        margin-bottom: 0;
      }
      .c--area__item--pref01-01,
      .c--area__item--pref02-01{
        &.c--area__item:first-child{
          border-top: 1px solid $clr-g40;
        }
      }
      .c--area__item--pref01-09,
      .c--area__item--pref02-09{
        border-bottom: none;
      }
    }

  }

  .p--search__intern{
    margin: 20px 0 0;
    .p--select__area{
      background: $clr-w;
      padding: 14px;
      .p--select__result{
        display: flex!important;
        align-items: center;
        .p--result__resultNum{
          width: 105px;
          i{
            color: $clr-p80;
            font-size: remSize(18);
            font-weight: bold;
          }
        }
        .p--result__selectList{
          width: calc(100% - 105px);
          padding: 0 0 0 15px;
          border-left: 1px solid;
          font-size: remSize(10);
          .c--main__ttl{
            color: #828288;
            font-weight: bold;
          }
        }
      }
      .p--reg__head{
        margin: 13px 0 0;
        padding: 5px 35px 5px 21px;
        font-size: remSize(13);
        &:before{
          right: 35px;
        }
        &:after{
          width: 6px;
          height: 6px;
          right: 14px;
        }
      }

      &--resultInner{
        .p--select__result{
          display: block!important;
          .p--result__resultNum{
            width: 100%;
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            i{
              margin: 0 0.5em 0 1em;
              font-size: 1.5rem;
            }
          }
          .p--result__selectList{
            width: 100%;
            margin-top: 20px;
            padding: 0 5px;
            border: none;
            color: #333;
            font-size: 14px;
            span{
              color: #828288;
            }
          }
        }
        + .p--reg__head{
          margin-top: 0;
        }
      }

    }
  }

  // 詳細条件で探す
  .p--intern__detailCond{
    .p__check{
      dt{
        margin: 20px 0 0;
      }
      dd{
        margin: 0;
        .p--tabBody__ttl{
          margin: 5px 0;
          padding: 0 20px;
        }
      }
      .c--control{
        width: 100%;
        margin: 0;
        padding: 13px 10px 13px 48px;
        &:first-child{
          border-top: none;
        }
        &__indicator{
          top: calc(13px + .25em);
          left: 22px;
        }
      }
    }
  }

  .p--result__intern{
    margin: 28px 0 0;
  
    .p--intern__list{
      margin-right: 15px;
      margin-left: 15px;
      .p--list__item{
        .p--item__heading{
          padding: 14px 22px 16px;
          font-size: remSize(16);
          &--fav{
            padding: 0 0 0 22px;
            .p--fav__ttl{
              width: 100%;
              p{
                display: inline-block;
                width: auto;
              }
            }
            .p--fav__btn{
              .p--favAnime{
                min-width: 54px;
                min-height: 54px;
                height: 100%!important;
                padding: 0;
              }
            }
            .c--ovalTag{
              margin: 0;
              padding: 5px;
              font-size: .625rem;
              font-weight: 400;
              line-height: 1;
            }
          }
        }
        .p--item__body{
          flex-direction: column;
          padding-bottom: 10px;
          &__sp--row{
            flex-direction: row;
            flex-wrap: wrap;
            .p--body__img--330__200{
              width: 120px!important;
              img{
                height: 72.7px!important;
              }
              + ul{
                width: calc(100% - 130px);
                margin: 0 0 0 10px;
                font-size: .75rem;
                span{
                  color: $clr-g70;
                }
              }
            }
          }
          &--noFlex{
            padding: 15px 10px;
            .p--body__catchcopy{
              font-size: remSize(14);
            }
          }
          .p--body__link{
            margin-bottom: 20px;
            padding: 0 20px;
            .p--link__item{
              width: calc((100% - 15px)/4);
              padding: 7px 5px 18px;
              font-size: remSize(11);
              &:before{
                background-size: contain;
                left: calc(50% - 4px);
                bottom: 5px;
                width: 8px;
                height: 9px;
              }
            }
          }
          .p--body__img{
            margin: 0 auto;
            &--276__200{
              width: 138px;
              img{
                height: 100px;
              }
            }
            &--330__200{
              width: 100%;
              max-width: 330px;
              img{
                height: calc(((100vw - 40px) / 33) * 20);
                max-height: 200px;
              }
            }
            &--846__254{
              width: 330px;
              img{
                height: 200px;
              }
            }
            &--846__252{
              width: 100%!important;
              padding: 0 20px;
              img{
                height: calc((100vw - 40px) / 33.5 * 10)!important;
              }
            }
          }
          .p--body__rsvArea{
            .p--rsvArea__ttl{
              display: flex;
              flex-direction: column;
              .c--arrow{
                &--p__right{
                  padding: 0;
                }
                &:before{
                  display: none;
                }

              }
            }
            .p--list__item{
              p:first-child{
                width: 140px;
              }
            }
          }
          .p--body__txt{
            width: 100%;
            margin: 10px 3px 0;
            ul{
              margin: 5px 0 0;
              font-size: remSize(12);
              li{
                margin: 5px 0 0;
              }
            }
          }
          .p--body__ttl{
            font-size: remSize(14);
            + p{
              font-size: remSize(12);
              margin: 5px 0 0;
            }
          }

          &--arrowLink{
            > a{
              .p--body__img--846__252{
                padding: 0;
                img{
                  height: calc((100vw - 64px) / 33.5 * 10)!important;
                }
              }
              .p--body__txt{
                width: 100%;
              }
            }
            &.p--item__body__sp--row{
              > a{
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
              }
            }
          }

        }
        + .p--list__item{
          margin-top: 24px;
        }
      }
    }
  
    .c--search__main{
      .c--item__txt{
        + .c--btn--searchIcon{
          margin: 13px 0 0;
          padding: 8px 22px;
        }
      }
    }

  }



  .selection__box{
    top: -8px;
    font-size: 11px;
    &::before{
      top: -8px;
      left: -8px;
      width: 10px;
      height: 10px;
      background-size: contain;
    }
    span{
      padding: 4px 3px 5px 21px;
      &::before{
        left: 3px;
      }
    }
  }


  // ページ送り
  .c--page__ctrl{
    padding: 0 20px;
    font-size: remSize(12);
  }

  
  // 追従エリア
  .p--fixed__bnr{
    .p--bnr__txt{
      flex-direction: column;
      width: auto;
      font-size: remSize(12);
      span{
        font-size: remSize(20);
      }
    }
    .c--btn{
      min-height: 58px;
    }
    .c--btn--lb{
      width: auto;
      margin: 0 0 0 10px;
      padding: 18px;
      font-size: remSize(16);
    }
    .c--btn--pk{
      width: 92%;
      padding: 18px;
      font-size: 1rem;
    }
    .c--btn--w{
      padding: 10px 13px;
      font-size: remSize(14);
      line-height: 1.3;
    }
  }

  // 検索条件
  .c--search__main{
    .c--main__item{
      .p--item__spTop{
        display: flex!important;
        .c--point__txt{
          width: 100px;
          color: $clr-g60;
          font-size: remSize(12);
          text-align: left;
          i{
            font-size: remSize(20);
          }
        }
        .c--item__txt{
          width: calc(100% - 100px);
          padding: 0 0 0 20px;
          border-left: 1px solid $clr-g40;
          color: $clr-g60;
          font-size: remSize(10);
        }
      }
    }
  }

  .p--search__calender{
    padding: 20px;
  }



  /** ========================================
  * @EDCT
  * EDCTページ
  ======================================== */
  .p--result__intern{
    &.p--trainingInfo__area{

      .p--intern__list{
        > .p--list__item{
          > .p--item__body{
            padding: 20px 0 0;
          }
          > .p--item__heading--fav{
            .p--heading__ttl{
              margin: 0 10px 0 0;
              .p--fav__ttl{
                display: block;
                .c--boxHeading{
                  margin-right: 0;
                }
              }
            }
            .c--ovalTag{
              width: auto;
              margin: 0;
              padding: 5px;
              font-size: remSize(10);
              font-weight: normal;
              line-height: 1;
            }
          }
        }
      }
      .p--trainingInfo{
        &__top{
          display: block;
          .p--body__img--330__200{
            img{
              height: calc(100vw / 33 * 20)!important;
            }
          }
          .p--top__body{
            width: 100%;
            margin: 10px 0 0;
            padding: 0 20px;
            .p--body__detail{
              flex-wrap: wrap;
              font-size: remSize(12);
              li{
                margin: 0 10px 0 0!important;
                &:not(:first-child){
                  margin-top: 2px;
                }
              }
            }
            .p--body__tag{
              .c--ovalTag{
                margin: 4px;
              }
            }
            .p--body__catchcopy{
              margin: 15px 0 0;
              padding: 0;
              font-size: remSize(14);
            }
            .p--body__list{
              display: block;
              margin: 15px 0 0;
              .p--list__item{
                width: 100%;
                .p--item__ttl{
                  padding: 0 12px;
                }
                .p--item__body{
                  flex-direction: row;
                  padding: 13px 15px;
                  border-top: none;
                  &--tag{
                    .c--ovalTag{
                      width: 78px;
                      padding: 2px 0;
                      font-size: remSize(10);
                    }
                  }
                }
              }
            }
          }
        }
        &__btm{
          padding: 0 20px;
          border: none;
          .p--btm__info{
            display: block;
            padding: 0;
            + .p--btm__info{
              border-bottom: 1px solid $clr-g40;
              padding-bottom: 8px;
            }
            .p--info__ttl{
              width: 100%;
              padding: 2px 12px 10px;
              border-bottom: 1px solid  $clr-g40;
              font-size: remSize(12);
            }
            .p--info__list{
              width: 100%;
              margin: 8px 0 0;
              font-size: remSize(12);
              line-height: 1.3;
              a{
                display: flex;
                align-items: flex-start;
                padding: 0 10px;
                &.c--arrow--p__right:before{
                  right: 1px;
                }
                .c--ovalTag{
                  + span{
                    width: calc(100% - 80px);
                    padding: 0 0 0 10px;
                  }
                }
                p{
                  font-size: remSize(12);
                }
              }
              &--exhibit{
                .p--exhibit__item{
                  &--date{
                    min-width: 90px;
                  }
                }
              }
            }
          }
        }
      }

      .p--trainingTab{
        .p--tabBody__main{
          width: 100%;
          padding: 25px 0 10px;
          .c--third__heading{
            padding: 0 20px;
          }
          .p--main__read{
            margin: 13px 0 0;
            padding: 0 20px;
            font-size: remSize(14);
            line-height: 1.5;
            + .p--body__img{
              margin: 35px auto 0;
              &--846__252{
                width: 335px;
                img{
                  height: 100px;
                }
              }
            }
          }
          .p--main__subHeading{
            margin: 20px 0 0;
            padding: 0 20px;
            .p--subHeading__txt{
              margin: 12px 2px;
              font-size: remSize(14);
            }
          }
          .p--main__text{
            display: block;
            margin: 5px 0 0;
            padding: 0 20px;
            .p--text{
              width: 100%;
              margin: 0;
              line-height: 1.5;
            }
            .p--body__img{
              margin: 25px auto 17px;
              span{
                margin: 8px 0;
                padding: 0 10px;
                font-size: remSize(12);
              }
            }
            + .p--main__subHeading{
              margin: 20px 0 0;
            }
            &.p--main__text--left,
            &.p--main__text--right{
              display: flex;
              flex-direction: column-reverse;
            }
          }
          .p--main__column{
            margin: 20px 0 0;
            padding: 0 20px;
            .p--column__ttl{
              padding: 12px 16px;
              font-size: remSize(14);
              line-height: 1.5;
            }
            .p--column__txt{
              padding: 5px 19px;
              line-height: 1.5;
            }
          }
          .p--main__basicInfo{
            margin: 30px 0 0;
            .p--intern__ttl{
              padding: 0 20px;
            }
            .c--primaryTable{
              width: calc(100% - 40px);
              margin: 15px 20px 0;
              th{
                width: 100%;
                padding: 3px 15px;
              }
              td{
                padding: 8px 15px 15px;;
              }
            }
            .p--basicInfo__list{
              margin: 5px 0 0;
              .p--list__ttl{
                border: none;
              }
              ul{
                li{
                  display: flex;
                  flex-direction: column;
                  a{
                    padding-right: 20px;
                    padding-left: 20px;
                  }
                  span{
                    width: 100px;
                    margin: 0 20px 5px 0;
                    font-size: remSize(10);
                  }
                }
              }
            }
          }
          .c--tgl__block--single{
            margin: 20px 20px 0;
            + .p--top__btn{
              padding: 20px 20px 25px!important;
              .c--btn{
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
  .p--trainingInfo__wrap{
    .c--boxWht.c--boxShadow{
      box-shadow: none;
    }

    .p--result__intern,
    .p--intern__list{
      margin: 0;
    }

    .p--fixed__bnr{
      padding: 22px 20px;
      .c--btn--l{
        width: auto;
        min-width: 70px;
        padding: 10px 13px;
        font-size: .875rem;
        line-height: 1.3;
      }
    }
    .c--card{
      margin: 20px 0;
      .p--top__btn{
        padding: 0 20px;
      }
    }
    .p--top__btn{
      padding: 20px;
      .c--btn{
        padding: 13px 22px;
        font-size: remSize(12);
      }
    }
    .p--trainingInfo{
      &__mdl{
        padding: 0 20px;
      }
    }
  }
  .p--spMiniTab{
    &.p--simpleTab{
      flex-wrap: wrap;
      padding: 8px 20px;
      .p--simpleTab__item{
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc((100% - 16px) / 3);
        min-height: 36px;
        margin: 0 8px 0 0;
        padding: 0;
        border-radius: 4px;
        font-size: remSize(12);
        &:nth-child(3n){
          margin-right: 0;
        }
        &:nth-child(n + 4){
          margin-top: 8px;
        }
        p,a{
          padding: 9px 5px;
        }
      }
      &Head{
        .p--simpleTab__item{
          &.p--tabSelect{
            border-radius: 0 0 4px 4px;
            p{
              padding-top: 5px;
            }
          }
          &.p--tabArrow{
            p,a{
              padding: 4px 0 14px;
            }
            &.p--tabSelect{
              p,a{
                padding: 4px 0 13px;
                &:before{
                  bottom: 7px;
                }
              }
            }
          }
        }
      }
      &Bottom{
        .p--simpleTab__item{
          &.p--tabSelect{
            border-bottom: 4px solid #e92d82;
            border-top: none;
            border-radius: 4px 4px 0 0;
            p{
              padding-bottom: 5px;
            }
          }
          &.p--tabArrow{
            p,a{
              padding: 14px 0 4px;
            }
            &.p--tabSelect{
              p,a{
                padding: 13px 0 4px;
                &:before{
                  top: 7px;
                }
              }
            }
          }
        }
      }
    }
  
  }

  /** ========================================
   * @SPFT
   * SPFTページ
   ======================================== */
  .p--hospFeature__wrap{
    .c--contents__inner{
      width: 100%;
    }
    .p--hospFeature{
      margin: 20px 0 0;
    }

    .p--fixed__bnr{
      .c--control{
        &__txt{
          font-size: remSize(16);
        }
      }
      .c--btn--lb{
        width: auto;
        margin: 0;
        padding: 18px 45px;
      }
    }
  } //p--hospFeature__wrap

  .p--result__intern{
    .p--intern__list{
      &.p--intern__list--headCheck{
        .p--list__item{
          .p--item__heading--fav{
            .p--fav__ttl{
              .c--control{
                margin-right: 5px;
              }
              .p--new{
                min-width: 30px;
              }
            }
          }
          .p--item__body{
            .p--body__txt{
              ul{
                flex-direction: row;
                flex-wrap: wrap;
                margin: 0;
                li{
                  margin-right: 13px;
                }
              }
              .p--body__tag{
                margin-top: 10px;
              }
            }
          }
          .p--item__btnArea{
            padding: 14px 20px;
            .c--btn{
              padding: 14px 5px;
              font-size: remSize(12);
            }
          }
        }
      } //p--intern__list--headCheck
    }
  }

}

@media screen and (max-width: 320px){

  .p--spMiniTab.p--simpleTab .p--simpleTab__item{
    font-size: remSize(9);
  }

  .p--fixed__bnr{
    .c--btn{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: remSize(12);
    }
  }

  .p--result__intern{
    .p--intern__list{
      &.p--intern__list--headCheck{
        .p--list__item{
          .p--item__btnArea{
            .c--btn{
              padding: 14px 0;
              font-size: .5rem;
            }
          }
        }
      } //p--intern__list--headCheck
    }
  }

}

/** ========================================
 * @p--recruitList
 * 教育・研修情報
 ======================================== */
.p--recruitList{
  &__heading{
    margin: 22px 0 0;
    padding: 5px 20px;
    background: #FFEFF4;
    &--bgGy{
      margin: 10px 0 0;
      padding: 5px 15px;
      background: #F0F0F0;
      font-size: .8125rem;
    }
  }
  &__secondaryHeading{
    margin: 15px 0 0;
  }
  &--bd{
    margin: 0!important;
    padding: 10px 20px;
    border: 1px solid;
    border-color: transparent #F0F0F0 #F0F0F0 #F0F0F0;
    a{
      display: inline-block;
      margin-bottom: 5px;
      text-decoration: underline;
    }
  }
  &__body{
    padding: 10px;
    .p--recruitList{
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0 0;
      font-size: .8125rem;
      &__item{
        width: 50%;
      }
    }
    &--bd{
      padding: 16px 20px!important;
      border: 1px solid #FFEFF4;
    }
  }
  &--shadow{
    box-shadow: 0 0 8px rgba(0, 0, 0, .25);
  }
  + .p--btm__info{
    margin: 30px 0 0;
  }
}
.p--body__img--396__240{
  width: 396px;
  img{
    height: 240px;
  }
}
.p--main__text--img__w--396{
  .p--main__read{
    width: calc(100% - 426px);
    margin: -5px 0 0 30px!important;
    font-size: 1.125rem!important;
    line-height: 1.8;
  }
}
@media screen and (max-width: 767px) {
  .p--recruitList{
    &__heading{
      padding: 5px 12px;
      font-size: .8125rem;
      font-weight: 700;
      &--bgGy{
        margin: 10px 0 0;
        padding: 3px 15px;
        font-size: .75rem;
      }
    }
    &__secondaryHeading{
      margin: 10px 0 0;
      font-size: .75rem;
    }
    &__body{
      .p--recruitList{
        display: block;
        &__item{
          width: 100%;
          ul li{
            width: 50%;
          }
          + .p--recruitList__item{
            margin: 15px 0 0;
          }
        }
      }
      &--bd{
        padding: 10px 8px!important;
      }
    }
    &--bd{
      padding: 5px 10px;
      .p--recruitList__item{
        margin: 2px 0 0!important;
      }
    }
  }
  .p--main__text--img__w--396{
    .p--main__read{
      width: 100%!important;
      margin: 10px 0 0!important;
      padding: 0!important;
      font-size: .875rem!important;
      font-weight: normal!important;
      line-height: 1.75;
    }
  }
  .p--body__img--396__240{
    width: 100%;
    max-width: 330px;
    img{
      height: calc((100vw - 40px)/33*20);
      max-height: 200px;
    }
  }
}

// 背景グレーのラジオボタンエリア
.p--search__radioList--bg__gl{
  text-align: center;
  .p--radioList__inner{
    display: inline-block;
    background: #f5f5f5;
    padding: 4px 20px 4px 20px;
    margin: 5px 0 20px;
    .c--control{
      border: none;
      width: auto;
      margin: 0 0 0 25px;
    }
  }
}
@media screen and (max-width: 767px){
  .p--search__radioList--bg__gl{
    .p--radioList__inner{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 40px 35px;
      padding: 10px 20px 3px 20px;
      > span{
        display: block;
        width: 100%;
      }
      .c--control{
        margin: 0;
        padding: 13px 0 13px 27px;
        .c--control__indicator{
          left: 0;
        }
      }
    }
  }
}



// インターンシップ用シンプルな見た目
// ログイン前
.p--tabBody__main--no__login{
  .c--tgl__body{
    position: relative;
  }
}
.p--continuation_block{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .p--continuation_txt{
    font-size: 13px;
    font-weight: bold;
    text-align: center;
  }
  .p--continuation_links{
    display: flex;
    margin: 12px 0 0;
    .c--btn{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 22px;
      font-size: 16px;
      span{
        background: #fff;
        border-radius: 100vh;
        color: #40c4ff;
        display: inline-block;
        font-size: 13px;
        line-height: 1;
        margin-left: 8px;
        padding: 6px 12px 7px;
        text-shadow: none;
      }
      + .c--btn{
        margin-left: 10px;
      }
    }
  }
  &--btm{
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
    margin: 35px 0 0;
    .p--continuation_links{
      justify-content: center;
    }
  }
}

@media screen and (max-width: 767px){
  .p--tabBody__main--no__login{
    .c--tgl__body{
      .c--body__txt{
        min-height: 190px;
      }
    }
  }
  .p--continuation_block{
    width: 90%;
    .p--continuation_links{
      display: block;
      margin-top: 17px;
      .c--btn{
        align-items: center;
        display: flex;
        height: 55px;
        justify-content: center;
        + .c--btn{
          margin: 10px 0 0;
        }
      }
    }
    &--btm{
      margin: 35px auto 15px;
      .p--continuation_links{
        padding: 0 21px;
      }
    }
  }
}


/** ========================================
 * @p--slideTab
 * タブ切り替え見た目変更 20240618
======================================== */
@media screen and (max-width: 767px){
  .p--slideTabHead{
    display: block;
    padding: 0!important;
    background: $clr-w;
  }
  .p--slideTabHead .p--slideTabHead__item{
    margin: 0;
    border-top: 1px solid #dadadb;
    &:last-of-type{
      border-bottom: 1px solid #dadadb;
    }
  }

  .p--slideTabHead .p--slideTabHead__item.p--tabSelect{
    border-top: 1px solid #dadadb;
    color: $clr-b;
    font-weight: normal;
  }
  .p--slideTabHead .p--slideTabHead__item p{
    position: relative;
    display: flex;
    flex-direction: row!important;
    justify-content: flex-start!important;
    width: 100%;
    padding: 14px 20px!important;
  }
  .p--slideTabHead .p--slideTabHead__item p::after{
    content: "";
    position: absolute;
    top: 48%;
    right: 17px;
    width: 7px;
    height: 7px;
    border: solid #e92d82;
    border-width: 2px 2px 0 0;
    z-index: 10;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
  }
  .p--slideTabHead .p--simpleTab__num{
    margin-left: 8px;
  }

  .p--slideTabBody{
    .p--tabBody__top{
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px 0 0;
    }
    .p--intern__ttl{
      min-height: 26px;
    }
    .tabBack{
      min-height: 46px;
      margin-top: 14px;
      padding: 0 20px;
      border-top: 1px solid #dadadb;
      .tabBack__btn{
        position: relative;
        display: block;
        padding: 11px 20px;
        font-size: remSize(16);
        &::before{
          content: "";
          position: absolute;
          top: 50%;
          left: 6px;
          width: 7px;
          height: 7px;
          border: solid #e92d82;
          border-width: 0 0 2px 2px;
          z-index: 10;
          transform: translateY(-50%) rotate(45deg);
        }
        .p--simpleTab__num{
          margin-left: 8px;
        }
      }
    }
    .c--main__map{
      margin-top: 0;
    }
  }

  .p--slideTabBody__item{
    z-index: 10000;
    background: $clr-w;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
    padding: 106px 0 0;
    transform: translateX(100%);
    transition: all .7s;
    &.is--show{
      pointer-events: all;
      transform: translateX(0);
    }
     > .p__check dt:first-of-type{
      margin-top: 0;
    }
    .p--tabBody__main{
      overflow-y: auto;
      height: calc(100vh - 106px);
    }
  }
}