/*! 
 * モーダル
 */


/**************************\
  Basic Modal Styles
\**************************/
/** ========================================
 * モーダル
 * ====================================== */
body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}


.c--modal{
  &__container{
    position: relative;
    // width: 90%;
    max-width: 1200px;
    box-sizing: border-box;

    min-width: 1200px;
  }

  &__content{
    position: relative;
    max-height: 80vh;
    overflow: hidden;
    margin:0 auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: rgba(0,0,0,.8);
    line-height:1.5;
  }
  &__close {
    position: absolute;
    right: -20px;
    top: -20px;
    padding: 0;
    border: 0;
    background: transparent;
    &:after {
      content: "";
      display: block;
      width: 40px;
      height: 40px;
      background: url(../images/icon_close.png) 50% no-repeat;
      background-size: 100%;
    }
  }
  &__main{
    margin: 0 0 40px;
  }
  &__overlay{
    z-index: 10005;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.75);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
}

@media screen and (min-width: 767px) and (max-width: 1220px) {
  .c--modal{
    &__container{
      position: absolute;
      left: 0;
    }
    &__overlay{
      > form{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .c--modal{
    &__container{
      min-width: auto;
      width: 95%;
      margin: 0 auto;
    }
    &__overlay{
      > form{
        width: 100%;
      }
    }
  }
}



/**************************\
  Demo Animation Style
\**************************/
.c--micromodal-slide {
  display: none;
}

.c--micromodal-slide.is-open {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

@media screen and (max-width: 767px) {
  .c--modal__close{
    right: -9px;
    top: -9px;
  }

}





/** ========================================
 * モーダル 追加
 * ====================================== */
.c--modal__title{
  color: $clr-p80;
  font-size: remSize(32);
  font-weight: bold;
  text-align: center;
}
.c--modal__btnArea{
  position: absolute;
  bottom: 0;
  border-top: 1px solid $clr-g40;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px;
  .c--btnArea__result{
    margin: 0 38px 0 0;
    font-size: remSize(16);
    span{
      display: inline-block;
      margin: 0 3px;
      font-size: remSize(24);
      font-weight: bold;
    }
  }
  .c--btn + .c--btn{
    margin: 0 0 0 8px;
  }
}
.c--modal__fixBtnBlock{
  padding: 0;
  .c--modal__mainArea{
    overflow-y: auto;
    max-height: calc(80vh - 135px);
    margin: 0 0 135px;
    padding: 30px 30px 0 30px;
  }
}



// インターン・説明会検索、エリアから探す 共通
.c--modal--area{
  .c--modal__mainArea{
    padding: 25px 48px 0!important;
  }
  .c--modal__main{
    margin: 34px 0 0;
  }
  .ps__rail-y{
    top: 0!important;
  }
  .ps__thumb-y,
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y{
    background-color: $clr-p80;
  }
}

// インターン・説明会検索のみ
.c--modal--details{
  // .c--modal__mainArea{
  //   padding: 25px 38px 0!important;
  // }
  .c--ttl__bg--dot{
    content: "";
    padding: 8px 16px;
    background: url(../images/bg-hdg.png) 50%;
    font-size: remSize(14);
    font-weight: 700;
    &:not(:first-child){
      margin: 24px 0 0;
    }
  }

  .c--modal__main{
    // display: flex;
    // flex-direction: row-reverse;
    // justify-content: space-between;
    margin: 34px 0 0;
    padding: 0;
    // .c--main__map{
    //   height: 649px;

    //   .c--area__item{
    //     &:nth-child(n+2){
    //       margin: 17px 0 0;
    //     }
    //     .c--item{
    //       &__heading{
    //         .c--control{
    //           padding: 10px 20px 10px 36px;
    //         }
    //       }
    //       &__body{
    //         li:nth-child(n+3){
    //           margin: 0;
    //         }
    //       }
    //     }
    //   }
    // }

    .c--main__date{
      width: 230px;
      margin: 0 0 0 20px;
      .c--date{
        &__checkList{
          margin: 16px 0 0;
          .c--checkList__item{
            border-bottom: 1px solid $clr-g40;
            &:first-child{
              border-top: 1px solid $clr-g40;
            }
            .c--control{
              padding: 10px 0 10px 34px;
              &__indicator{
                left: 8px;
              }
            }
          }
        }
        &__inputArea{
          margin: 17px 0 0;
          .c--input__item{
            &:not(:first-child){
              margin: 10px 0 0;
            }
            input{
              width: 150px;
              height: 30px;
              margin: 0 15px 0 0;
              border: 1px solid #a2a2a7;
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .c--modal__title{
    font-size: remSize(24);
  }
  // インターン・説明会検索、エリアから探す 共通
  .c--modal--area{
    .ps__rail-y{
      top: 41px!important;
    }
    .c--modal__content{
      max-height: 80vh;
    }
    .c--modal__mainArea{
      max-height: unset;
      height: 100%;
      margin: 0;
      padding: 5px 10px 0!important;
    }
    .c--modal__title{
      height: 36px;
    }
    .c--modal__main{
      max-height: calc(80vh - 122px);
      margin: 0 0 81px;
      overflow: hidden;
    }

    .c--modal__btnArea{
      height: 81px;
      padding: 0 10px;
      border-top: none;
      .c--btnArea__result{
        margin: 0;
        padding: 0 10px 0 0;
        font-size: remSize(12);
        text-align: center;
        span{
          font-size: remSize(20);
          line-height: 1;
        }
      }
      .c--btn--xl{
        width: 179px;
      }
      .c--btn--l{
        width: 75px;
        margin: 0 0 0 8px;
        padding: 14px 12px;
        font-size: remSize(14);
      }
    }
  }

  // インターン・説明会検索のみ
  .c--modal--details{
    .c--ttl__bg--dot{
      margin: 10px 0 0!important;
    }
    .c--modal__main{
      flex-direction: column;
      .c--main__map{
        width: 100%;
        height: auto;
        margin: 10px 0 0;
        + .c--main__map{
          margin: 0;
        }
        .c--area__item{
          &:nth-child(n+2){
            margin: 0!important;
          }
          .c--control__indicator{
            width: 16px;
            height: 16px;
          }
          .c--item{
            &__heading{
              font-size: remSize(14);
              .c--control{
                padding: 0 0 0 26px;
              }
            }
            &__body{
              display: block;
              font-size: remSize(13);
            }
          }
        }
      }

      .c--main__date{
        width: 100%;
        margin: 0;
        .c--date{
          &__checkList{
            margin: 10px 0 0;
            .c--checkList__item{
              padding: 11px 20px;
              .c--control{
                padding: 0 0 0 26px;
                &__indicator{
                  left: 0;
                }
              }
            }
          }
          &__inputArea{
            margin: 10px 0 0;
            padding: 0 0 0 20px;
            .c--input__item{
              width: 100%;
              &:not(:first-child){
                margin: 10px 0 0;
              }
              input{
                width: 70%;
                height: 30px;
                margin: 0 10px 0 0;
              }
            }
          }
        }
      }
    }
  }
  .c--modal--terms{
    .c--modal__content{
      padding: 10px;
      .c--modal__mainArea{
        overflow-y: auto;
        max-height: calc(80vh - 20px);
      }
    }
  }
}


@media screen and (max-width: 320px) {
  .c--modal--area .c--modal__btnArea .c--btnArea__result{
    font-size: remSize(9);
    span{
      font-size: 1rem;
    }
  }
  .c--modal--area .c--modal__btnArea .c--btn--xl{
    width: 160px;
    padding: 20px 0px;
  }
}

// 通常スクロール対応
.c--modal__mainArea--defaultScrl{
  max-height: calc(80vh - 40px);
  overflow-y: auto;
}