/*! 
 * EDIT - スカウト条件追加登録
 */

/** ========================================
 * @p--edit
 * ====================================== */

.p--edit {
  .c--contents:last-child {
    padding-bottom: 120px;
  }

  .c--contents__inner {
    width: 896px;
  }//.c--contents__inner

  .c--error__list {
    margin-top: 30px;
  }

  &__caption{
    margin-bottom: 24px;
    text-align: left;
  }

  &__option {
    padding: 24px 24px 40px;
    //tableの上書き
    caption {
      margin-bottom: 24px;
      text-align: left;
    }
    th {
      width: 167px;
      vertical-align: top;
    }
    .c--primaryTable + .c--primaryTable {
      margin-top: 30px;
    }
    .c--primaryTable td.p--option__strong {
      padding: 0;
    }
    //働き方の二段目
    .p--strong__heading {
      position: relative;
      display: block;
      padding: 8px 20px;
      background: #FAFAFA;
      color: #525252;
      font-size: .75rem;
      font-weight: normal;
      &:hover{
        cursor: pointer;
      }
      &:before{
        margin: 0 5px 0 0;
        content: "▼";
      }
      &.p--tgl--open{
        &:before{
          content: "▲";
        }
      }
      &.c--no__pointer{
        &:before{
          display: none;
        }
      }
    }
    .p--strong__wrap {
      padding: 10px 20px;
    }
    //PRのtextarea
    .p--pr__txtarea {
      width: 100%;
      height: 12rem;
    }
    //PRの下のカウント
    .p--pr__count {
      display: flex;
      justify-content: space-between;
    }
    .p--edit__passForget{
      margin: 30px 0 0;
      .c--arrow--circle{
        margin: 10px 0 0;
      }
    }

  }//.p--edit__option

  &__infoTxt{
    + .c--tableHeading{
      margin: 20px 0 0;
    }
  }

  &RegistBtn--col3{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin: 40px 0 0;
    > .c--btn{
      &:nth-child(1){
        width: 155px;
      }
      &:nth-child(2){
        width: 300px;
      }
      + .c--btn{
        margin: 0 0 0 8px;
      }
    }
    .p--editRegistBtn--WithdrawArea{
      margin: 0 0 0 30px;
    }
  }

}

//メリット部分
.p--merit {
  display: flex;
  &__item {
    width: calc((100% - 20px) / 3);
    padding: 8px;
    background: #FAFAFA;
    &:not(:first-child) {
      margin-left: 10px;
    }
    figure {
      display: flex;
      margin-top: 8px;
      img {
        width: 100px;
        height: 100px;
      }
      figcaption {
        width: calc(100% - 100px);
        padding: 0 20px;
        p{
          margin: 0 0 10px;
          font-weight: bold;
        }
      }
    }
  }
}//.p--merit


@media screen and (max-width: 767px){
  .p--edit {
    .c--contents:last-child {
      padding-bottom: 0;
    }
  
    .c--contents__inner {
      width: 100%;
    }//.c--contents__inner

    &__lead {
      display: none;
    }

    .c--error__list {
      margin: 5.3vw 5.3vw 0;
    }

    &__infoTxt{
      padding: 0 25px;
    }

    &__caption{
      margin: 0 5.3vw 4.2vw;
    }

    &__option {
      margin-top: 5.3vw;
      padding: 4.2vw 0 8vw;
      //tableの上書き
      caption {
        margin: 0 5.3vw 4.2vw;
      }

      th {
        width: 100%;
        vertical-align: middle;
      }
      .c--primaryTable + p:not(.c--tableHeading){
        padding: 0 25px;
      }

    }//.p--edit__option
  
    .p--edit__passForget{
      padding: 0 25px;
    }

    &RegistBtn--col3{
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 25px;
      > .c--btn{
        &:nth-child(1){
          width: 100px;
        }
        &:nth-child(2){
          width: calc(100% - 108px);
        }
        + .c--btn{
          margin: 0 0 0 8px;
        }
      }
      .p--editRegistBtn--WithdrawArea{
        width: 100%;
        margin: 20px 0 0;
        .c--btn{
          width: 100%;
          margin: 10px 0 0;
        }
      }
    }

  }

  //メリット部分
  .p--merit {
    display: flex;
    &__item {
      figure {
        img {
          display: none;
        }
        figcaption {
          width: 100%;
          padding: 0;
          font-size: remSize(12);
        }
      }
    }
  }//.p--merit
  
}

