/*!
 * ナビ
 */

/** ========================================
 * @GLOBAL NAVI
 * グロナビ
 * ====================================== */
/** --------------------
 * 
 * ------------------ */
.c--gnavi{
  display:flex;
  &__main{
    display:flex;
    align-items: flex-end;
    height: 100%;
    margin: 0 26px 0 0;
    .c--main__item{
      display: inline-block;
      padding: 16px 0;
      &:hover{
        padding: 14px 0;
        color: $clr-p80;
        border-bottom: 2px solid $clr-p80;
        cursor: pointer;
      }
      &.c--arrow{
        &:hover{
          &:before{
            top: calc(48% - 1px);
          }
        }
      }
      .c--item__btn{
        display: inline-block;
        padding: 0 36px 0 24px;
        border-left: 1px solid $clr-g40;
        &:before{
          right: 23px;
        }
        &--noChild{
          padding: 0 30px;
        }
      }
    }
  }

  &__child{
    position: absolute;
    top: 103px;
    left: 0;
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background: $clr-w;
    box-shadow: inset 2px 10px 8px -8px rgba(97,97,106,.1), inset 0 11px 10px -10px rgba(97,97,106,.1), 2px 10px 8px -8px rgba(97,97,106,.01), 1px 11px 10px -10px rgba(97,97,106,.1);
    z-index: 1001;
    &--imgOnly{
      background: $clr-w;
    }
    &--linkOnly{
      background: $clr-g10;
    }
    .c--child__inner{
      display: flex;
      justify-content: center;
      width: 1248px;
      min-height: 217px;
      margin: 0 auto;
    }
    .c--arrow--circle{
      &:hover{
        color: $clr-p80;
        i{
          border-bottom: 1px solid $clr-p80;
        }
      }
    }
  }

  .c--child__item{
    width: 25%;
    padding: 24px 0 20px 24px;
    &--img{
      .c--item__img{
        margin: 0 30px 0 0;
        width: 242px;
        img{
          height: 140px;
        }
      }
      .c--arrow--circle{
        margin: 10px 0 0;
      }
      a{
        display: inline-block;
        width: 100%;
        height: 100%;
        font-weight: bold;
      }
    }
    &--link{
      p{
        line-height: 1;
        color: $clr-p80;
        font-weight: bold;
        + ul{
          margin: 10px 0 0;
        }
      }
      ul{
        margin: 18px 0 0;
        li{
          &:not(:first-child){
            margin: 16px 0 0;
          }
          .c--arrow--circle:before{
            transform: none;
            top: .2rem;
          }
        }
      }
    }
  }




  &__sub{
    display: flex;
    a{
      display: inline-block;
      width: 82px;
      padding: 25px 0 20px;
      color: $clr-w;
      text-align: center;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
      &:before{
        background-size: contain;
        content: "";
        display: block;
        margin: 0 auto 13px;
        width: 24px;
      }
    }
  }
  .c--sub__item{
    &--regist{
      a{
        background: $clr-lb60;
        &:before{
          height: 24px;
          background: url(../images/header_icon_regist.png) 50% no-repeat;
          background-size: 17px;
        }
        &:hover{
          background: $clr-lb80;
        }
      }
    }
    &--login{
      margin: 0 0 0 1px;
      a{
        background: $clr-p60;
        &:before{
          height: 24px;
          background: url(../images/header_icon_login.png) 50% no-repeat;
          background-size: 20px;
        }
        &:hover{
          background: $clr-p80;
        }
      }
    }
    &--spBtn{
      display: none;
    }

  }



  // ログインヘッダー
  &--login{
    align-self: stretch;
    .c--gnavi__main{
      margin: 0;
      .c--main__item{
        .c--item__btn{
          border-right: 1px solid #dadadb;
          border-left: none;
        }
        a span:last-child{
          border-right: 1px solid #dadadb;
        }
        &:first-child .c--item__btn{
          border-left: 1px solid #dadadb;
        }
      }
    }
    .c--menu__mainInner{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
    }
  }
}

// ログインヘッダー
.c--login{
  display: flex;
  align-items: center;
  margin: 0 12px 0 0;
  a:hover{
    color: $clr-p80;
  }
  &__list{
    display: flex;
    border: 1px solid;
    border-color: transparent $clr-g40 $clr-g40 $clr-g40;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &__item{
    &:not(:first-child){
      border-left: 1px solid $clr-g40;
    }
  }
  .c--item{
    font-size: remSize(12);
    display: flex;
    align-items: center;
    padding: 6px 14px;

    &__icon{
      span{
        display: flex;
        align-items: center;
        &:before{
          content: "";
          display: inline-block;
          width: 17px;
          height: 17px;
          margin: 0 7px 0 0;
          background-size: contain!important;
        }
      }
      &--msg span:before{
        background: url(../images/icon_msg.png) 50% no-repeat;
      }
      &--scout span:before{
        background: url(../images/icon_scout.png) 50% no-repeat;
      }
      &--fav span:before{
        background: url(../images/icon_fav.png) 50% no-repeat;
      }
    }

  }
}
.c--login__user{
  padding: 8px 14px;
  font-size: .75rem;
}





@media screen and (max-width: 767px){

  .c--gnavi{
    &__sub{
      a{
        width: 60px;
        padding: 10px 0 7px;
        border-left: 1px solid $clr-g40;
        font-size: .6rem;
        &:before{
          margin: 0 auto 4px;
        }
        &.c--menu__trigger{
          color: $clr-b;
        }
      }
    }
    .c--sub__item{
      &--login{
        margin: 0;
      }
      &--spBtn{
        position: relative;
        display: block;
      }
    }
    &--login{
      align-items: center;
      flex-direction: row;
      .c--gnavi__sub{
        height: 60px;
      }
    }
  
    &__main{
      display: block;
      width: 100%;
      margin: 0;
      .c--main__item{
        width: 100%;
        padding: 0;
        border-top: 1px solid $clr-g30;
        &:hover{
          padding: 0;
          border-bottom: none;
          color: $clr-b;
        }
        .c--item__btn{
          position: relative;
          width: 100%;
          padding: 14px 20px;
          border: none!important;
          &.c--childTgl--open:before{
            transform: translateY(-50%) rotate(-135deg);
          }
          &:not(.c--item__btn--noChild){
            &:after{
              content: "";
              position: absolute;
              top: 0;
              right: 50px;
              width: 1px;
              height: 100%;
              background: $clr-g40;
            }
          }
          &.c--gnavi__childTgl{
            &:before{
              transform: translateY(-50%) rotate(-45deg);
            }
          }
          &--noChild{
            position: relative;
            padding: 0;
            &:before{
              content: "";
              position: absolute;
              top: 48%;
              right: 23px;
              width: 7px;
              height: 7px;
              border: solid #e92d82;
              border-width: 0 2px 2px 0;
              z-index: 10;
              transform: translateY(-50%) rotate(-45deg);
            }
            a{
              display: inline-block;
              width: 100%;
              padding: 14px 20px;
            }
          }

        }
        &--btm{
          padding: 18px 22px;
          background: $clr-g20;
          &:hover{
            padding: 18px 22px;
          }
          > a{
            width: 100%;
            padding: 5px 25px;
          }
        }

      }
    }

    &__btn--sp{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px;
      background: $clr-g20;
    }

    .c--menu{
      &__main{
        display: none;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        border-top: 1px solid $clr-g40;
        background: $clr-w;
        box-shadow: 0 8px 3px -3px rgba(97,97,106,.1), 0 8px 3px -3px rgba(97,97,106,.1);
        z-index: 15;
        &.c--menu--open{
          display: block;
        }
      }
      &__trigger{
        height: 100%;
        display: inline-block;
        transition: all .4s;
        box-sizing: border-box;
        text-shadow: none;
        span{
          position: absolute;
          left: 29%;
          display: inline-block;
          width: 45%;
          height: 2px;
          box-sizing: border-box;
          background-color: $clr-p80;
          transition: all .4s;
          &:first-of-type{
            top: 13px;
          }
          &:nth-of-type(2){
            top: 22px;
          }
          &:nth-of-type(3){
            top: 30px;
          }
          &.c--menu__trigger--open,
          &.c--menu__trigger--close{
            top: 38px;
            left: 0;  
            width: 100%;
            height: auto;
            background: none;
          }
          &.c--menu__trigger--open{
            display: none;
          }
        }
    
        &.c--menu--open{
          span{
            &:nth-of-type(1) {
              top: 10px;
              transform: translateY(10px) rotate(-45deg);
            }
            &:nth-of-type(2) {
              top: 22px;
              opacity: 0;
              left: 50%;
              animation: active-menu .8s forwards;
            }
            &:nth-of-type(3) {
              transform: translateY(-10px) rotate(45deg);
            }
            &:nth-of-type(4) {
              display: none;
            }
            &:nth-of-type(5) {
              display: block;
              color: $clr-p80;
            }
          }
        }
      }
      @-webkit-keyframes active-menu {
        100% {
          height: 0;
        }
      }
      @keyframes active-menu {
        100% {
          height: 0;
        }
      }
    }

    // メニュー一覧
    &__child{
      position: relative;
      top: auto;
      left: auto;
      width: calc(100% - 40px);
      margin: 0 20px;
      border-top: 1px solid $clr-g30;
      background: $clr-w;
      box-shadow: unset;
      .c--child__inner{
        display: block;
        width: 100%;
        min-height: auto;
        padding: 7px 12px 17px;
      }
    }

    .c--child__item{
      width: 100%;
      padding: 0;
      &--img{
        width: 100%;
        margin: 10px 0 0;
        padding: 0;
        box-shadow: unset;
        background: transparent;
        a{
          font-weight: normal;
        }
        img{
          display: none;
        }
        .c--arrow--circle{
          margin: 0;
        }
        .c--item__img{
          width: 100%;
        }
      }
      &--link{
        box-shadow: unset;
        background: transparent;
        p{
          display: none;
          + ul{
            margin: 0;
          }
        }
        ul{
          margin: 0;
          li{
            margin: 10px 0 0!important;
          }
        }
      }
    }
  }


  .c--login{
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 20px 0;
    background: $clr-g20;
    .c--item{
      position: relative;
      display: block;
      padding: 22px 5px;
      text-align: center;
      &:before{
        content: "";
        position: absolute;
        top: 48%;
        width: 7px;
        height: 7px;
        border: solid #e92d82;
        border-width: 0 2px 2px 0;
        z-index: 10;
        top: auto;
        right: 10px;
        bottom: 4px;
        transform: translateY(-50%) rotate(-45deg);
      }
      &__icon{
        span{
          position: relative;
          display: inline-block;
          &:before{
            display: block;
            width: 25px;
            height: 25px;
            margin: 0 auto;
          }
        }
        &--msg span:before {
          background: url(../images/icon_msg_sp.png) 50% no-repeat;
        }
        &--scout span:before {
          background: url(../images/icon_scout_sp.png) 50% no-repeat;
        }
        &--fav span:before {
          background: url(../images/icon_fav_sp.png) 50% no-repeat;
        }
      }
      .c--notice__num{
        position: absolute;
        top: -8px;
        right: -19px;
        border: 1px solid #fff;
      }
    }
    &__list{
      width: 100%;
      background: $clr-w;
      border-top-color: $clr-g40;
      .c--login__item{
        flex: 1 1 calc(100% / 3);
        // flex-basis: calc(100% / 3);
        // width: calc(100% / 3);
      }
    }
  }
  .c--gnavi--login .c--gnavi__main .c--main__item{
    &:first-child .c--item__btn{
      border: none;
    }
  }

}

@media screen and (max-width: 320px){
  .c--gnavi__sub a{
    width: 50px;
  }
}