/*! 
 * パンくず
 */
/** ========================================
 * @
 * パンくず
 * ====================================== */
.c--breadcrumbs{
  background: $clr-g10;
  padding: 3px 0;
  &__inner{
    width: 1200px;
    margin: 0 auto;
  }
  li{
    display: inline-block;
    font-size: remSize(12);
    + li{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        top: 55%;
        left: 5px;
        width: 6px;
        height: 6px;
        border: solid $clr-g70;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &:not(:first-child){
      padding: 0 0 0 20px;
    }
    a{
      display: inline-block;
      color: $clr-p80;
      text-decoration: underline;
    }
  }
}



@media screen and (max-width: 767px){
  .c--breadcrumbs{
    padding: 0;
    overflow: hidden;
    &__inner{
      width: 100%;
      box-shadow: 0 8px 3px -3px rgba(97,97,106,.1) inset;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;
    }
    li{
      padding: 0!important;
      > a,
      > span{
        padding: 7px 14px!important;
      }
      > a{
        color: $clr-g50;
        text-decoration: none;
      }
      &.c--current{
        color: $clr-g40;
      }
      + li{
        &:before{
          width: 30px;
          height: 30px;
          left: -30px;
          top: 50%;
          border-color: $clr-g40;
          border-width: 0 1px 1px 0;
        }
      }
    }
    .c--home{
      position: relative;
      z-index: 11;
      &:before{
        content: "";
        display: inline-block;
        width: 10px;
        height: 12px;
        background: url(../images/icon_home.png) 50% no-repeat;
        background-size: contain;
      }
      i{
        display: none;
      }
    }

  }
}