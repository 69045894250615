/*! 
 * ログイン関連
 */

 /** ========================================
 * @p--inpt
 * 会員情報登録関連
======================================== */
.p--inpt{
  /*全体*/
  &__area{
    width:900px;
    margin:0 auto;
    .c--error__list {
      margin-top: 30px;
    }
  }
  &__box{
    width:900px;
    margin:30px auto 0;
  }
  &__btn{
    width:300px;
  }

}
@media screen and (max-width: 767px){
  .p--inpt{
    &__area{
      width:100%;
      // padding:0 20px;
      &.p--edit__merit{
        padding: 0;
      }
      .c--error__list {
        &:not(.c--error__list--box){
          padding: 0 25px;
        }
      }
    }
    &__box{
      width:100%;
      .p--ruleBox--sp{
        padding: 0 25px;
        .c--control{
          margin: 20px 0;
        }
      }
    }
    &__btn{
      width: calc(100% - 100px );
    }

  }
}




/** ========================================
 * @p--prefTbl
 * 希望勤務地エリア
 * ====================================== */
.p--prefTbl{
  display: flex;
  flex-wrap: wrap;
  border-width: 1px;
  border-style: solid;
  border-color: transparent #dadadb #dadadb #dadadb;

  &--th{
    background: #fff0f5;
    padding: 4px 15px;
    font-size: remSize(13);
    border-top: 1px solid #dadadb;
  }
  &__heading{
    width: 140px;
    &:not(.p--prefTbl--th){
      padding: 5px 10px;
      border-top: 1px solid #dadadb;
      font-size: remSize(13);
    }
  }
  &__body{
    width: calc(100% - 140px);
    border-left: 1px solid #dadadb;
    &:not(.p--prefTbl--th){
      padding: 5px 10px;
      border-top: 1px solid #dadadb;
      font-size: remSize(13);
    }
  }

  .c--control__indicator{
    height: 16px;
    width: 16px;
  }
}



@media screen and (max-width: 767px){
  .p--prefTbl__area{
    padding: 0!important;
  }
  .p--prefTbl{
    display: block;
    border-width: 0 0 1px 0;
    &--th{
      display: none;
      + .p--prefTbl__heading{
        border-top: none;
      }
    }

    &__heading{
      position: relative;
      width: 100%;
      &:before{
        content: "";
        display: inline-block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 41px;
        background: #dadadb;
      }
      &:not(.p--prefTbl--th){
        padding: 7px 10px 7px 22px;
        font-size: remSize(14);
      }
    }

    &__body{
      width: 100%;
      border-left: none;
      &:not(.p--prefTbl--th){
        padding: 9px 0 9px 46px;
        font-size: remSize(14);
      }
      & .c--control{
        width: calc(100% - 15px);
        + .c--control{
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #dadadb;
          .c--control__indicator{
            top: calc(50% + 5px);
          }
        }
      }
    }
  }
}


/*20200406*/
.p--inpt__lead {
  li {
    padding-left: 1em;
    text-indent: -1em;
    &::before {
      content: "・";
    }
  }
}

@media screen and (max-width: 767px) {
  .p--inpt__lead {
    padding: 0 22px;
  }
}

.p--inpt__mailadd{
  width:460px;
}
.p--inpt__pass{
  width:400px;
}
@media screen and (max-width: 767px) {
  .p--inpt__mailadd{
    width:100%;
  }
  .p--inpt__pass{
    width:100%;
  }
}