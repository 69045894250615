/*! ============================================================
 * File        : Layout/_base.scss
 * DESCRIPTION : 基本設定
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */

*,
* ::before,
* ::after {
  box-sizing: border-box;
//   word-break: keep-all;
//   line-break: strict;
//   word-wrap: break-word;
//   overflow-wrap: break-word;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
body ,textarea, input, button {
  color: $clr-b;
  font-size: remSize($base-fontSize);
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  font-family: "Noto Sans JP", "メイリオ",Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

/** --------------------
 * フォント
 * ------------------ */
.l--defFont{
  font-family: "Noto Sans JP", "メイリオ",Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.l--numFont{
  font-family: "Roboto", "Arial" ,sans-serif;
}
 

p{
  word-break: normal !important;
  word-wrap: break-word;
}
img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: $clr-b;
  text-decoration: none;
}

.width__block {
    @include width_block;
}

@media screen and (max-width:768px) {
}
