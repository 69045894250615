/*! 
 * DRWL - 会員退会
 */

/** ========================================
 * @p--drwl
 * ====================================== */
@mixin drwlbox {
  width: 896px;
  margin: 0 auto;
}

.p--drwl {

  .c--contents:last-child {
    padding-bottom: 120px;
  }

  &__lead {
    @include drwlbox;
    margin-top: 40px;
  }
  &__box {
    @include drwlbox;
    padding: 24px 24px 40px;
    border-radius: 4px;
  }
  .p--box__upper {
    padding: 24px;
    .p--drwl__infoTxtBox{
      padding: 17px 14px;
      p:not(:first-child){
        margin: 20px 0 0;
      }
      .p--drwl__infoTtl{
        margin: 22px 0 0;
        font-weight: bold;
        + p{
          margin: 0;
        }
      }
    }
  }
  .c--select {
    select {
      border-color: #DADADB;
    }
  }
  .p--box__reason {
    display: flex;
    align-items: center;
    margin-top: 20px;
    border-top: 1px solid #DADADB;
    border-bottom: 1px solid #DADADB;
  }
  .p--reason__heading {
    flex: 0 0 30%;
    padding: 27px 18px;
    background: #fff0f5;
  }
  .p--reason__body {
    flex: 1 1 70%;
    // padding: 27px 18px;
    padding: 0 18px;
  }

}





@media screen and (max-width: 767px){
  @mixin drwlbox-sp {
    width: auto;
  }
  .p--drwl {
    .c--contents:last-child {
      padding-bottom: 0;
    }
    .c--contents__flow {
      margin-bottom: 0;
    }
    &__lead {
      @include drwlbox-sp;
      margin: 3.5vw 5.3vw 0;
    }
    &__box {
      @include drwlbox-sp;
      padding: 2.6vw 0 3.2vw;
    }
    .p--box__upper {
      padding: 3.7vw;
    }
    .p--box__bottom {
      padding: 0;
    }
    
    .p--reason__heading {
      width:100%;
    padding: 18px;
    }
    .p--reason__body {
      padding: 10px;
      width:100%;
    }
  }
  .p--box__reason {
    flex-direction: column;
  }

}

