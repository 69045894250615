/*! ============================================================
 * File        : Foundation/_reset.scss
 * DESCRIPTION : スタイルの初期化
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
     License: none (public domain)
     update 2016 koizumi
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-style: normal;
  vertical-align: baseline;
  /*aタグとかをタップしたときに色が変わるのを防ぐ*/
  -webkit-tap-highlight-color: rgba(253, 153, 153, 0);
  /*回転したときなど文字の大きさを調整*/
  -webkit-text-size-adjust : 100% ;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}
em {
  font-weight: bold;
}
input,textarea,button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input:focus,textarea:focus,button:focus {
  outline: 0;
}


