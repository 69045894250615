/*! 
 * お気に入り
 */

 /** ========================================
 * @p--mypage
 * マイページエリア
 * ====================================== */
// TOP
.p--fav__top{
  padding: 25px 0 60px;
}
// おすすめコンテンツ
.p--fav__rmd{
  padding: 55px 0 60px;
  background: $clr-w;
  .c--card{
    margin: 40px 0 0;
  }
}
// あなたにおすすめの病院
.p--fav__hospital{
  padding: 55px 0 115px;
  .c--card{
    margin: 40px 0 0;
  }
  .c--hoverShadow__item{
    background: $clr-p10;
  }
}

.p--show__btn{
  &:hover{
    cursor: pointer;
  }
  &--close,
  &--open{
    position: relative;
    height: 30px;
    &:before,
    &:before{
      content: "";
      position: absolute;
      top: 48%;
      width: 12px;
      height: 12px;
      border: solid #e92d82;
      border-width: 0 2px 2px 0;
      z-index: 10;
      left: 50%;
    }
  }
  &--close{
    &:before{
      transform: translateY(-50%) translateX(-50%) rotate(45deg);
    }
  }
  &--open{
    &:before{
      margin: 5px 0 0;
      transform: translateY(-50%) translateX(-50%) rotate(-135deg);
    }
  }

}
.p--show__block{
  overflow: hidden;
}


// メイン
// 新着のお知らせ
.p--infoBlock{
  box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
}





/** ========================================
 * @p--reg
 * 登録情報一覧エリア
 * ====================================== */

// 件数・ボタン
.p--reg{
  margin: 40px 0 0;
  &__top{
    display: flex;
    justify-content: space-between;
    .p--top__num{
      font-size: remSize(16);
      font-weight: bold;
      i{
        color: $clr-p80;
        font-size: remSize(24);
      }
    }
    .p--top__btn{
      display: flex;
      align-items: center;
      .c--btn{
        height: 38px;
        padding: 7px;
        font-size: remSize(13);
        vertical-align: middle;
      }
      .c--select__tcArea{
        width: 230px;
        margin: 0 0 0 8px;
      }
    }
  }

  // リスト
  &__block{
    margin: 25px 0 0;
  }
  &__item{
    box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
    &:not(:first-of-type){
      margin: 33px 0 0;
    }
  }
  &__head{
    position: relative;
    padding: 12px 55px 14px 25px;
    border-top: 2px solid $clr-p80;
    border-bottom: 1px solid $clr-g40;
    background: $clr-w;
    font-size: remSize(20);
    font-weight: bold;
    &:hover{
      cursor: pointer;
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 54px;
      display: inline-block;
      width: 1px;
      height: 100%;
      background: $clr-g40;
    }
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      width: 12px;
      height: 12px;
      border: solid $clr-p80;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg);
      right: 20px;
    }
    &.p--tgl--open:after{
      transform: translateY(-50%) rotate(-135deg);
    }
    .c--ovalTag{
      width: auto;
      padding: 0 10px;
    }
    &--finish{
      background: $clr-g40;
      border-bottom-color: $clr-g50;
      &:before{
        background: $clr-g50;
      }
      + .p--reg__body{
        background: $clr-g40;
        .p--body__btm{
          .p--favAnime{
            background: $clr-g40;
            border: 1px solid $clr-g50;
          }
        }
      }
    }
  }
  &__body{
    // margin: -5px 0 0;
    padding: 21px 24px 16px 24px;
    background: $clr-w;
    &--open{
      display: block;
    }
    .p--body__list{
      display: flex;
      .p--list__item{
        width: calc(25% - 10px);
        padding: 4px 4px 8px;
        background: $clr-g10;
        text-align: center;
        &:not(:first-child){
          margin: 0 0 0 10px;
        }
        &--on{
          background: $clr-lb10;
        }
        .p--item__heading{
          padding: 5px;
          background: $clr-w;
        }
        .c--btn{
          width: calc(100% - 4px);
          padding: 11px 5px;
          &--disabled{
            border: 2px solid $clr-g40;
            background: $clr-g40;
            color: $clr-g70;
          }
        }
        .p--item__icon{
          position: relative;
          width: 36px;
          height: 36px;
          margin: 16px auto;
          &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: 100%;
            background-size: contain!important;
            background-repeat: no-repeat;
          }
          &.p--item__icon--on:before{
            background-image: url(../images/icon_mypage.png);
          }
          &.p--item__icon--on__event:before{
            background-image: url(../images/icon_mypage_event.png);
          }
          &.p--item__icon--off:before{
            background-image: url(../images/icon_mypage_gray.png);
          }
          &.p--item__icon--off__event:before{
            background-image: url(../images/icon_mypage_gray_event.png);
          }
          &.p--item__icon--noSelect:before{
            background: $clr-g40;
            height: 1px;
          }
        }
      }
    }
    
    .p--body__btm{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 13px 0 0;
      font-size: remSize(12);
      .p--btm__date{
        display: flex;
        p:not(:first-child){
          margin: 0 0 0 20px;
        }
        a{
          color: $clr-p80;
          text-decoration: underline;
        }
      }
      .p--fav__btn{
        position: relative;
        padding: 11px 9px 11px 30px;
      }
    
    }
    &--nopd{
      padding: 0 0 16px;
      .p--body__btm{
        padding-right: 24px;
        padding-left: 24px;
      }
    }
    .p--reg__txt__bgGy{
      background: #fafafa;
      padding: 10px;
      font-size: remSize(12);
    }
  }

}



@media screen and (max-width: 767px){
  // TOP
  .p--fav__top{
    padding-bottom: 30px;
  }
  // あなたにおすすめの病院
  .p--fav__hospital{
    padding: 30px 0 55px;
    background: $clr-w;
    .c--card{
      margin-top: 20px;
    }
    .c--hoverShadow__item{
      background: $clr-w;
    }
  }


  /** ========================================
   * @p--reg
   * 登録情報一覧エリア
   * ====================================== */

  // 件数・ボタン
  .p--reg{
    margin: 10px 0 0;
    &__top{
      display: block;
      justify-content: unset;
      // flex-direction: column;
      padding: 0 20px;
      .p--top__num{
        font-size: remSize(14);
        i{
          font-size: remSize(20);
        }
      }
      .p--top__btn{
        display: block;
        align-items: unset;
        margin: 10px 0 0;
        .c--btn{
          width: calc(50% - 6px);
        }
        .c--select__tcArea{
          width: calc(50% - 6px);
          height: 38px;
          vertical-align: middle;
          .c--tc__label{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .53rem;
          }
        }
      }
    }
    // リスト
    &__head{
      padding: 15px 55px 15px 21px;
      font-size: remSize(16);
      .c--ovalTag{
        width: auto;
        margin: 0;
        padding: 5px;
        font-size: .625rem;
        font-weight: 400;
        line-height: 1;
      }
    }
    &__body{
      margin: 0;
      padding: 0;
      > P{
        padding: 10px 20px 0;
      }
      .p--body__list{
        .p--list__item{
          width: calc(25% - 1px);
          &:not(:first-child){
            margin: 0 0 0 1px;
          }
          .p--item__heading{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
          }
          .p--item__icon{
            margin: 11px auto;
          }
        }
      }

      .p--body__btm{
        margin: 0;
        padding: 10px 19px 15px 21px;
        .p--btm__date{
          display: block;
          font-size: remSize(10);
          p:not(:first-child){
            margin: 0;
          }
        }
        .p--fav__btn{
          width: 38px;
          height: 38px;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 429px){
  .p--reg__top{
    .p--top__btn{
      text-align: center;
      .c--select__tcArea{
        margin-left: 4px;
      }
      .c--btn,
      .c--select__tcArea{
        width: calc(48% - 6px);
      }
    }
  }
}

@media screen and (max-width: 320px){
  .p--reg__body{
    .p--body__list{
      .p--list__item{
        .p--item__heading{
          font-size: .7rem;
        }
      }
    }
  }
}

/** ========================================
 * @p--history
 * 活動履歴
 * ====================================== */
.p--history{
  .p--reg{
    margin-top: 15px;
    .p--top__narrowDown{
      display: flex;
      align-items: flex-start;
      li{
        &:not(:first-child){
          margin: 0 0 0 20px;
          padding: 0 0 0 20px;
          border-left: 1px solid $clr-g40;  
        }
        a{
          color: $clr-p80;
        }
      }
    }
  }
  .p--topBdBox{
    margin: 30px 0 0;
    &__heading{
      padding: 0;
      &.p--topBdBox__link{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 12px 21px 12px 26px;
        .c--arrow--circle:before{
          top: .3rem;
          left: 0;
          transform: none;
        }
        .p--moreMsg{
          display: block;
          margin: 0 0 0 20px;
          color: $clr-p80;
          font-size: remSize(14);
          font-weight: normal;
          line-height: 1;
        }
        .p--date{
          font-size: remSize(12);
          font-weight: normal;
        }
      }
    }
  }

  .p--contBox{
    padding: 20px 25px;
  }
}

.p--splitBox{
  margin: 25px 0 0;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(97,97,106,.3), 0 1px 10px rgba(97,97,106,.3);
  + .p--splitBox{
    margin-top: 40px;
  }
  + .p--topBdBox__infoTxt{
    margin: 32px 0 0;
    padding: 32px 0 0;
    border-top: 1px dotted $clr-g40;
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 24px;
    border-bottom: 1px solid #dbdbdc;
    .p--top__ttl{
      width: calc(100% - 165px);
      font-size: 18px;
      font-weight: bold;
      .c--ovalTag{
        margin-right: 10px;
      }
    }
    .c--btn{
      width: 155px;
      padding: 17px 22px;
    }
  }

  &__btm{
    padding: 15px 25px;
    opacity: 0;
    .p--btm__ttl{
      color: #e92d82;
      font-weight: bold;
    }
    .p--btm__txt{
      margin-top: 5px;
      line-height: 1.5;
      &.c--tgl__body--more{
        position: relative;
        overflow: hidden;
        height: 231px;
        padding-bottom: 45px;
        .c--moreBtn__block{
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 45px;
          background: #fff;
          text-align: center;
          .c--tgl__moreBtn{
            padding: 22px 0 0;
            cursor: pointer;
            span{
              position: relative;
              padding-right: 10px;
              font-size: 14px;
              font-weight: bold;
              &::before{
                content: "";
                position: absolute;
                top: 49%;
                right: 0;
                width: 7px;
                height: 7px;
                border: solid #e92d82;
                border-width: 0 2px 2px 0;
                z-index: 10;
                transform: translateY(-50%) rotate(45deg);
              }
            }
          }
        }
      }
    }
    &.c--tgl--open{
      .c--tgl__body--more{
        overflow: auto;
        height: auto;
        .c--moreBtn__block{
          .c--tgl__moreBtn{
            span{
              &::before{
                top: 69%;
                transform: translateY(-50%) rotate(225deg);
              }
            }
          }
        }
      }
    }
    .p--btm__btn{
      border-top: 1px dashed #dbdbdc;
      margin-top: 20px;
      padding: 27px 27px 10px;
      text-align: center;
      .c--btn{
        width: 300px;
        padding: 20px 0;
      }
    }
  }
}

.p--btnBox{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 25px;
  .c--btn{
    width: calc(50% - 5px);
    padding: 20px 0;
    font-size: 14px;
    + .c--btn{
      margin-left: 10px;
    }
  }
}
.p--headLpBox{
  &--border{
    border: 1px solid #E5E5E5;
  }
  &:not(:first-child){
    margin: 10px 0 0;
  }
  &--finished{
    .p--headLp__ttl{
      background: #E5E5E5;
      color: #808084;
    }
  }
}
.p--headLp__ttl{
  display: flex;
  justify-content: space-between;
  padding: 7px 13px;
  background: #ffeff4;
  color: $clr-p80;
  font-weight: bold;
  &--off{
    color: $clr-b;
    font-weight: normal;
  }
  &--disabled{
    border-color: #f0f0f0;
    background: #f0f0f0;
    color: #bebec1;
    pointer-events: none;
    &.c--arrow:before{
      border-color: #bebec1;
    }
  }
  .p--ttl__date{
    color: $clr-b;
    font-size: remSize(12);
    font-weight: normal;
  }
  .c--ovalTag{
    margin: 0 12px 0 0;
    font-weight: normal;
  }
  &.c--arrow--p__right{
    &::before{
      right: 10px;
    }
    p{
      width: 100%;
    }
  }
}
.p--ttl__date--heading{
  margin-top: 30px;
  text-align: right;
}
.p--headLp__body{
  padding: 10px 13px;
  font-size: remSize(12);
  .p--headLp__list{
    p{
      margin: 5px 0 0;
      span{
        display: inline-block;
        margin: 0 10px 0 0;
        color: #818187;
      }
    }
  }
  .p--headLp__steteBox{
    margin: 14px 0 0;
    .c--ovalTag{
      width: auto;
      padding: 2px 10px;
      font-size: remSize(10);
    }
  }
  .p--headLp__btnBox{
    margin: 15px 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 767px){
  .p--history{
    .c--select__tcArea{
      width: 100%;
    }
    .p--contBox{
      padding: 10px 20px 15px;
    }
  }
  .p--headLp__ttl{
    display: block;
    .c--ovalTag{
      width: auto;
      min-width: 96px;
      padding: 1px 12px;
      font-size: remSize(10);
    }
    i{
      display: block;
      margin: 5px 0 0;
    }
    p + p{
      margin: 3px 0 0;
    }

  }
  .p--headLp__body{
    .p--headLp__steteBox{
      .c--btn{
        width: 100%;
        font-size: remSize(12);
      }
    }
    .p--headLp__btnBox{
      .c--btn{
        width: 100%;
      }
    }
  }
  .p--ttl__date--heading{
    margin-right: 1em;
  }

  .p--topBdBox__infoTxt{
    padding: 0 20px;
  }
  .p--splitBox{ 
    margin: 13px 20px 0;
    &:last-of-type{
      margin-bottom: 12px;
    }
    + .p--topBdBox__infoTxt{
      margin: 22px 0 0;
      padding: 22px 20px 0!important;
    }
    &__top{
      display: block;
      padding: 5px 15px 15px;
      .p--top__ttl{
        width: 100%;
        .p--ttl__item{
          display: block;
          font-size: 14px;
        }
        .c--ovalTag{
          width: 95px;
          font-size: 10px;
        }
      }
      .c--btn{
        width: 100%;
        margin-top: 15px;
        padding: 14px 22px;
        font-size: 12px;
      }
    }
  
    &__btm{
      padding: 15px;
      .p--btm__ttl{
        font-size: 12px;
      }
      .p--btm__txt{
        font-size: 13px;
        &.c--tgl__body--more{
          height: 140px;
        }
      }
      &.c--tgl--open{
        .c--tgl__body--more{
          height: auto;
        }
      }
      .p--btm__btn{
        margin-top: 10px;
        padding: 27px 0 10px;
        .c--btn{
          width: 100%;
          padding: 14px 0;
          font-size: 12px;
        }
      }
    }
  }


  .p--btnBox{
    align-items: unset;
    &:not(.p--btnBox--col2){
      .c--btn{
        width: 100%;
        padding: 13px 0;
      }
    }
    .c--btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 5px);
      padding: 5px 0;
      font-size: 12px;
      line-height: 1.3;
    }
  }

}


/** ========================================
 * @p--rsv
 * 活動履歴
 * ====================================== */
.p--rsv{
  .c--primary__heading + .p--topBdBox{
    margin: 35px 0 0;
  }
  .p--contBox__inner{
    width: 847px;
    margin: 0 auto;
  }
  .p--contBox{
    padding: 38px 20px;
  }
  .p--headLpBox{
    &:not(:first-child){
      margin: 25px 0 0;
    }
    + .p--topBdBox__infoTxt{
      margin: 32px 0 0;
      padding: 32px 0 0;
      border-top: 1px dotted $clr-g40;
    }
  }
  .p--headLp__body{
    padding: 15px 13px;
  }
}

@media screen and (max-width: 767px){
  .p--rsv{
    .p--contBox__inner{
      width: 100%;
    }
    .p--contBox{
      padding: 13px 0;
    }
    .p--topBdBox__infoTxt{
      padding: 0 20px;
    }
    .p--headLpBox{
      padding: 0 20px;
      &:not(:first-child){
        margin: 13px 0 0;
      }
      .p--headLp__ttl{
        padding: 10px 13px;
      }
      + .p--topBdBox__infoTxt{
        margin: 22px 0 0;
        padding: 22px 20px 0;
      }
    }
    .p--headLp__body{
      padding: 10px 0;
      font-size: remSize(14);
    }
  }
}

/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--rsv__detail{
  .p--headLp__body{
    padding: 15px 0;
    font-size: remSize(14);
  }
}

.p--rsvTable{
  width: 100%;
  height: 1px;
  margin: 20px 0 0;
  tr{
    border-bottom: 1px solid #DADADB;
  }
  th,td{
    height: 100%;
    padding: 10px 15px;
    font-size: remSize(13);
    vertical-align: middle;
    &:not(:first-child){
      border-left: 1px solid #DADADB;
    }
  }
  th{
    background: #F5F5F5;
    text-align: left;
  }
  .c--btn{
    width: 100%;
    + .c--btn{
      margin-top: 5px;
    }
  }
  .p--col2{
    padding: 0;
    > div{
      display: flex;
      align-items: center;
      height: 100%;
      span{
        display: inline-block;
        width: 50%;
        height: 100%;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        + span{
          border-left: 1px solid #DADADB;
        }
      }
    }
  }
}

.p--guideLink{
  margin: 12px 5px 0;
  text-align: right;
  a{
    color: $clr-p80;
    font-size: remSize(14);
  }
}
.p--icon__route{
  &:before{
    content: "";
    display: inline-block;
    width: 16px;
    height: 18px;
    background: url(../images/icon_train.png) no-repeat;
    margin: 0 3px 0 0;
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px){
  .p--tag__finish--sp{
    display: inline-block;
    padding: 3px 15px;
    border-color: $clr-g30;
    border-radius: 100px;
    background: $clr-g30;
    color: $clr-g50;
    font-size: remSize(10);
  }
  .p--rsvTable{
    height: auto;
    tr:nth-child(2){
      border-top: 1px solid $clr-g40;
    }
    th{
      display: none;
    }
    td{
      display: block;
      padding: 7px 0 0;
      &:not(:first-child){
        border: none;
      }
      &:last-child,
      &.p--sp__lastItem{
        padding-bottom: 15px;
      }
    }
    .c--btn{
      + .c--btn{
        margin-top: 10px;
      }
    }
    .p--col2{
      > div{
        display: flex;
        align-items: center;
        height: 100%;
        span{
          width: auto;
          margin: 5px 0 0;
          padding: 0 30px 0 0;
          + span{
            border-left: none;
          }
        }
      }
    }

  }
}


/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--qrArea{
  width: 140px;
  margin: 30px auto 0;
}
@media screen and (max-width: 767px){
  .p--qrArea{
    margin: 20px auto 0;
  }
}



/** ========================================
 * @p--checkArea
 * 「忘れずにこちらもチェック」エリア
 * ====================================== */
.p--checkArea{
  margin: 25px 0 0;
  padding: 13px 25px;
  .p--ttl{
    font-size: remSize(14);
  }
  .c--btn{
    min-width: auto;
    margin: 13px 0 0;
    padding: 10px 20px;
    font-size: .875rem;
    + p{
      margin: 7px 0 10px;
    }
  }
}




/** ========================================
 * お気に入りボタンアニメ
 * ====================================== */
.p--favAnime{
  position: relative;
  padding: 0 0 0 10px!important;
  &:before{
    display: none;
  }
  .p--favAnime__img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
    width: 122px;
    height: 20px;
  }
  &.c--btn{
    width: 147px;
    height: 40px;
    .p--favAnime__img {
      right: 12px;
    }
  }
}


.p--favAnime--img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px!important;
  height: 70px!important;
  padding: 0!important;
  &.c--sp__only{
    display: none;
  }
  span{
    width: 42px;
    height: 30px;
    background: url(../images/fav_anime_sp.png) no-repeat;
  }
  &.on span{
    background-position: -2348px 0;
  }
  &.off span{
    background-position: 0 0;
  }
  &.move{
    &.on,
    &.off{
      span{
        -webkit-transition: background 1s steps(55);
        transition: background 1s steps(55);
      }
    }
  }
}
@media screen and (max-width: 767px){
  .p--favAnime--img{
    &.c--sp__only{
      display: flex!important;
    }
    width: 44px!important;
    height: 44px!important;
  }
}


/** ========================================
 * ？アイコンリンク
 * ====================================== */
.p--question__link{
  position: relative;
  padding-right: 21px;
  &::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../images/icon_question-circle.png);
  }
}

/** ========================================
 * 登録口コミ確認モーダル
 * ====================================== */
.c--modal__post{
  .c--modal__container{
    max-width: 690px;
    min-width: 690px;
  }
  .c--post__top{
    position: relative;
    margin-bottom: 20px;
    &--ttl{
      font-size: 20px;
      font-weight: bold;
      text-align: center;
    }
    &--date{
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: remSize(11);
      span{
        font-size: remSize(10);
      }
    }
  }
  .c--post__detail{
    margin-bottom: 20px;
    th{
      background: #F2F2F2;
    }
  }
  .c--post__note{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px){
  .c--modal__post{
    .c--modal__container{
      max-width: 90%;
      min-width: 90%;
    }
    .c--post__top{
      &--date{
        position: relative;
        top: 0;
        right: 0;
        transform: translateY(0);
        text-align: right;
      }
    }
  }
}

.p--desc{
  &__block{
    padding-bottom: 40px;
    .p--reg__top + &{
      margin-top: 20px;
      padding-bottom: 0;
    }
    .p--msgInbox__body &{
      margin-top: 40px;
      padding-bottom: 0;
    }
  }
  &__txt + .p--desc__txt{
    margin-top: 20px;
  }
  &__img{
    margin: 40px 0 60px;
    text-align: center;
    img{
      width: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }
  &__btn{
    margin: 20px 0 0;
    text-align: center;
  }
  &__link{
    margin: 20px 0 0;
    text-align: center;
    .p--link__item{
      display: inline-block;
      text-align: left;
      .c--arrow--circle{
        &:not(:first-of-type){
          margin-top: 10px;
        }
      }
      
    }
  }
}

@media screen and (max-width: 767px){
  .p--desc{
    &__block{
      .p--msgSearch + .p--msgInbox__body &{
        .p--desc__img{
          margin-top: 33px;
        }
        + .p--msgInboxInfo{
          margin-top: 40px;
        }
      }
    }
    &__txt{
      padding: 0 20px;
    }
    &__img{
      margin: 20px 0 30px;
      padding: 0 20px;
    }
    &__btn{
      padding: 0 12px;
    }
  }
}


/** ========================================
 * @p--tblLink
 * お気に入り
 * ====================================== */
.p--tblLink{
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0;
  box-shadow: 2px 2px 2px rgba(97, 97, 106, .2), 1px 1px 5px rgba(97, 97, 106, .1);
  border-radius: 10px;
  overflow: hidden;
  &__item{
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    border-right: 1px solid #eee;
    &:nth-child(3n){
      border-right: 0;
    }
    &:nth-child(n + 4){
      border-top: 1px solid #eee;
    }
    a{
      display: block;
      padding: 20px 20px 16px;
      width: 100%;
    }
    .p--tblLink__img{
      position: relative;
      display: inline-block;
      margin: 0 0 10px;
      img{
        width: 32px;
      }
      span{
        position: absolute;
        top: -4px;
        right: -18px;
      }
    }
  }
}
@media screen and (max-width: 767px){
  .p--tblLink__wrap{
    padding: 0 20px;
  }
  .p--tblLink__item a{
    font-size: remSize(12);
  }
}


/** ========================================
 * @p--popup
 * ポップアップ
 * ====================================== */
.p--popup{
  z-index: 10005;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .75);
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: auto;
  &__block{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    max-width: 1200px;
    min-width: 1200px;
    max-height: 93vh;
    border-radius: 20px;
    background: $clr-w;
  }
  &__scroll{
    overflow-y: auto;
    height: 100%;
  }
  &__inner{
    min-width: 100%;
    padding: 40px 40px 50px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  &__close{
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  &__heading{
    min-height: 48px;
    font-size: remSize(32);
    font-weight: bold;
    text-align: center;
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 34px;
    margin: 18px 0 0;
  }
  &__intro{
    width: calc(100% - 116px);
    text-align: center;
  }
  &__allCheck{
    display: flex;
    align-items: center;
    padding: 9px 10px;
    min-width: 116px;
    border-radius: 5px;
    background: #F4F4F4;
    color: $clr-p80;
    .c--control{
      display: flex;
      align-items: center;
      min-width: 68px;
      margin: 0;
      &__txt{
        display: block;
        line-height: 1;
      }
    }
  }
  &__detail{
    overflow: hidden auto;
    min-height: 190px;
    max-height: calc(93vh - 90px - 48px - 52px - 60px - 132px); //inner余白、見出し高さ、テキスト高さ（余白含む）、本体余白、下部ボタン高さ
    margin: 30px 0;
  }

  // 本文アイテム
  .c--hoverShadow__item{
    .c--control{
      margin: 0;
      padding: 0;
      img{
        height: 120px;
      }
      &__indicator{
        transform: none;
        top: auto;
        right: 8px;
        bottom: 68px;
        left: auto;
      }
      &__txt{
        width: 100%;
        display: inline-block;
        min-height: 60px;
        padding: 10px;
        i{
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          min-height: 2rem;
          overflow: hidden;
        }
      }
    }
  }

  // ボタンエリア
  &__btns{
    display: flex;
    align-items: center;
    flex-direction: column;
    .c--btn{
      height: 56px;
      &.c--btn--lb{
        width: 420px;
      }
      &.c--btn--w{
        width: 236px;
      }
    }
    .c--btn + .c--btn{
      margin-top: 20px;
    }
  }
}

// 一覧
.p--favoriteList{
  display: flex;
  flex-wrap: wrap;
  margin-top: -40px;
  padding: 0 0 10px;
  &__item{
    width: 200px;
    min-height: 180px;
    margin: 40px 10px 0;
    .c--control{
      input{
        right: 0;
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 1220px) {
  .p--popup{
    &__block{
      min-width: auto;
      width: 90vw;
      top: auto;
      left: auto;
      transform: none;
    }
    &__inner{
      min-width: 100%;
    }
  }
}
@media screen and (max-width: 767px){
  .p--popup{
    &__block{
      max-width: 90%;
      max-height: 80vh;
      min-width: auto;
    }
    &__inner{
      min-width: 100%;
      padding: 20px 10px 15px;
    }
    &__close{
      top: -9px;
      right: -9px;
    }
    &__heading{
      min-height: 70px;
      font-size: remSize(24);
    }
    &__top{
      align-items: flex-end;
      flex-direction: column;
      min-height: 108px;
      margin-top: 8px;
    }
    &__intro{
      width: 100%;
    }
    &__allCheck{
      margin-top: 15px;
    }
    &__detail{
      margin: 20px 0;
      padding: 0 2px;
      min-height: 100px;
      max-height: calc(80vh - 35px - 70px - 116px - 40px - 98px); //inner余白、見出し高さ、テキスト高さ（余白含む）、本体余白、下部ボタン高さ
    }
  
    // 本文アイテム
    .c--hoverShadow__item{
      .c--control{
        img{
          height: 25.3vw;
        }
        &__txt{
          min-height: 50px;
          padding: 7px 10px;
          i{
            font-size: remSize(12);
          }
        }
      }
    }
  
    // ボタンエリア
    &__btns{
      .c--btn{
        height: 44px;
        padding: 14px 22px;
        &.c--btn--lb,
        &.c--btn--w{
          width: 100%;
        }
      }
      .c--btn + .c--btn{
        margin-top: 10px;
      }
    }
  }
  
  // 一覧
  .p--favoriteList{
    margin: 0;
    &__item{
      width: calc(50% - 10px);
      min-height: auto;
      margin: 0;
      &:nth-child(odd){
        margin-right: 20px;
      }
      &:nth-child(n + 3){
        margin-top: 15px;
      }
    }
  }
}


/** ========================================
 * @p--checkbox__head
 * チェックボックスが入っている見出し（.p--reg__head内に入っているもの）
 * ====================================== */
.p--checkbox__head{
  display: flex;
  align-items: center;
  padding-left: 15px;
  .c--control.checkbox__only{
    min-width: 9px;
    margin: 5px 20px 4px 0;
    padding-left: 9px;
  }
  .c--arrow--circle{
    width: calc(100% - 10px);
  }
}



